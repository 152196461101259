import request from './request'
import { getStoreViewUrl } from 'utils/request'

export default {
  getProductDetail(id) {
    return request.get({ url: `${getStoreViewUrl()}/products/${id}` })
  },
  getProductRelated(sku) {
    return request.get({
      url: `${getStoreViewUrl()}/ld-related-products/${sku}`,
    })
  },
  getShoppingListById(id) {
    return request.get({ url: `get-list-by-product-id/${id}` })
  },
  addProductReview(payload) {
    return request.post({ url: `review/mine/post`, data: payload })
  },
}

import React from 'react';
import { toast } from 'react-toastify';
import './notification.styles.scss';

export const showError = (message, trackingClassName) => {
  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: `toast-container ${trackingClassName}`,
  };
  // toast.error(msg, toastOptions);
  toast(<ToastContainer message={message} />, toastOptions);
};

export const showMcApplyError = (message) => {
  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: `mc-error-message`,
  };
  toast(
    <ToastContainer
      message={message}
      icon={<span className="fal fa-exclamation-circle icon"></span>}
    />,
    toastOptions,
  );
};

export const showSuccess = (message, trackingClassName = '') => {
  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: `toast-container ${trackingClassName}`,
  };
  // toast.success(msg, toastOptions);
  toast(<ToastContainer message={message} />, toastOptions);
};

export function showUpdate(message) {
  const toastOptions = {
    type: toast.TYPE.UPDATE,
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-container',
  };
  toast(<ToastContainer message={message} />, toastOptions);
}

// example Element: <Component {...props}/>
export function showToastSuccessCustom(Element) {
  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-container',
  };
  toast(Element, toastOptions);
}

export function showUpdateCustom(Element) {
  const toastOptions = {
    type: toast.TYPE.UPDATE,
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-container',
  };
  toast(Element, toastOptions);
}

function ToastContainer(props) {
  const { message, icon } = props;
  return (
    <>
      {icon ? icon : null}
      <span className="message">{message}</span>
    </>
  );
}

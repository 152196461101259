import con from '../../constant/actionConstant'

const initialState = {
	videoModal: false,
	videoId: '',
}
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case con.openVideoModal:
			return {
				...state,
				videoModal: true,
				videoId: action.payload,
			}
		case con.closeVideoModal:
			return {
				...state,
				videoModal: false,
			}
		default:
			return state
	}
}
export default reducer

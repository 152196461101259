import { initialState } from './reducer';
import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectAccountDomain = (state) => state.rnaccount || initialState;

export const makeSelectAllOrders = () =>
  createSelector(selectAccountDomain, (substate) => substate.allOrders);

export const makeSelectCurrentOrders = () =>
  createSelector(selectAccountDomain, (substate) =>
    get(substate, 'currentOrders.items', []),
  );

export const makeSelectOrders = () =>
  createSelector(selectAccountDomain, (substate) =>
    get(substate, 'allOrders.items', []),
  );

export const makeSelectOrdersTotalCount = () =>
  createSelector(selectAccountDomain, (substate) =>
    get(substate, 'allOrders.total_count', 0),
  );

export const makeSelectProcessingOrdersCount = () =>
  createSelector(selectAccountDomain, (substate) => substate.processingOrders);

export const makeSelectPopupTrackerDrawer = () =>
  createSelector(selectAccountDomain, (substate) => substate.trackPopupDrawer);

export const makeSelectPriceSubscriptions = () =>
  createSelector(selectAccountDomain, (substate) =>
    get(substate, 'priceSubscriptions'),
  );

export const makeSelectStockSubscriptions = () =>
  createSelector(selectAccountDomain, (substate) =>
    get(substate, 'stockSubscriptions'),
  );

export const makeSelectProductReviewData = () =>
  createSelector(selectAccountDomain, (substate) =>
    get(substate, 'productReviewData'),
  );
export const makeSelectStatusEditAbleAddress = () =>
  createSelector(selectAccountDomain, (substate) =>
    get(substate, 'statusEditAbleAddress'),
  );
export const makeSelectIsUpdatingUser = () =>
  createSelector(selectAccountDomain, (substate) => substate.isUpdatingUser);

export const makeSelectProcessingOrders = () =>
  createSelector(
    selectAccountDomain,
    (substate) => substate.allProcessingOrders,
  );

import { get } from 'lodash';
import {
  getCampaignDataByStoreCode,
  getCampaignPrice,
  getValidSchedules,
} from 'utils/helper';
import { calculateDiscountPercent } from 'components/Product/ProductListItem/components/Price/helper';

/**
 * TODO: TRANSFORM PAYLOAD FOR CT VIEW ITEM AND REMOVE ITEM FROM CART
 * @param {ProductDetail}
 */
export const transformPayload = (productDetail = {}) => {
  const sku = get(productDetail, 'sku');
  const extensionAttributes = get(productDetail, 'extension_attributes', {});
  const brand = get(productDetail, 'extension_attributes.brand', {});
  const campaignData = get(extensionAttributes, 'campaign_data', []);
  const campaignSchedules = getCampaignDataByStoreCode(campaignData);
  const campaignTime = get(campaignSchedules, 'campaign_time', []);
  const schedules = getValidSchedules(campaignTime);
  const finalPrice = getCampaignPrice(productDetail, schedules);
  const productName = get(productDetail, 'name', '...');
  const originalPrice = Number(extensionAttributes.price || 0);
  const percentDiscount = calculateDiscountPercent(originalPrice, finalPrice);

  const payload = {
    sku_id: sku,
    sku_name: productName,
    sku_price_regular: originalPrice,
    sku_price_special: finalPrice,
    sku_price_discount: `${percentDiscount}%`,
    brand: brand,
  };
  return payload;
};

/*
 *
 * LandersApp constants
 *
 */
export const SHOW_LOADER = 'landers/Global/SHOW_LOADER';
export const HIDE_LOADER = 'landers/Global/HIDE_LOADER';
export const SHOW_PARTIAL_LOADER = 'landers/Global/SHOW_PARTIAL_LOADER';

export const LOAD_SIDEBAR = 'landers/Global/LOAD_SIDEBAR';
export const LOAD_SIDEBAR_SUCCESS = 'landers/Global/LOAD_SIDEBAR_SUCCESS';
export const LOAD_SIDEBAR_FAILED = 'landers/Global/LOAD_SIDEBAR_FAILURE';

export const LOAD_CATEGORY_MENU_LEFT = 'LOAD_CATEGORY_MENU_LEFT';
export const LOAD_CATEGORY_MENU_LEFT_SUCCESS =
  'LOAD_CATEGORY_MENU_LEFT_SUCCESS';
export const LOAD_CATEGORY_MENU_LEFT_FAILURE =
  'LOAD_CATEGORY_MENU_LEFT_FAILURE';

export const LOAD_SIDEADS = 'LOAD_SIDEADS';
export const LOAD_SIDEADS_SUCCESS = 'LOAD_SIDEADS_SUCCESS';
export const LOAD_SIDEADS_FAILURE = 'LOAD_SIDEADS_FAILURE';

export const LOAD_SUBMENUTOP = 'landers/Global/LOAD_SUBMENUTOP';
export const LOAD_SUBMENUTOP_SUCCESS = 'landers/Global/LOAD_SUBMENUTOP_SUCCESS';
export const LOAD_SUBMENUTOP_FAILED = 'landers/Global/LOAD_SUBMENUTOP_FAILED';

// Create a new cart (guest or user cart)
export const CHECK_LOCATION = 'landers/LandersApp/CHECK_LOCATION';
export const CHECK_LOCATION_SUCCESS =
  'landers/LandersApp/CHECK_LOCATION_SUCCESS';
export const CHECK_LOCATION_FAILED = 'landers/LandersApp/CHECK_LOCATION_FAILED';
export const UPDATE_SHIPPING_ADDRESS = 'landers/Global/UPDATE_SHIPPING_ADDRESS';
export const CLEAR_CURRENT_LOCATION = 'landers/Global/CLEAR_CURRENT_LOCATION';

export const GET_USER_INFO = 'app/Global/GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'app/Global/GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILED = 'app/Global/GET_USER_INFO_FAILED';

//Addres modal
export const LOAD_PROVINCE = 'landers/Global/LOAD_PROVINCE';
export const LOAD_PROVINCE_SUCCESS = 'landers/Global/LOAD_PROVINCE_SUCCESS';
export const LOAD_PROVINCE_FAILED = 'landers/Global/LOAD_PROVINCE_FAILURE';
export const LOAD_CITY = 'landers/Global/LOAD_CITY';
export const LOAD_CITY_SUCCESS = 'landers/Global/LOAD_CITY_SUCCESS';
export const LOAD_CITY_FAILED = 'landers/Global/LOAD_CITY_FAILURE';
export const LOAD_BARANGAY = 'landers/Global/LOAD_BARANGAY';
export const LOAD_BARANGAY_SUCCESS = 'landers/Global/LOAD_BARANGAY_SUCCESS';
export const LOAD_BARANGAY_FAILED = 'landers/Global/LOAD_BARANGAY_FAILURE';
export const LOAD_FOOTER = 'landers/Global/LOAD_FOOTER';
export const LOAD_FOOTER_REQUEST = 'landers/Global/LOAD_FOOTER_REQUEST';
export const LOAD_FOOTER_SUCCESS = 'landers/Global/LOAD_FOOTER_SUCCESS';
export const LOAD_FOOTER_FAILED = 'landers/Global/LOAD_FOOTER_FAILURE';
export const SHOW_SIDE_BAR = 'landers/Global/SHOW_SIDE_BAR';
export const HIDE_SIDE_BAR = 'landers/Global/HIDE_SIDE_BAR';
export const SHOW_SEARCH_MODE = 'landers/Global/SHOW_SEARCH_MODE';
export const LOGOUT = 'landers/Global/LOGOUT';
// Get recomment product
export const SUBSCRIBE_PRODUCT = 'landers/Global/SUBSCRIBE_PRODUCT';
export const SUBSCRIBE_PRODUCT_SUCCESS =
  'landers/Global/SUBSCRIBE_PRODUCT_SUCCESS';
export const SUBSCRIBE_PRODUCT_FAILED =
  'landers/Global/SUBSCRIBE_PRODUCT_FAILURE';

export const SET_PERSISTOR_LOADED = 'landers/Global/SET_PERSISTOR_LOADED';

export const LOAD_COUNTRY_NAME_SUCCESS =
  'landers/Global/LOAD_COUNTRY_NAME_SUCCESS';

// get all cms
export const LOAD_ALL_CMS = 'landers/Global/LOAD_ALL_CMS';
export const LOAD_ALL_CMS_SUCCESS = 'landers/Global/LOAD_ALL_CMS_SUCCESS';
export const LOAD_ALL_CMS_FAILED = 'landers/Global/LOAD_ALL_CMS_FAILED';

export const LOAD_GLOBAL_CONFIG = 'landers/Global/LOAD_GLOBAL_CONFIG';
export const LOAD_GLOBAL_CONFIG_SUCCESS =
  'landers/Global/LOAD_GLOBAL_CONFIG_SUCCESS';
export const LOAD_GLOBAL_CONFIG_FAILED =
  'landers/Global/LOAD_GLOBAL_CONFIG_FAILED';

// get store pickup data
export const LOAD_STORE_ADDRESSES = 'landers/Global/LOAD_STORE_ADDRESSES';
export const LOAD_STORE_ADDRESSES_SUCCESS =
  'landers/Global/LOAD_STORE_ADDRESSES_SUCCESS';
export const LOAD_STORE_ADDRESSES_FAILED =
  'landers/Global/LOAD_STORE_ADDRESSES_FAILURE';

export const LOAD_SPECIAL_CATEGORY_ID_SUCCESS =
  'landers/Global/LOAD_SPECIAL_CATEGORY_ID_SUCCESS';

export const LOAD_OUT_OF_STOCK_CONFIG =
  'landers/Global/LOAD_OUT_OF_STOCK_CONFIG';

export const LOAD_FOOTER_CONFIG = 'landers/Global/LOAD_FOOTER_CONFIG';

export const LOAD_PREFERRED_BRANCHES = 'landers/Global/LOAD_PREFERRED_BRANCHES';

export const UPDATE_FCM_TOKEN = 'landers/Global/UPDATE_FCM_TOKEN';
export const DELETE_FCM_TOKEN = 'landers/Global/DELETE_FCM_TOKEN';

export const LOAD_DELIVERY_SLOT_VERBIAGE =
  'landers/Global/LOAD_DELIVERY_SLOT_VERBIAGE';

export const LOAD_SEARCH_AND_AUTO_SUGGEST =
  'landers/Global/LOAD_SEARCH_AND_AUTO_SUGGEST';

export const GET_STORE_VIEW_MAPPING = 'landers/Global/GET_STORE_VIEW_MAPPING';
export const SET_STORE_VIEW_MAPPING_SUCCESS =
  'landers/Global/SET_STORE_VIEW_MAPPING_SUCCESS';
export const REHYDRATE_STORE_VIEW_CODE =
  'landers/Global/REHYDRATE_STORE_VIEW_CODE';

export const DEFAULT_STORE_VIEW = 'default';

export const SYNC_CART_ADDRESS_CHANGED =
  'landers/Global/SYNC_CART_ADDRESS_CHANGED';

export const SET_YELLOW_AI_PAYLOAD = 'landers/Global/SET_YELLOW_AI_PAYLOAD';

export const SET_IS_SHOW_CHAT_NUDGE = 'landers/Global/SET_IS_SHOW_CHAT_NUDGE';

export const SET_YELLOW_AI_PAYLOAD_SUCCESS =
  'landers/Global/SET_YELLOW_AI_PAYLOAD_SUCCESS';
export const RELOAD_API_OF_PAGE = 'landers/Global/RELOAD_API_OF_PAGE';
export const UPDATE_CT_USER_PROFILE = 'landers/Global/UPDATE_CT_USER_PROFILE';
export const SET_STORE_CODE_VIEW = 'landers/GLOBAL_SET_STORE_CODE_VIEW';

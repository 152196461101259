import * as constants from './constants'

// GET_SHOPPING_NOW
export function getShoppingNow(payload) {
  return {
    type: constants.GET_SHOPPING_NOW,
    payload,
  }
}

export function getShoppingNowSuccess(payload) {
  return {
    type: constants.GET_SHOPPING_NOW_SUCCESS,
    payload,
  }
}

export function getShoppingNowFailed(error) {
  return {
    type: constants.GET_SHOPPING_NOW_FAILED,
    error,
  }
}

// GET_RECOMMEND_PRODUCT
export function getRecommendProducts(payload) {
  return {
    type: constants.GET_RECOMMEND_PRODUCT,
    payload,
  }
}

export function getRecommendProductsSuccess(payload) {
  return {
    type: constants.GET_RECOMMEND_PRODUCT_SUCCESS,
    payload,
  }
}

export function getRecommendProductsFailed(error) {
  return {
    type: constants.GET_RECOMMEND_PRODUCT_FAILED,
    error,
  }
}

// GET_PROGRESSBAR
export function getProgressBar(params = {}) {
  return {
    type: constants.GET_PROGRESSBAR,
    params,
  }
}

export function getProgressBarSuccess(payload) {
  return {
    type: constants.GET_PROGRESSBAR_SUCCESS,
    payload,
  }
}

export function getProgressBarFailed(error) {
  return {
    type: constants.GET_PROGRESSBAR_FAILED,
    error,
  }
}

export function getProductRecommendation1() {
  return {
    type: constants.GET_PRODUCT_RECOMMENDATION_1,
  }
}

export function getProductRecommendation1Success(payload) {
  return {
    type: constants.GET_PRODUCT_RECOMMENDATION_1_SUCCESS,
    payload,
  }
}

export function getProductRecommendation2() {
  return {
    type: constants.GET_PRODUCT_RECOMMENDATION_2,
  }
}

export function getProductRecommendation2Success(payload) {
  return {
    type: constants.GET_PRODUCT_RECOMMENDATION_2_SUCCESS,
    payload,
  }
}

import axios from 'axios'
import get from 'lodash/get'
import { history } from 'configureStore'
import { clearAuthData } from 'utils/localStorage'
import config from 'global-config'
import { getAccessToken, getStoreCode } from 'utils/request'

const axiosInstance = axios.create({
  // `baseURL` will be prepended to `url` unless `url` is absolute.
  // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
  // to methods of that instance. Refer: https://github.com/axios/axios#creating-an-instance
  baseURL: `${config.apiUrl}/`,
  withCredentials: false,
})

function handleUnauthorized(response) {
  if (response.status === 401 && !response.url.includes('/me/password')) {
    clearAuthData()
    history.push('/', { isTokenExpired: true })
  }
}

function requestHelper({ method, options }) {
  const token = getAccessToken()
  return axiosInstance({
    ...options,
    method,
    headers: {
      Accept: 'application/json',
      ...options.headers,
      Authorization: `Bearer ${token}`,
      sourcecode: getStoreCode() || '',
    },
  })
    .then((response) => {
      const { status, data } = response
      if (status >= 200 && status < 300) {
        return Promise.resolve(data)
      }
      handleUnauthorized(response)
      return Promise.reject({ status, response })
    })
    .catch((response) => {
      const status = get(response, 'response.status')
      const error = get(response, 'response.data')
      handleUnauthorized(response)
      return Promise.reject({ status, ...error })
    })
}

export default {
  get(options) {
    return requestHelper({ method: 'GET', options })
  },

  post(options) {
    return requestHelper({ method: 'POST', options })
  },

  put(options) {
    return requestHelper({ method: 'PUT', options })
  },

  patch(options) {
    return requestHelper({ method: 'PATCH', options })
  },

  delete(options) {
    return requestHelper({ method: 'DELETE', options })
  },
}

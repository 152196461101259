import { produce } from 'immer'
import * as constant from 'containers/ShoppingList/constants'

export const initialState = {
  shoppingList: [],
  createUpdateListLoading: false,
  getShoppingListLoading: false,
  item: {},
  allProductInMyList: {},
  removeConfirmModal: false,
  idToRemove: null,
  pastPurchase: {},
  getListDetailLoading: false,
  shoppingListDetail: {},
  shoppingDetailFilter: {
    ld_display: null,
    product_list_dir: 'ASC',
    product_list_order: null,
    curPage: 1,
  },
  listIdToRemove: null,
  editableErrorMessage: '',
  shareShoppingListData: {},
}
const reducer = (state = initialState, action) => {
  return produce(state, (newstate) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case constant.REQUEST_CREATE_UPDATE_LIST:
        newstate.editableErrorMessage = ''
        break
      case constant.REQUEST_CREATE_UPDATE_LIST_ERROR:
        newstate.editableErrorMessage = action.payload
        break
      case constant.SAVE_SHOPPING_LIST:
        newstate.editableErrorMessage = ''
        newstate.shoppingList = action.payload
        break
      case constant.SAVE_ALL_PRODUCT_IN_MY_LIST:
        newstate.allProductInMyList = action.payload
        break
      case constant.GET_SHOPPING_LIST_START:
        newstate.getShoppingListLoading = true
        break
      case constant.GET_SHOPPING_LIST_FINISH:
        newstate.getShoppingListLoading = false
        break
      case constant.CREATE_UPDATE_LIST_LOADING_START:
        newstate.createUpdateListLoading = true
        break
      case constant.CREATE_UPDATE_LIST_LOADING_FINISH:
        newstate.createUpdateListLoading = false
        break
      case constant.SAVE_PAST_PURCHASE:
        newstate.pastPurchase = action.payload
        break
      case constant.MODIFY_LIST:
        newstate.shoppingListDetail = action.payload
        newstate.idToRemove = null
        break
      case constant.SAVE_SHOPPING_LIST_FILTER:
        newstate.shoppingDetailFilter = action.payload
        break
      case constant.CLEAR_SHOPPING_LIST_FILTER:
        newstate.shoppingDetailFilter = initialState.shoppingDetailFilter
        break
      case constant.GET_LIST_DETAIL_START:
        newstate.getListDetailLoading = true
        break
      case constant.GET_LIST_DETAIL_FINISH:
        newstate.getListDetailLoading = false
        break
      case constant.SAVE_LIST_DETAIL:
        newstate.shoppingListDetail = action.payload
        break
      case constant.SAVE_LIST_ID_TO_REMOVE:
        newstate.listIdToRemove = action.payload
        break
      case constant.MODIFY_SHOPPING_LIST:
        newstate.shoppingList = action.payload
        break
      case constant.SHARE_SHOPPING__LIST:
        break
      case constant.LOAD_SHARE_SHOPPING__LIST:
        break
      case constant.SAVE_SHARE_SHOPPING__LIST:
        newstate.shareShoppingListData = action.payload
        break

      case constant.ADD_PRODUCT_TO_SHOPPING_LIST:
        break
      case constant.ADD_PRODUCT_TO_SHOPPING_LIST_SUCCESS:
        break
      case constant.ADD_PRODUCT_TO_SHOPPING_LIST_FAILED:
        break
    }
  })
}
export default reducer

import { getStoreCode } from 'utils/request'
import config from 'global-config'
import { get, startsWith, find } from 'lodash'
import {
  isDuringCampaign,
  getValidSchedules,
  getCampaignDataByStoreCode,
} from './helper'

export const getThumbnail = (product) => {
  const customAttributes =
    product.custom_attributes ||
    get(product, 'extension_attributes.product_data.custom_attributes', [])
  const smallImage = customAttributes.find(
    (val) => get(val, 'attribute_code') === 'small_image',
  )
  if (smallImage) {
    const urlSmallImage = get(smallImage, 'value')
    if (startsWith(urlSmallImage, 'http')) {
      return urlSmallImage
    } else {
      return `${config.imageUrl}${urlSmallImage}`
    }
  } else {
    const mediaGalleryEntriesFirstItemImage = get(
      product,
      'extension_attributes.product_data.media_gallery_entries[0].file',
      [],
    )
    if (mediaGalleryEntriesFirstItemImage) {
      if (startsWith(mediaGalleryEntriesFirstItemImage, 'http')) {
        return mediaGalleryEntriesFirstItemImage
      } else {
        return `${config.imageUrl}${mediaGalleryEntriesFirstItemImage}`
      }
    }
    return ''
  }
}

export const getFinalPrice = (product) => {
  const fullItemData = get(
    product,
    'extension_attributes.product_data',
    product,
  )
  const { extension_attributes } = fullItemData
  if (!extension_attributes) {
    return 0
  }

  return get(extension_attributes, 'final_price', 0)
}

export const getOriginalPrice = (product) => {
  const fullItemData = get(
    product,
    'extension_attributes.product_data',
    product,
  )
  const { extension_attributes } = fullItemData

  if (!extension_attributes) {
    return 0
  }

  return get(fullItemData, 'price', 0)
}

export const isMembershipProduct = (product) => {
  // @TODO re-visit this condition after decoupling
  if (
    product?.extension_attributes?.product_data?.type_id ===
    'customer_membership'
  ) {
    return true
  }

  if (product?.product_type === 'customer_membership') {
    return true
  }

  return false
}

export const getSavedValue = (product) => {
  if (isMembershipProduct(product)) {
    return 0
  }

  return (
    get(product, 'extension_attributes.product_data.price', 0) *
      get(product, 'qty', 0) -
    get(product, 'base_row_total', 0)
  )
}

export const isDiscount = (product) => {
  const { extension_attributes } = product

  if (!extension_attributes) {
    return false
  }

  const { final_price, price } = extension_attributes

  return final_price !== price
}

export const getInStockValue = (product) => {
  if (!product) {
    return 0
  }

  if (isMembershipProduct(product)) {
    return 99999
  }

  const { extension_attributes } = product

  if (!extension_attributes) {
    return 0
  }

  const hasProductData = get(extension_attributes, 'product_data', null)
  const campaignData = get(
    extension_attributes,
    hasProductData
      ? 'product_data.extension_attributes.campaign_data'
      : 'campaign_data',
    [],
  )
  const campaignSchedules = getCampaignDataByStoreCode(campaignData)
  const campaignTime = get(campaignSchedules, 'campaign_time', [])
  const schedules = getValidSchedules(campaignTime)

  if (isDuringCampaign(schedules[0])) {
    return Number(schedules[0].stock)
  }

  const stock_source_data =
    get(extension_attributes, 'stock_source_data') ||
    get(
      extension_attributes,
      'product_data.extension_attributes.stock_source_data',
    )

  const storeCode = getStoreCode()

  if (!storeCode) {
    return 1
  }

  const status = get(
    find(
      stock_source_data,
      (sourceData) => sourceData.source_code === storeCode,
    ),
    'status',
    0,
  )

  if (!status) {
    return status
  }

  const result = get(
    find(
      stock_source_data,
      (sourceData) => sourceData.source_code === storeCode,
    ),
    'quantity',
    0,
  )

  return parseInt(result)
}

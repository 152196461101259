import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import LoaderImage from 'component/LoaderImage'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
`

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    max-width: 100%;
  }
  i {
    font-size: 50px;
    color: rgba(0, 0, 0, 0.5);
  }
`

const Text = styled.p`
  text-align: center;
  max-width: 300px;
  margin-top: 10px;
  color: blue;
`

export default function LoaderIndicator(props) {
  const loader = useSelector((state) => state.rnglobal.hasLoader)
  return (
    <Wrapper>
      <Spinner>
        <LoaderImage />
      </Spinner>
      {loader && loader.isPaymentProcessing && (
        <Text>
          Your order is being processed. <br />
          Please do not refresh this page.
        </Text>
      )}
    </Wrapper>
  )
}

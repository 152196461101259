import { get } from 'lodash'

import { SOLANE_VENDOR } from 'global-constant'

export const calculateSolaneProducts = (cartItems = []) => {
  return cartItems.reduce((acc, currentItem) => {
    if (
      get(
        currentItem,
        'extension_attributes.product_data.extension_attributes.vendor',
      ) === SOLANE_VENDOR
    ) {
      return acc + get(currentItem, 'base_row_total', 0)
    }

    return acc
  }, 0)
}

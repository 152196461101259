import Axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as globalActions from 'containers/Landers/actions'
import * as modalActions from 'containers/Modals/actions'
import * as actions from './actions'
import * as constant from './constants'
import config from 'global-config'
import { get } from 'lodash'
import { makeSelectCurrentUser } from 'containers/Landers/selectors'

function* onLoadHelp(action) {
  const requestURL = `${config.apiUrl}/helpdesk/get-faqs`
  try {
    yield put(globalActions.showLoader())
    const res = yield call(fetch, requestURL, {
      method: 'GET',
    })
    const data = yield res.json()
    yield put(actions.loadHelpSuccess(data))
  } catch (err) {
    yield put(actions.loadHelpFailed(err))
  } finally {
    yield put(globalActions.hideLoader())
  }
}

function* onPostFaq(action) {
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))
  const membershipCode = get(
    currentUser,
    'extension_attributes.membership_info.code'
  )
  const requestURL = `${config.apiUrl}/web2case/send-enquiry`

  try {
    yield put(globalActions.showLoader())
    const formData = new FormData()
    Object.entries(action.payload).forEach(([name, value]) => {
      if (value !== undefined) {
        formData.append(name, value)
      }
    })
    if (membershipCode) {
      formData.append('membership_code', membershipCode)
    }
    const res = yield call(Axios, requestURL, {
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
    if (res && res.status === 200) {
      yield put(modalActions.showModalFaqSuccess())
    }
  } catch (err) {
    yield put(modalActions.showModalFaqFailed(err))
  } finally {
    yield put(globalActions.hideLoader())
  }
}

export default function* expSaga() {
  yield takeLatest(constant.GET_ALL_HELP, onLoadHelp)
  yield takeLatest(constant.POST_FAQ, onPostFaq)
}

import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { setAutoFreeze } from 'immer';
import { persistStore } from 'redux-persist';

import createRootReducer from './mainReducer';
import rootSaga from 'store/saga';
import { middlewares } from 'rtk';

setAutoFreeze(false);

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();
const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([routerMiddleware(history), sagaMiddleware, ...middlewares]),
});

setupListeners(store.dispatch);
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export { store, persistor };

import * as constants from './constants'

export const initialState = {
	siteNavigation: [],
}
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case constants.LOAD_CATEGORY_NAME:
			return {
				...state,
			}
		case constants.SAVE_CATEGORY_NAME:
			return {
				...state,
				siteNavigation: action.payload,
			}
		case constants.CLEAR_CATEGORY_NAME:
			return {
				...state,
				siteNavigation: [],
			}
		default:
			return state
	}
}
export default reducer

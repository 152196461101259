import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { isTokenExpired } from 'api/utils';
import { store } from 'configureStore';
import { setLambdaAccessToken } from 'containers/Authentication/actions';
import { mutex } from './mutex';

const baseUrl = process.env.REACT_APP_LAMBDA_CORE_MS_URL;

const NOT_AUTH_API_PATHS = ['/login', '/guest-carts'];

const reAuth = async (api, extraOptions) => {
  if (!mutex.isLocked()) {
    const release = await mutex.acquire();

    try {
      const authQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
          headers.set('Accept', 'application/json');
          headers.set('Content-Type', 'application/json');

          return headers;
        },
      });

      const email = store.getState()?.rnglobal.currentUserEmail;
      const accessToken = store.getState()?.rnglobal.accessToken;

      const { data } = await authQuery(
        {
          url: '/user/login',
          method: 'POST',
          body: { access: accessToken, email },
        },
        api,
        extraOptions,
      );

      if (data?.data) {
        store.dispatch(setLambdaAccessToken(data?.data));
      }
    } finally {
      release();
    }
  }
};

const baseQueryInterceptor = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  const isNotAuthAPI = NOT_AUTH_API_PATHS.every(
    (path) => !args.url.includes(path),
  );

  if (isNotAuthAPI && isTokenExpired()) {
    await reAuth(api, extraOptions);
  }

  await mutex.waitForUnlock();

  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      const nodeAccessToken =
        store.getState()?.rnauthen.lambdaAccessToken.access_token;

      headers.set('Authorization', `Bearer ${nodeAccessToken}`);
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  });

  let result = await baseQuery(args, api, extraOptions);

  // @TODO status should be 401 here waiting for microservice to fix this
  const isError =
    result.error?.status === 'FETCH_ERROR' || result.error?.status === 401;
  if (isError) {
    await reAuth(api, extraOptions);
    await mutex.waitForUnlock();
    result = await baseQuery(args, api, extraOptions);
  }

  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const coreApi = createApi({
  baseQuery: baseQueryInterceptor,
  endpoints: () => ({}),
  reducerPath: 'coreApi',
});

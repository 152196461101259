import * as productUtils from 'utils/product'
import { setCartData } from 'utils/validate'
import { getDispatch } from 'utils/navigation'
import * as modalActions from 'containers/Modals/actions'
import { MEMBERSHIP_TYPES } from 'global-constant'
import {
  getCampaignDataByStoreCode,
  getValidSchedules,
  isDuringCampaign,
} from 'utils/helper'
import {
  get,
  set,
  clone,
  find,
  extend,
  forOwn,
  sumBy,
  map,
  filter,
} from 'lodash'

const productInCart = (cartItems, temp_id) =>
  clone(find(cartItems, (cartItem) => cartItem.temp_id === temp_id))

const ruleToCartItem = (cartItems, rule) => {
  let promotionCartItem = {
    promotion: {
      ...rule,
    },
  }

  switch (rule.promotion_type) {
    case 'normal':
      forOwn(rule.skus, (product, sku) => {
        const obj = productInCart(cartItems, rule.temp_id)
        promotionCartItem = extend(promotionCartItem, obj)
        set(
          promotionCartItem,
          'promotion.promotion_qty',
          promotionCartItem.qty - product.qty,
        )

        set(promotionCartItem, 'qty', product.qty)
      })
      break
    case 'buyxgety':
    case 'bundle_group':
      const _inCartProduct = productInCart(cartItems, rule.temp_id)

      promotionCartItem.extension_attributes =
        _inCartProduct.extension_attributes

      const buyItems = []
      forOwn(rule.skus, (product, sku) => {
        const inCartProduct = productInCart(cartItems, sku)
        set(inCartProduct, 'promotion.promotion_qty', product.qty)

        set(promotionCartItem, 'sku', get(promotionCartItem, 'sku', '') + sku)

        set(
          promotionCartItem,
          'promotion.promotion_original_price',
          get(promotionCartItem, 'promotion.promotion_original_price', 0) +
            productUtils.getOriginalPrice(inCartProduct) * product.qty,
        )
        buyItems.push(inCartProduct)
      })

      promotionCartItem.buy = buyItems

      const getItems = []
      forOwn(rule.discount, (product, sku) => {
        const inCartProduct = productInCart(cartItems, sku)
        set(inCartProduct, 'promotion.promotion_qty', product.qty)

        const discount = (inCartProduct.price - product.price) * product.qty
        set(inCartProduct, 'promotion.promotion_saved', discount)

        const currentTotalDiscount = get(
          promotionCartItem,
          'promotion.promotion_row_saved',
          0,
        )
        set(
          promotionCartItem,
          'promotion.promotion_row_saved',
          currentTotalDiscount + discount,
        )

        set(promotionCartItem, 'sku', get(promotionCartItem, 'sku', '') + sku)

        set(
          promotionCartItem,
          'promotion.promotion_original_price',
          get(promotionCartItem, 'promotion.promotion_original_price', 0) +
            productUtils.getOriginalPrice(inCartProduct) * product.qty,
        )
        getItems.push(inCartProduct)
      })

      promotionCartItem.get = getItems
      break
    default:
      break
  }

  return promotionCartItem
}

const showCartNotification = (cartData, actionSync) => {
  const dispatch = getDispatch()
  const { prev_promotion_items, promotion_items } = cartData

  if (!prev_promotion_items) {
    return
  }

  const prevBundleQty = sumBy(prev_promotion_items || [], (cartItem) =>
    cartItem.buy ? 1 : 0,
  )

  const newBundleQty = sumBy(promotion_items, (cartItem) =>
    cartItem.buy ? 1 : 0,
  )
  if (newBundleQty !== prevBundleQty) {
    dispatch(
      modalActions.showCartNotificationModal({
        // actionSync from button add to cart or empty cart
        /**
         * actionSync: remove/add
         */
        isRemoved: actionSync === 'remove',
        isBundled: true,
        qty: Math.abs(newBundleQty - prevBundleQty),
      }),
    )
  } else {
    const newQty = sumBy(promotion_items, 'qty')
    const prevQty = sumBy(prev_promotion_items || [], 'qty')
    dispatch(
      modalActions.showCartNotificationModal({
        isRemoved: actionSync === 'remove',
        isBundled: false,
        qty: Math.abs(newQty - prevQty),
      }),
    )
  }

  cartData.prev_promotion_items = map(cartData.promotion_items, clone)
}

const updateCartTotals = (cartData, actionSync) => {
  cartData.total_promotion_saved = 0
  cartData.items_qty = sumBy(cartData.items, 'qty')
  cartData.subtotal = cartData.promotion_items.reduce((prev, item) => {
    if (['bundle_group', 'buyxgety'].includes(item.promotion.promotion_type)) {
      return (
        prev +
        item.promotion.promotion_original_price -
        item.promotion.promotion_row_saved
      )
    }

    const campaignData = get(
      item,
      'extension_attributes.product_data.extension_attributes.campaign_data',
      [],
    )
    const campaignSchedules = getCampaignDataByStoreCode(campaignData)
    const campaignTime = get(campaignSchedules, 'campaign_time', [])
    const schedules = getValidSchedules(campaignTime)

    let price = 0

    if (isDuringCampaign(schedules[0])) {
      price = Number(schedules[0].price)
      return prev + price * item.qty
    } else {
      return prev + item.price * item.qty
    }
  }, 0)

  cartData.total_normal_saved = sumBy(cartData.items, 'base_row_saved')

  cartData.total_saved = cartData.total_normal_saved
  cartData.items = cartData.items.filter((item) => item.qty > 0)
  setCartData(cartData)
  showCartNotification(cartData, actionSync)

  return cartData
}

export const applyRulesToCart = (cartData, cartRules, actionSync) => {
  const cartItems = filter(cartData.items, (item) => item.qty > 0)
  let i = 0
  let j = 0
  cartItems.forEach((item, index) => {
    if (MEMBERSHIP_TYPES.includes(item.sku)) {
      cartItems[index].temp_id = `${item.sku}_${i}`
      i += 1
    } else {
      cartItems[index].temp_id = item.sku
    }
  })

  cartRules.forEach((item, index) => {
    const [sku] = Object.keys(item.skus)
    if (MEMBERSHIP_TYPES.includes(sku)) {
      cartRules[index].temp_id = `${sku}_${j}`
      j += 1
    } else {
      cartRules[index].temp_id = sku
    }
  })
  const cartPromotionItems = map(cartRules, (cartRule) =>
    ruleToCartItem(cartItems, cartRule),
  )
  cartData.promotion_items = cartPromotionItems

  const nextCartData = updateCartTotals(cartData, actionSync)
  return nextCartData
}

/*
 *
 * Home constants
 *
 */
export const LOAD_PRODUCTS = 'landers/Home/LOAD_PRODUCTS'
export const LOAD_PRODUCTS_SUCCESS = 'landers/Home/LOAD_PRODUCTS_SUCCESS'
export const LOAD_PRODUCTS_FAILED = 'landers/Home/LOAD_PRODUCTS_FAILED'

export const LOAD_WIDGET = 'landers/Home/LOAD_WIDGET'
export const LOAD_WIDGET_SUCCESS = 'landers/Home/LOAD_WIDGET_SUCCESS'
export const LOAD_WIDGET_FAILED = 'landers/Home/LOAD_WIDGET_FAILED'

export const LOAD_EVENTS = 'landers/Home/LOAD_EVENTS'
export const LOAD_EVENTS_SUCCESS = 'landers/Home/LOAD_EVENTS_SUCCESS'
export const LOAD_EVENTS_FAILED = 'landers/Home/LOAD_EVENTS_FAILED'

export const LOAD_CATEGORIES_CARDS = 'landers/Home/LOAD_CATEGORIES_CARDS'
export const LOAD_CATEGORIES_CARDS_SUCCESS =
  'landers/Home/LOAD_CATEGORIES_CARDS_SUCCESS'
export const LOAD_CATEGORIES_CARDS_FAILED =
  'landers/Home/LOAD_CATEGORIES_CARDS_FAILED'

export const LOAD_BANNER_SLIDER = 'landers/Home/LOAD_BANNER_SLIDER'
export const LOAD_BANNER_SLIDER_SUCCESS =
  'landers/Home/LOAD_BANNER_SLIDER_SUCCESS'
export const LOAD_BANNER_SLIDER_FAILED =
  'landers/Home/LOAD_BANNER_SLIDER_FAILED'

export const LOAD_PAST_PURCHASE_PRODUCTS =
  'landers/Home/LOAD_PAST_PURCHASE_PRODUCTS'
export const LOAD_PAST_PURCHASE_PRODUCTS_SUCCESS =
  'landers/Home/LOAD_PAST_PURCHASE_PRODUCTS_SUCCESS'
export const LOAD_PAST_PURCHASE_PRODUCTS_FAILED =
  'landers/Home/LOAD_PAST_PURCHASE_PRODUCTS_FAILED'

export const GET_SPLASH_AD = 'landers/Home/GET_SPLASH_AD'
export const GET_SPLASH_AD_SUCCESS = 'landers/Home/GET_SPLASH_AD_SUCCESS'
export const GET_SPLASH_AD_FAILED = 'landers/Home/GET_SPLASH_AD_FAILED'

export const LOAD_NET_PROMOTERS_SCORE_SURVEY =
  'landers/Home/LOAD_NET_PROMOTERS_SCORE_SURVEY'
export const LOAD_NET_PROMOTERS_SCORE_SURVEY_SUCCESS =
  'landers/Home/LOAD_NET_PROMOTERS_SCORE_SURVEY_SUCCESS'
export const LOAD_NET_PROMOTERS_SCORE_SURVEY_FAILED =
  'landers/Home/LOAD_NET_PROMOTERS_SCORE_SURVEY_FAILED'

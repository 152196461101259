import { FadeInContainer } from 'components/Layouts/Container';
import React, { lazy, Suspense } from 'react';

const loadable = (
  importFunc,
  { fallback = <FadeInContainer /> } = { fallback: <FadeInContainer /> }
) => {
  const LazyComponent = lazy(importFunc);

  return props => (
    <Suspense fallback={fallback}>
        <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;

import { createSlice } from '@reduxjs/toolkit'

const npsSlice = createSlice({
  name: 'nps',
  initialState: {
    forNpsCompletedOrders: [],
    currentNpsDisplay: 0,
    isLoading: false,
  },
  reducers: {
    initializeNPS: (state, action) => {
      //
    },
    setForNpsCompletedOrders: (state, action) => {
      state.forNpsCompletedOrders = action.payload
    },
    setCurrentNpsDisplay: (state, action) => {
      state.currentNpsDisplay = action.payload
    },
  },
})

export const { setForNpsCompletedOrders, setCurrentNpsDisplay, initializeNPS } =
  npsSlice.actions

export default npsSlice.reducer

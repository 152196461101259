import * as constant from './constants'

export const initialState = {
  help: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_ALL_HELP:
      return {
        ...state,
        help: null,
      }
    case constant.HELP_SUCCESS:
      return {
        ...state,
        help: action.payload,
      }
    case constant.HELP_FAILED:
      return {
        ...state,
        help: [],
      }
    case constant.POST_FAQ:
      return {
        ...state,
      }
    case constant.POST_FAQ_SUCCESS:
      return {
        ...state,
      }
    case constant.POST_FAQ_FAILED:
      return {
        ...state,
      }
    default:
      return state
  }
}
export default reducer

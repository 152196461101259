import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the authorize state domain
 */

// const selectSearchDomain = state => state.results || initialState;
const selectSearchDomain = state => state.rnsearch || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Authorize
 */

export const makeSelectSearchResult = () =>
  createSelector(
    selectSearchDomain,
    subState => subState.results,
  );

export const makeSelectShowAllResultSearch = () =>
  createSelector(
    selectSearchDomain,
    subState => subState.allResults,
  );

export const makeSelectIsLoadingResultSearch = () =>
  createSelector(
    selectSearchDomain,
    subState => subState.isLoadingResultSearch,
  );

export const makeSelectListCategorySearch = () =>
  createSelector(
    selectSearchDomain,
    subState => subState.listCategorySearch,
  );

export const makeSelectSearchResultsPopup = () =>
  createSelector(
    selectSearchDomain,
    subState => subState.resultsPopup,
  );

export const makeSelectSearchCurrentPage = () =>
  createSelector(
    selectSearchDomain,
    subState => subState.currentPage,
  );

import * as constant from './constants';

export function getAllExpEvent() {
  return {
    type: constant.GET_ALL_EXP_EVENT,
  };
}

export function loadExpEventSuccess(payload) {
  return {
    type: constant.EXP_LOAD_EVENTS_SUCCESS,
    payload,
  };
}

export function loadExpEventFailed(payload) {
    return {
      type: constant.EXP_LOAD_EVENTS_FAILED,
      payload,
    };
}

export function expEventLoadMore() {
  return {
    type: constant.EXP_LOAD_MORE,
  };
}

export function setLoading(error) {
  return {
    type: constant.EXP_LOADING_START,
    error,
  };
}

export function closeLoading(error) {
    return {
      type: constant.EXP_LOADING_FINISH,
      error,
    };
}

export function getBannerExp() {
  return {
    type: constant.GET_BANNER_EXP,
  };
}

export function loadExpBannerSuccess(payload) {
  return {
    type: constant.EXP_LOAD_BANNER_SUCCESS,
    payload,
  };
}

export function loadExpBannerFailed(payload) {
    return {
      type: constant.EXP_LOAD_BANNER_FAILED,
      payload,
    };
}
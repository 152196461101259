import * as constant from './constants';

export function getAllDeliveryAreas() {
  return {
    type: constant.GET_ALL_DELIVERY_AREAS,
  };
}

export function loadDeliveryAreasSuccess(payload) {
  return {
    type: constant.DELIVERY_AREAS_SUCCESS,
    payload,
  };
}

export function loadDeliveryAreasFailed(payload) {
    return {
      type: constant.DELIVERY_AREAS_FAILED,
      payload,
    };
}
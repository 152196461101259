import * as constants from './constants'
export function loadBreadcumb(payload) {
  return {
    type: constants.LOAD_CATEGORY_NAME,
    pathName: payload.pathName,
    isCategory: payload.isCategory,
  };
}

export function saveBreadcumb(payload) {
  return {
    type: constants.SAVE_CATEGORY_NAME,
    payload,
  };
}

export const clearCategoryName = () => {
	return {
		type: constants.CLEAR_CATEGORY_NAME,
	}
}

import con from '../../constant/actionConstant'

export const initialState = {
  mobileMenuIsOpen: false,
  sideMenuStatus: false,
  index: 0,
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case con.showSideMenu:
      return {
        ...state,
        sideMenuStatus: true,
        index: action.payload,
      }
    case con.hideSideMenu:
      return {
        ...state,
        sideMenuStatus: false,
      }
    case con.openMobileMenu:
      return {
        ...state,
        mobileMenuIsOpen: true,
      }
    case con.closeMobileMenu:
      return {
        ...state,
        mobileMenuIsOpen: false,
      }
    default:
      return state
  }
}
export default reducer

const formatMobileNumber = (mobileNo: string) => {
  if (!mobileNo) return ''

  let str = mobileNo.toString().replace(/\D/g, '') // Remove non-digit characters

  if (str.startsWith('09')) {
    str = str.replace(/^09/, '+63 9')
  } else if (str.startsWith('9')) {
    str = '+63 9' + str.substring(1)
  }

  if (str.startsWith('639')) {
    str = '+63 9' + str.substring(3)
  }

  const formattedStr = str.replace(/(\+639)(\d{9})$/, '$1$2')

  return formattedStr
}

export default formatMobileNumber

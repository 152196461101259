import { createSelector } from '@reduxjs/toolkit'

import { INITIAL_STATE } from './constants'

const selectRenewMayaDomain = (state) => state.renewMayaSlice || INITIAL_STATE

export const makeSelectIsShowMayaRenew = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.isShowMayaRenew)
export const makeSelectIsShowMayaXLanders = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isShowMayaXLanders,
  )

export const makeSelectMembershipSF = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.membershipSfData)

export const makeSelectisShowMayaCompleteOrder = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isShowMayaCompleteOrder,
  )

export const makeSelectIsShowCompleteMaya = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isShowCompleteMaya,
  )
export const makeSelectIsMayaCCOnly = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.isMayaCCOnly)

export const makeSelectSelectedMembershipProduct = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.selectedMembershipProducts,
  )

export const makeSelectVerbiageMaya = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.verbiage)

export const makeSelectIsShowOnlineAccountRequired = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isShowOnlineAccountRequired,
  )

export const makeSelectIsLoginWithMayaCC = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isLoginWithMayaCC,
  )

export const makeSelectIsShowSnackbar = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.isShowSnackbar)

export const makeSelectSnackbarDetails = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.snackbarDetails)

export const makeSelectIsShowLinkMembershipCard = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isShowLinkMembershipCard,
  )

export const makeSelectIsShowMembershipLinkFormModal = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isShowMembershipLinkFormModal,
  )

export const makeSelectMayaCCButtonOption = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.mayaCCButtonOption,
  )

export const makeSelectIsShowExpireMcOpen = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.isShowExpireMC)

export const makeSelectExpireMCAlert = () =>
  createSelector(selectRenewMayaDomain, (subState) => subState.expireMCAlert)

export const makeSelectMembershipEmailExistNotif = () =>
  createSelector(
    selectRenewMayaDomain,
    (subState) => subState.isShowMembershipEmailExistNotif,
  )

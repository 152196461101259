import con from '../../constant/actionConstant'

const initialState = {
	loginModal: false,
	signupModal: false,
}
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case con.openLoginModal:
			return {
				...state,
				loginModal: true,
				signupModal: false,
			}
		case con.openSignupModal:
			return {
				...state,
				signupModal: true,
				loginModal: false,
			}
		case con.closeAuthModal:
			return {
				...state,
				signupModal: false,
				loginModal: false,
			}
		default:
			return state
	}
}
export default reducer

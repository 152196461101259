import { trackEvent, trackingEvent } from 'utils/firebase'

export function ga4AddPaymentInfo(cartTotals, method, couponCode = '') {
  const { grand_total } = cartTotals
  trackEvent(trackingEvent.addPaymentInfo, {
    currency: 'PHP',
    value: grand_total,
    payment_type: method,
    coupon: couponCode,
  })
}

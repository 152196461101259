import { get, cloneDeep, find, set } from 'lodash'
import dayjs from 'dayjs'
import { getCurrentUser } from 'utils/validate'
import config from 'global-config'

/**
 * Convert Address to ShippingAddress
 * @constructor
 * @param {AddressItem} address - user address in containers/Account/models/AddressItem
 * @return {ShippingAddress} shippingAddress in containers/Checkout/models/ShippingAddress
 */

export const membershipInfoToAddress = (membershipInfo) => ({
  id: null,
  city: get(membershipInfo, 'city.name'),
  country_id: 'PH',
  custom_attributes: [
    {
      attribute_code: 'location_label',
      value: '',
    },
    {
      attribute_code: 'company',
      value: '',
    },
    {
      attribute_code: 'building_name',
      value: '',
    },
    {
      attribute_code: 'landmark',
      value: get(membershipInfo, 'building_name'),
    },
  ],
  customer_id: getCurrentUser().id,
  default_billing: false,
  default_shipping: false,
  firstname: get(membershipInfo, 'first_name'),
  lastname: get(membershipInfo, 'last_name'),
  postcode: get(membershipInfo, 'barangay_name.postcode'),
  region: {
    region_code: get(membershipInfo, 'region_name.code'),
    region: get(membershipInfo, 'region_name.name'),
    region_id: get(membershipInfo, 'region_name.id'),
  },
  region_id: get(membershipInfo, 'region_name.id'),
  street: [get(membershipInfo, 'street')],
  telephone: get(membershipInfo, 'phone'),
  extension_attributes: {
    township: get(membershipInfo, 'barangay_name.name'),
    township_id: get(membershipInfo, 'barangay_name.id'),
    city_id: get(membershipInfo, 'city.id'),
  },
})

export const addressToShippingAddress = (
  selectedAddress,
  selectedTimeslot = {},
) => {
  const address = cloneDeep(selectedAddress)
  const { custom_attributes } = address

  const addressLocationLabel =
    find(
      custom_attributes,
      (attr) => attr.attribute_code === 'location_label',
    ) || {}
  const company =
    find(custom_attributes, (attr) => attr.attribute_code === 'company') || {}
  const building =
    find(
      custom_attributes,
      (attr) => attr.attribute_code === 'building_name',
    ) || {}
  const landmark =
    find(custom_attributes, (attr) => attr.attribute_code === 'landmark') || {}

  const shippingAddress = {
    countryId: get(address, 'country_id', ''),
    regionId: get(address, 'region.region_id', ''),
    region: get(address, 'region.region', ''),
    street: get(address, 'street', []),
    customerId: get(address, 'customer_id', 1),
    company: get(company, 'value', ''),
    telephone: get(address, 'telephone', ''),
    fax: get(address, 'telephone', ''),
    postcode: get(address, 'postcode', 0),
    same_as_billing: 1,
    city: get(address, 'city', ''),
    firstname: get(address, 'firstname', ''),
    lastname: get(address, 'lastname', ''),
    middlename: null,
    prefix: null,
    suffix: null,
    extension_attributes: {
      allow_go_green: selectedTimeslot ? selectedTimeslot.allow_go_green : 0,
      city_id: get(address, 'extension_attributes.city_id', ''),
      location_label: get(addressLocationLabel, 'value', ''),
      building_name: get(building, 'value', ''),
      landmark: get(landmark, 'value', ''),
      township: get(address, 'extension_attributes.township', ''),
      township_id: get(address, 'extension_attributes.township_id', ''),
    },
    save_in_address_book: address.save_in_address_book ? 1 : 0,
  }
  return shippingAddress
}

export const mapStoreAddressToShippingAddress = (
  shippingAddress,
  storeAddress,
) => {
  const nextAddress = {
    ...shippingAddress,
    region: storeAddress.datastore.region_name,
    regionId: storeAddress.datastore.region_id,
    city: storeAddress.datastore.city_name,
    postcode: storeAddress.datastore.postcode,
    street: [storeAddress.storeaddress],
  }

  set(nextAddress, 'extension_attributes', {
    township_id: storeAddress.townshipid,
    township: storeAddress.storeaddress,
    city_id: storeAddress.datastore.city_id,
    building_name: '',
    landmark: '',
    location_label: '',
  })

  return nextAddress
}

export const currentLocationToMembershipAddress = (
  currentLocation,
  currentUser = {},
) => ({
  id: null,
  city: get(currentLocation, 'city.name'),
  country_id: 'PH',
  custom_attributes: [
    {
      attribute_code: 'location_label',
      value: 'landers',
    },
    {
      attribute_code: 'company',
      value: 'landers',
    },
    {
      attribute_code: 'subDivision',
      value: 'landers',
    },
    {
      attribute_code: 'landmark',
      value: 'landers',
    },
  ],
  customer_id: currentUser.id,
  default_billing: false,
  default_shipping: false,
  firstname: 'landers',
  lastname: 'landers',
  postcode: get(currentLocation, 'barangay.postcode'),
  region: {
    region_code: get(currentLocation, 'province.code'),
    region: get(currentLocation, 'province.name'),
    region_id: get(currentLocation, 'province.id'),
  },
  region_id: get(currentLocation, 'province.id'),
  street: ['landers'],
  telephone: '000000000000',
  extension_attributes: {
    township: get(currentLocation, 'barangay.name'),
    township_id: get(currentLocation, 'barangay.id'),
    city_id: get(currentLocation, 'city.id'),
  },
})

export const currentLocationToUserAddress = (
  currentLocation,
  currentUser = {},
) => ({
  id: null,
  city: get(currentLocation, 'city.name'),
  country_id: 'PH',
  custom_attributes: [
    {
      attribute_code: 'location_label',
      value: '',
    },
    {
      attribute_code: 'company',
      value: '',
    },
    {
      attribute_code: 'subDivision',
      value: '',
    },
    {
      attribute_code: 'landmark',
      value: '',
    },
  ],
  customer_id: currentUser.id,
  default_billing: false,
  default_shipping: false,
  firstname: '',
  lastname: '',
  postcode: get(currentLocation, 'barangay.postcode'),
  region: {
    region_code: get(currentLocation, 'province.code'),
    region: get(currentLocation, 'province.name'),
    region_id: get(currentLocation, 'province.id'),
  },
  region_id: get(currentLocation, 'province.id'),
  street: [''],
  telephone: '',
  extension_attributes: {
    township: get(currentLocation, 'barangay.name'),
    township_id: get(currentLocation, 'barangay.id'),
    city_id: get(currentLocation, 'city.id'),
  },
})

export const userAddressToFormikValues = (userAddress) => ({
  id: get(userAddress, 'id'),
  default_shipping: get(userAddress, 'default_shipping'),
  default_billing: get(userAddress, 'default_billing'),
  firstname: get(userAddress, 'firstname'),
  lastname: get(userAddress, 'lastname'),
  company: get(userAddress, 'company'),
  telephone: get(userAddress, 'telephone'),
  save_in_address_book: get(userAddress, 'save_in_address_book'),
  location_label: get(
    find(
      userAddress.custom_attributes,
      (attribute) => attribute.attribute_code === 'location_label',
    ),
    'value',
  ),
  street: get(userAddress, 'street[0]'),
  subDivision: get(
    find(
      userAddress.custom_attributes,
      (attribute) => attribute.attribute_code === 'building_name',
    ),
    'value',
  ),
  landmark: get(
    find(
      userAddress.custom_attributes,
      (attribute) => attribute.attribute_code === 'landmark',
    ),
    'value',
  ),
  postcode: get(userAddress, 'postcode'),
  province: {
    id: get(userAddress, 'region.region_id'),
    name: get(userAddress, 'region.region'),
    code: get(userAddress, 'region.region_code'),
  },
  city: {
    id: get(userAddress.extension_attributes, 'city_id'),
    name: get(userAddress, 'city'),
  },
  barangay: {
    id: get(userAddress.extension_attributes, 'township_id'),
    name: get(userAddress.extension_attributes, 'township'),
    postcode: get(userAddress, 'postcode'),
  },
})

export const formikValuesToUserAddress = (formikValues, currentUser = {}) => {
  let save_in_address_book_value = formikValues?.save_in_address_book
  if (save_in_address_book_value === undefined) {
    save_in_address_book_value = []
  }

  let values = {
    city: get(formikValues, 'city.name'),
    id: formikValues.id,
    country_id: 'PH',
    custom_attributes: [
      {
        attribute_code: 'location_label',
        value: get(formikValues, 'location_label', ''),
      },
      {
        attribute_code: 'building_name',
        value: get(formikValues, 'subDivision', ''),
      },
      {
        attribute_code: 'landmark',
        value: get(formikValues, 'landmark', ''),
      },
    ],
    save_in_address_book: formikValues.default_shipping ? 1 : 0,
    customer_id: currentUser.id,
    default_billing: formikValues.default_shipping,
    default_shipping: formikValues.default_shipping,
    firstname: formikValues.firstname,
    lastname: formikValues.lastname,
    postcode: get(formikValues, 'barangay.postcode'),
    region: {
      region_code: get(formikValues, 'province.code'),
      region: get(formikValues, 'province.name'),
      region_id: get(formikValues, 'province.id'),
    },
    region_id: get(formikValues, 'province.id'),
    street: [get(formikValues, 'street')],
    telephone: get(formikValues, 'telephone'),
    extension_attributes: {
      township: get(formikValues, 'barangay.name'),
      township_id: get(formikValues, 'barangay.id'),
      city_id: get(formikValues, 'city.id'),
      nationwide: get(formikValues, 'barangay.extension_attributes.nationwide'),
    },
  }
  const company = get(formikValues, 'company')
  if (company) {
    values.custom_attributes.push({
      attribute_code: 'company',
      value: get(formikValues, 'company'),
    })
  }
  return values
}

export const productToCartItem = (product) => {
  const productItem = cloneDeep(product)
  const finalPrice = get(productItem, 'extension_attributes.final_price')

  const cartItem = {
    base_row_total: finalPrice * productItem.qty,
    qty: productItem.qty,
    price: finalPrice,
    name: productItem.name,
    sku: get(productItem, 'sku'),
    item_id: productItem.item_id,
    product_type: productItem.type_id,
    extension_attributes: {
      product_data: productItem,
    },
    special_instructions: productItem?.special_instructions || '',
  }

  return cartItem
}

function formatDay(day) {
  return dayjs(day).format('MMM Do YYYY - dddd')
}

export const getDataByCreatedOrder = (createdOrder, orderId) => {
  const {
    customer_email,
    grand_total,
    created_at,
    payment,
    billing_address,
    extension_attributes,
  } = createdOrder
  const firstName = get(billing_address, 'firstname')
  const lastName = get(billing_address, 'lastname')
  const street = get(billing_address, 'street[0]')
  const postcode = get(billing_address, 'postcode')
  const mobile = get(billing_address, 'telephone')
  const city = get(billing_address, 'city')
  const region = get(billing_address, 'region')
  const emailReceiver = get(billing_address, 'email')

  const deliveryTime = get(extension_attributes, 'amdeliverydate_time')
  const deliveryDate = formatDay(
    get(extension_attributes, 'amdeliverydate_date'),
  )
  const barangay = get(
    extension_attributes,
    'shipping_assignments[0].shipping.address.extension_attributes.township',
    '...',
  )

  const addressReceiver = `${street} St., Barangay ${barangay}, ${city}, ${region} ${postcode}` //'32B, Champaca St., Arya Residenses Bldg./Subd., Barangay Loma, Binan, Laguna 4028 09077280034',
  const orderDate = formatDay(created_at)

  const infoReceiver = {
    name: `${firstName} ${lastName}`,
    address: addressReceiver,
    email: emailReceiver,
    mobile,
  }

  const orderInformation = {
    orderId: orderId,
    price: `₱${grand_total}`,
    orderDate, //'Jan 15th, 2019 - Thursday',
    paymentMethod: get(payment, 'additional_information[0]'), //'Credit Card/ Debit Card',
    address: addressReceiver,
  }

  return {
    customer_email,
    orderInformation,
    infoReceiver,
    deliveryDate,
    deliveryTime,
  }
}

export const payPlaceOrderPayUrl = () => {
  const payPlaceOrderUrl = [
    {
      mgtUrl: `${config.apiUrl}/snapmart-quote/save-address-information`,
    },
    {
      mgtUrl: `${config.apiUrl}/snapmart-quote/save-payment-information`,
    },
    {
      mgtUrl: `${config.apiUrl}/snapmart-quote/pay-placeorder`,
    },
  ]
  return payPlaceOrderUrl
}

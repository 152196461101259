export const directoryCountries = {
  id: 'PH',
  two_letter_abbreviation: 'PH',
  three_letter_abbreviation: 'PHL',
  full_name_locale: 'Philippines',
  full_name_english: 'Philippines',
  available_regions: [
    {
      id: '569',
      code: 'PH-1',
      name: 'Abra',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '570',
      code: 'PH-2',
      name: 'Agusan Del Norte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '571',
      code: 'PH-3',
      name: 'Agusan Del Sur',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '572',
      code: 'PH-4',
      name: 'Aklan',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '573',
      code: 'PH-5',
      name: 'Albay',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '574',
      code: 'PH-6',
      name: 'Antique',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '575',
      code: 'PH-7',
      name: 'Aurora',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '576',
      code: 'PH-8',
      name: 'Basilan',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '577',
      code: 'PH-9',
      name: 'Bataan',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '578',
      code: 'PH-10',
      name: 'Batangas',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'alabang',
      },
    },
    {
      id: '579',
      code: 'PH-11',
      name: 'Benguet',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '580',
      code: 'PH-12',
      name: 'Biliran',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '581',
      code: 'PH-13',
      name: 'Bohol',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '582',
      code: 'PH-14',
      name: 'Bukidnon',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '583',
      code: 'PH-15',
      name: 'Bulacan',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'balintawak',
      },
    },
    {
      id: '584',
      code: 'PH-16',
      name: 'Cagayan',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '585',
      code: 'PH-17',
      name: 'Camarines Norte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '586',
      code: 'PH-18',
      name: 'Camarines Sur',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '587',
      code: 'PH-19',
      name: 'Camiguin',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '588',
      code: 'PH-20',
      name: 'Capiz',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '589',
      code: 'PH-21',
      name: 'Catanduanes',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '590',
      code: 'PH-22',
      name: 'Cavite',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'alabang',
      },
    },
    {
      id: '591',
      code: 'PH-23',
      name: 'Cebu',
      extension_attributes: { allow_shipping: true, stock_source_code: 'cebu' },
    },
    {
      id: '592',
      code: 'PH-24',
      name: 'Compostela Valley',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '593',
      code: 'PH-25',
      name: 'Cotabato',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '594',
      code: 'PH-26',
      name: 'Davao Del Norte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '595',
      code: 'PH-27',
      name: 'Davao Del Sur',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '596',
      code: 'PH-28',
      name: 'Davao Oriental',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '597',
      code: 'PH-29',
      name: 'Dinagat Islands',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '598',
      code: 'PH-30',
      name: 'Eastern Samar',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '599',
      code: 'PH-31',
      name: 'Guimaras',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '600',
      code: 'PH-32',
      name: 'Ifugao',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '601',
      code: 'PH-33',
      name: 'Ilocos Norte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '602',
      code: 'PH-34',
      name: 'Ilocos Sur',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '603',
      code: 'PH-35',
      name: 'Iloilo',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '604',
      code: 'PH-36',
      name: 'Isabela',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '605',
      code: 'PH-37',
      name: 'Kalinga',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '606',
      code: 'PH-38',
      name: 'La Union',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '607',
      code: 'PH-39',
      name: 'Laguna',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'alabang',
      },
    },
    {
      id: '608',
      code: 'PH-40',
      name: 'Lanao Del Norte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '609',
      code: 'PH-41',
      name: 'Lanao Del Sur',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '713',
      code: 'PH-101',
      name: 'Landers ALABANG STORE COLLECTION',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '712',
      code: 'PH-100',
      name: 'Landers ARCOVIA STORE COLLECTION',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '711',
      code: 'PH-99',
      name: 'Landers BALINTAWAK STORE COLLECTION',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '714',
      code: 'PH-102',
      name: 'Landers CEBU STORE COLLECTION',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '710',
      code: 'PH-98',
      name: 'Landers OTIS STORE COLLECTION',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '610',
      code: 'PH-42',
      name: 'Leyte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '611',
      code: 'PH-43',
      name: 'Maguindanao',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '612',
      code: 'PH-44',
      name: 'Marinduque',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '613',
      code: 'PH-45',
      name: 'Masbate',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '648',
      code: 'PH-80',
      name: 'Metro Manila-Caloocan',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'balintawak',
      },
    },
    {
      id: '649',
      code: 'PH-81',
      name: 'Metro Manila-Las Pinas',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'alabang',
      },
    },
    {
      id: '650',
      code: 'PH-82',
      name: 'Metro Manila-Makati',
      extension_attributes: { allow_shipping: true, stock_source_code: 'otis' },
    },
    {
      id: '651',
      code: 'PH-83',
      name: 'Metro Manila-Malabon',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'balintawak',
      },
    },
    {
      id: '652',
      code: 'PH-84',
      name: 'Metro Manila-Mandaluyong',
      extension_attributes: { allow_shipping: true, stock_source_code: 'otis' },
    },
    {
      id: '653',
      code: 'PH-85',
      name: 'Metro Manila-Manila',
      extension_attributes: { allow_shipping: true, stock_source_code: 'otis' },
    },
    {
      id: '654',
      code: 'PH-86',
      name: 'Metro Manila-Marikina',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'arcovia',
      },
    },
    {
      id: '655',
      code: 'PH-87',
      name: 'Metro Manila-Muntinlupa',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'alabang',
      },
    },
    {
      id: '656',
      code: 'PH-88',
      name: 'Metro Manila-Navotas',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'balintawak',
      },
    },
    {
      id: '657',
      code: 'PH-89',
      name: 'Metro Manila-Paranaque',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'alabang',
      },
    },
    {
      id: '658',
      code: 'PH-90',
      name: 'Metro Manila-Pasay',
      extension_attributes: { allow_shipping: true, stock_source_code: 'otis' },
    },
    {
      id: '659',
      code: 'PH-91',
      name: 'Metro Manila-Pasig',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'arcovia',
      },
    },
    {
      id: '660',
      code: 'PH-92',
      name: 'Metro Manila-Pateros',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'arcovia',
      },
    },
    {
      id: '661',
      code: 'PH-93',
      name: 'Metro Manila-Quezon City',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'balintawak',
      },
    },
    {
      id: '662',
      code: 'PH-94',
      name: 'Metro Manila-San Juan',
      extension_attributes: { allow_shipping: true, stock_source_code: 'otis' },
    },
    {
      id: '663',
      code: 'PH-95',
      name: 'Metro Manila-Taguig',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'arcovia',
      },
    },
    {
      id: '664',
      code: 'PH-96',
      name: 'Metro Manila-Valenzuela',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'balintawak',
      },
    },
    {
      id: '614',
      code: 'PH-46',
      name: 'Misamis Occidental',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '615',
      code: 'PH-47',
      name: 'Misamis Oriental',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '616',
      code: 'PH-48',
      name: 'Mountain Province',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '617',
      code: 'PH-49',
      name: 'Negros Occidental',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '618',
      code: 'PH-50',
      name: 'Negros Oriental',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '619',
      code: 'PH-51',
      name: 'North Cotabato',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '620',
      code: 'PH-52',
      name: 'Northern Samar',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '621',
      code: 'PH-53',
      name: 'Nueva Ecija',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '622',
      code: 'PH-54',
      name: 'Nueva Vizcaya',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '623',
      code: 'PH-55',
      name: 'Occidental Mindoro',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '624',
      code: 'PH-56',
      name: 'Oriental Mindoro',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '625',
      code: 'PH-57',
      name: 'Palawan',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '626',
      code: 'PH-58',
      name: 'Pampanga',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '627',
      code: 'PH-59',
      name: 'Pangasinan',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '628',
      code: 'PH-60',
      name: 'Quezon',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '629',
      code: 'PH-61',
      name: 'Quirino',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '630',
      code: 'PH-62',
      name: 'Rizal',
      extension_attributes: {
        allow_shipping: true,
        stock_source_code: 'arcovia',
      },
    },
    {
      id: '631',
      code: 'PH-63',
      name: 'Romblon',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '632',
      code: 'PH-64',
      name: 'Sarangani',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '633',
      code: 'PH-65',
      name: 'Siquijor',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '634',
      code: 'PH-66',
      name: 'Sorsogon',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '635',
      code: 'PH-67',
      name: 'South Cotabato',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '636',
      code: 'PH-68',
      name: 'Southern Leyte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '637',
      code: 'PH-69',
      name: 'Sultan Kudarat',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '638',
      code: 'PH-70',
      name: 'Sulu',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '639',
      code: 'PH-71',
      name: 'Surigao Del Norte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '640',
      code: 'PH-72',
      name: 'Surigao Del Sur',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '641',
      code: 'PH-73',
      name: 'Tarlac',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '642',
      code: 'PH-74',
      name: 'Tawi-Tawi',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '643',
      code: 'PH-75',
      name: 'Western Samar',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '644',
      code: 'PH-76',
      name: 'Zambales',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '645',
      code: 'PH-77',
      name: 'Zamboanga Del Norte',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '646',
      code: 'PH-78',
      name: 'Zamboanga Del Sur',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
    {
      id: '647',
      code: 'PH-79',
      name: 'Zamboanga Sibugay',
      extension_attributes: { allow_shipping: false, stock_source_code: '' },
    },
  ],
}

/*
 *
 * Authent constants
 *
 */

//SignUp Screen
export const SIGN_UP = 'Landers/Authentication/signUp'
export const SIGN_UP_SUCCESS = 'Landers/Authentication/signUp success'
export const SIGN_UP_FAILED = 'Landers/Authentication/signup fail'
export const CHECK_MEMBERSHIP = 'Landers/Authentication/check membership'
export const CHECK_MEMBERSHIP_SUCCESS =
  'Landers/Authentication/check membership success'
export const CHECK_MEMBERSHIP_FAILED =
  'Landers/Authentication/check membership fail'

//Login Screen
export const LOGIN = 'Landers/Authentication/Login'
export const LOGIN_SUCCESS = 'Landers/Authentication/LoginSuccess'
export const LOGIN_FAILED = 'Landers/Authentication/LoginFailed'

//Login Social
export const LOGIN_SOCIAL = 'Landers/Authentication/LoginSocial'
export const LOGIN_SOCIAL_SUCCESS = 'Landers/Authentication/LoginSocialSuccess'
export const LOGIN_SOCIAL_FAILED = 'Landers/Authentication/LoginSocialFailed'

export const RESET_PASSWORD = 'Landers/Authentication/ResetPassword'
export const RESET_PASSWORD_SUCCESS =
  'Landers/Authentication/ResetPasswordSuccess'
export const RESET_PASSWORD_FAILED =
  'Landers/Authentication/ResetPasswordFailed'
export const CLEAR_DATA_FORGOT_PASSWORD =
  'Landers/Authentication/CLEAR_DATA_FORGOT_PASSWORD'

// Reset password
export let CREATE_PASSWORD = 'Landers/Authentication/CreatePassword'
export let CREATE_PASSWORD_SUCCESS =
  'Landers/Authentication/CreatePasswordSuccess'
export let CREATE_PASSWORD_FAILED =
  'Landers/Authentication/CreatePasswordFailed'

// Get lambda access token
export const GET_LAMBDA_ACCESS_TOKEN =
  'Landers/Authentication/GetLambdaAccessToken'
export const SET_LAMBDA_ACCESS_TOKEN =
  'Landers/Authentication/SetLambdaAccessToken'

export const SET_LOGIN_TYPE = 'Landers/Authentication/SetLoginType'

import { initialState } from './reducer'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { checkCartHasOnlyMC } from './components/CartTotals/helper'
import { getBizRebateAmount } from 'utils/helpers'

const selectCheckoutDomain = (state) => state.rncheckout || initialState

export const makeSelectCartData = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.cartData)

export const makeSelectCartTotals = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.cartTotals)

export const makeSelectCartMaskId = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.cartMaskId)

export const makeSelectCartId = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.cartData.id)

export const makeSelectNeededMergedItems = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.neededMergedItems)

export const makeSelectInvalidCartItems = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.invalidCartItems)

export const makeSelectPaymentMethods = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.paymentMethods)

export const makeSelectMediaPath = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.mediaPath)

export const makeSelectDeliveryTimeslots = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.deliveryTimeslots)

export const makeSelectSelectedTimeslot = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.selectedTimeslot)

export const makeSelectSelectedPaymentMethod = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.selectedPaymentMethod,
  )

export const makeSelectNewUpdatedQtys = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.newUpdatedQtys)

export const makeSelectOldUpdatedQtys = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.oldUpdatedQtys)

export const makeSelectShippingAddress = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.shippingAddress)

export const makeSelectOrderId = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.orderId)

export const makeSelectShoppingNow = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.shoppingNow)

export const makeSelectProgressBar = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.progressBar)

export const makeSelectCreatedOrder = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.createdOrder)

export const makeSelectRecommendProducts = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.recommendProducts)

export const makeSelectCouponCode = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.couponCode)

export const makeSelectDataCoupon = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.dataCheckCoupon)

export const makeSelectCartItemsCount = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.cartItemsCount)

export const makeSelectCartNeedSync = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.cartNeedSync)

export const makeSelectCancellationOption = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.cancellationOption,
  )

export const makeSelectDefaultCancellationOption = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.defaultCancellationOption,
  )

export const makeSelectCartHasMembershipSKUOnly = () =>
  createSelector(selectCheckoutDomain, (substate) => {
    return checkCartHasOnlyMC(
      get(substate, 'cartData.promotion_items') ||
        get(substate, 'cartData.items') ||
        [],
    )
  })

export const makeSelectFeeTooltipVisible = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.feeTooltipVisible)

export const makeSelectIsRestoreCartProcessing = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.isRestoreCartProcessing,
  )

export const makeSelectLastOrderOOS = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.lastOrderOOS)

export const makeSelectIsSubmittedPayment = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.isSubmittedPayment,
  )

export const makeSelectStoresTimeSlots = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.storesTimeSlots)

export const makeSelectSelectedPaymentCard = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.selectedPaymentCard,
  )

export const makeSelectPaymentCards = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.paymentCards)

export const makeSelectShowSlowCartLoading = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.showSlowCartLoading,
  )

export const makeSelectProductRecommendation1 = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.productRecommendations1,
  )

export const makeSelectProductRecommendation2 = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.productRecommendations2,
  )

export const makeSelectCartId2 = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.cartId)

export const makeSelectIsSyncingProduct = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.isSyncingProduct)
export const makeSelectPayPlaceOrderResponse = () =>
  createSelector(
    selectCheckoutDomain,
    (substate) => substate.payPlaceOrderResponse,
  )

export const makeSelectBizRebateAmount = () =>
  createSelector(selectCheckoutDomain, (state) => {
    if (state.bizRebateAmount === 0) {
      return getBizRebateAmount(state.cartData.promotion_items)
    }

    return state.bizRebateAmount
  })

export const makeSelectCartTempTotals = () =>
  createSelector(selectCheckoutDomain, (state) => state.tempCartTotals)

export const makeSelectTempItems = () =>
  createSelector(selectCheckoutDomain, (substate) => substate.tempItems)

export const makeSelectCartItemBySku = (sku) =>
  createSelector(selectCheckoutDomain, (substate) => {
    const item = substate.tempItems.find((item) => item.sku === sku)

    if (item) {
      return item
    }

    return false
  })

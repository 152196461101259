import axios from 'axios';

import { getAccessToken } from './request';
import config from 'global-config';
import { store } from 'configureStore';
import { setLambdaAccessToken } from 'containers/Authentication/actions';
import { mutex } from 'api/mutex';
import { isTokenExpired } from 'api/utils';

const NOT_AUTH_API_PATHS = ['/login', '/guest-carts'];

const instance = axios.create({
  headers: {},
});

instance.interceptors.response.use(undefined, async (error) => {
  await mutex.waitForUnlock();
  const statusCode = error.request.status;
  const { config } = error;

  const accessToken = store.getState()?.rnglobal.accessToken;

  if (accessToken === '') {
    return Promise.reject(error);
  }

  if (!config || !config.retry) {
    return Promise.reject(error);
  }

  config.retry -= 1;

  const isNotAuthAPI = NOT_AUTH_API_PATHS.every(
    (path) => !config.url.includes(path),
  );

  if (
    ((error.code === 'ERR_NETWORK' && statusCode === 0) ||
      statusCode === 401) &&
    isNotAuthAPI &&
    accessToken !== ''
  ) {
    await reAuth();
    return instance(config);
  }

  return Promise.reject(error);
});

instance.interceptors.request.use(
  async (config) => {
    const isNotAuthAPI = NOT_AUTH_API_PATHS.every(
      (path) => !config.url.includes(path),
    );

    if (isNotAuthAPI) {
      await mutex.waitForUnlock();
      const accessToken = store.getState()?.rnglobal.accessToken;

      if (isTokenExpired() && accessToken !== '') {
        await reAuth();
      }
    }

    const nodeAccessToken =
      store.getState()?.rnauthen.lambdaAccessToken.access_token;

    config.headers = {
      ...config.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${nodeAccessToken}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const reAuth = async () => {
  if (mutex.isLocked()) {
    return;
  }

  const email = store.getState()?.rnglobal.currentUserEmail;
  const requestURL = `${config.lambdaURL.user}/login`;
  const release = await mutex.acquire();

  try {
    const { data } = await instance.post(requestURL, {
      access: getAccessToken(),
      email: email,
    });

    store.dispatch(setLambdaAccessToken(data.data));
  } catch (error) {
  } finally {
    release();
  }
};

export function req(url, options) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  // Merge options with default headers, giving priority to custom headers
  const mergedHeaders = Object.assign({}, defaultHeaders, options.headers);

  // Create the final options object with merged headers
  const parsedOptions = Object.assign({}, options, { headers: mergedHeaders });

  return instance({
    url,
    retry: 1,
    ...parsedOptions,
  });
}

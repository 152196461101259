import { initialState } from './reducer';
import { createSelector } from 'reselect';

/**
 * Direct selector to the authorize state domain
 */

const selectShoppingListDomain = state => state.shoppingList || initialState;

/**
 * Other specific selectors
 */


export const makeSelectLoading = () =>
  createSelector(
    selectShoppingListDomain,
    substate => substate.getShoppingListLoading,
  );

export const makeSelectDataShoppingList = () =>
  createSelector(
    selectShoppingListDomain,
    substate => substate.shoppingList,
  );

export const makeSelectDataShoppingListDetail = () =>
  createSelector(
    selectShoppingListDomain,
    substate => substate.shoppingListDetail,
  );

export const makeSelectDataShoppingListDetailFilter = () =>
  createSelector(
    selectShoppingListDomain,
    substate => substate.shoppingDetailFilter,
  );

export const makeSelectDataShoppingListDetailLoading = () =>
  createSelector(
    selectShoppingListDomain,
    substate => substate.getListDetailLoading,
  );

export const makeSelectEditableErrorMessage = () =>
  createSelector(
    selectShoppingListDomain,
    substate => substate.editableErrorMessage,
  );

export const makeSelectShareShoppingListData = () =>
  createSelector(
    selectShoppingListDomain,
    substate => substate.shareShoppingListData,
  );

import con from '../../constant/actionConstant'

const initialState = {
	userToken: '',
	user: {
		addresses: [],
		created_at: '', // 2019-08-26 15:07:31
		created_in: '', // Default Store View
		disable_auto_group_change: 0,
		email: '', // amoc1000@gmail.com
		extension_attributes: [],
		is_subscribed: false,
		firstname: null,
		group_id: 1,
		id: null, //48897
		lastname: null,
		store_id: 1,
		updated_at: '', //2019-08-31 03:24:58
		website_id: 1,
	},
	originalLocation: '',
	loading: false,
}
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case con.saveToken:
			return {
				...state,
				userToken: action.payload,
			}
		case con.loginStart:
			return {
				...state,
				loading: true,
			}
		case con.loginFinish:
			return {
				...state,
				loading: false,
			}
		case con.saveUserInfo:
			return {
				...state,
				user: action.payload,
			}
		case con.logout:
			return {
				...state,
				userToken: '',
				user: {},
			}
		case con.saveOriginalLocation:
			return {
				...state,
				originalLocation: action.payload,
			}
		case con.clearOriginalLocation:
			return {
				...state,
				originalLocation: '',
			}
		default:
			return state
	}
}
export default reducer

import * as checkoutActions from 'containers/Checkout/actions';
import { GET_CART_INFO_SUCCESS } from 'containers/Checkout/constants';
import * as homeActions from 'containers/Home/actions';
import * as globalActions from 'containers/Landers/actions';
import { updateFCMToken } from 'containers/Landers/actions';
import { GET_USER_INFO_SUCCESS } from 'containers/Landers/constants';
import { makeSelectCurrentUser } from 'containers/Landers/selectors';
import * as membershipActions from 'containers/Membership/actions';
import * as modalActions from 'containers/Modals/actions';
import { makeSelectShowLogin } from 'containers/Modals/selectors';
import { makeSelectIsLoginWithMayaCC } from 'containers/Modals/slices/renewMaya';
import { setShowCompleteMaya } from 'containers/Modals/slices/renewMaya/slice';
import config from 'global-config';
import { messageErrorForm, SCREEN } from 'global-constant';
import { get } from 'lodash';
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { getUserToken, trackEvent, trackingEvent } from 'utils/firebase';
import { isGrantedPermission } from 'utils/helper';
import { setAuthData, setDeviceId } from 'utils/localStorage';
import { getDispatch, navigate } from 'utils/navigation.js';
import { showError, showSuccess } from 'utils/notification';
import request, { setAccessToken } from 'utils/request';
import * as actions from './actions';
import * as constants from './constants';

export async function updateFCMTokenFunc() {
  try {
    const fcmToken = await getUserToken();
    const dispatch = getDispatch();
    if (fcmToken) {
      dispatch(updateFCMToken({ fcmToken }));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* onLogin(action) {
  const loginParams = yield select((state) => makeSelectShowLogin()(state));
  const { isSaveToShoppingList, item } = action.params;
  const requestURL = `${config.apiUrl}/customer/login`;
  const supportLogin = `${config.apiUrl}/customer-support/login`;
  const loginRedirect = localStorage.getItem('loginRedirect');
  yield put(globalActions.showLoader());
  try {
    let res = {};

    if (action.params.is_customer_support) {
      res = yield call(request, supportLogin, {
        method: 'POST',
        body: JSON.stringify({
          email: action.username,
        }),
      });
    } else {
      res = yield call(request, requestURL, {
        method: 'POST',
        body: JSON.stringify({
          username: action.username,
          password: action.password,
        }),
      });
    }

    setDeviceId();
    setAccessToken(res.access_token);
    setAuthData(res.access_token);

    yield put(globalActions.hideLoader());

    yield put(actions.loginSuccess(res));

    if (loginRedirect) {
      if (loginParams?.loadDefaultAddress) {
        yield put(
          globalActions.getUserInfo({
            needValidateMembership: true,
            needUpdateID: true,
            method: 'email/password',
          }),
        );
        yield take(GET_USER_INFO_SUCCESS);
        yield put(globalActions.updateShippingAddress());
        yield put(
          checkoutActions.getCartInfo({
            needReloadTotals: false,
          }),
        );
      }
      localStorage.removeItem('loginRedirect');
      window.location.pathname = loginRedirect;
    } else if (action.params.addMembership) {
      yield put(globalActions.hideLoader());
      yield put(
        globalActions.getUserInfo({
          needValidateDefaultShipping: true,
          needValidateMembership: false,
          needUpdateID: true,
          method: 'email/password',
        }),
      );
      yield take(GET_USER_INFO_SUCCESS);
      yield put(
        checkoutActions.getCartInfo({
          needReloadTotals: false,
        }),
      );
      yield take(GET_CART_INFO_SUCCESS);

      /**
       * VALIDATIONS FOR EXISTING MAYA CC AND USER
       * BLOCK IF GUEST USER APPLIED BEFORE AND HAS EXISTING CC
       **/
      const currentUser = yield select((state) =>
        makeSelectCurrentUser()(state),
      );
      const sfURL = `${config.apiUrl}/membership-information-sf`;
      const membershipInfoSF = yield call(request, sfURL, {
        method: 'POST',
        body: JSON.stringify({
          code:
            currentUser?.extension_attributes?.membership_info?.code || null,
        }),
      });
      const hasAppliedMayaCC =
        membershipInfoSF?.[0]?.landers_credit_card_applied || 0;

      if (hasAppliedMayaCC) {
        yield put(modalActions.hideMembershipModal());
        yield put(setShowCompleteMaya(true));
      } else {
        const { membershipRequest } = action.params;
        yield put(membershipActions.applyMembership(membershipRequest));
      }
    } else {
      yield put(
        globalActions.getUserInfo({
          needValidateMembership: true,
          needUpdateID: true,
          method: 'email/password',
        }),
      );
      yield take(GET_USER_INFO_SUCCESS);
      yield put(globalActions.hideLoader());
      yield put(globalActions.updateShippingAddress());
      yield put(
        checkoutActions.getCartInfo({
          needReloadTotals: false,
        }),
      );
    }
    yield put(modalActions.hideLoginModal());
    yield put(globalActions.showSideBar(false));
    if (isSaveToShoppingList && item) {
      yield put(modalActions.showModalAddProductToShoppingList(item));
    }
    if (isGrantedPermission()) {
      updateFCMTokenFunc();
    }

    yield put(modalActions.showAddMoreItemModal(false));
    yield put(homeActions.loadProducts());
    yield put(globalActions.reloadApiForPage());
  } catch (err) {
    if (action.params.is_customer_support) {
      showError('Customer email not found');
      return;
    }

    const setFieldValue = action.callback;
    if (err + '' === 'TypeError: Network request failed') {
      setFieldValue('invalidLogin', 'Network error');
    } else {
      err?.json().then((errorJson) => {
        const errMsg = errorJson?.message || '';

        if (errMsg) {
          setFieldValue('invalidLogin', errMsg);
        }

        if (!errMsg) {
          setFieldValue('invalidLogin', messageErrorForm.loginFailed);
        }
      });
    }
    yield put(globalActions.hideLoader());
    yield put(actions.loginFailed(err));
  }
}

// checking membership before signup
function* onCheckMembership(action) {
  const requestURL = `${config.apiUrl}/landersapi-membership`;
  const signUpRequest = action.signUpRequest;
  const isCaltexCard = signUpRequest.membershipId.length === 16;

  try {
    if (signUpRequest.linkToMembership) {
      const res = yield call(request, requestURL, {
        method: 'POST',
        body: JSON.stringify({
          membership_id: signUpRequest.membershipId,
          membership_name: signUpRequest.membershipName,
          membership_type: isCaltexCard ? 'caltex' : 'landers',
        }),
      });

      // membership is right
      if (!res.error) {
        yield put(actions.checkMemberShipActionSuccess());
        yield put(
          actions.signUpAction({
            ...signUpRequest,
            membershipType: isCaltexCard ? 'caltex' : 'landers',
          }),
        );
      } else {
        const showChat = get(res, 'message', '').includes(
          'issue was encountered while linking your Membership Card to your account',
        );

        yield put(modalActions.showSupportMessage(showChat));
        if (showChat === false) {
          yield put(actions.checkMemberShipActionFailed(res.message));
        }
      }
    } else {
      yield put(actions.signUpAction(signUpRequest));
    }
  } catch (err) {
    yield put(actions.checkMemberShipActionFailed(err));
  }
}

// call request signup
function* onSignUp(action) {
  const requestURL = `${config.apiUrl}/landersapi-customer-register`;
  const signUpRequest = action.signUpRequest;
  yield put(globalActions.showLoader());
  const inputParams = {
    customer: {
      email: signUpRequest.email,
      password: signUpRequest.password,
      password_confirmation: signUpRequest.passwordConfirmation,
      link_to_membership: signUpRequest.linkToMembership,
      membership_id: signUpRequest.membershipId,
      membership_name: signUpRequest.membershipName,
      is_subscribed: signUpRequest.isSubscribe,
      membership_type: signUpRequest.membershipType,
      recaptcha_response: signUpRequest.token,
    },
  };

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(inputParams),
    });

    if (!res.error) {
      const isLoginWithMayaCC = yield select((state) =>
        makeSelectIsLoginWithMayaCC()(state),
      );

      setDeviceId();
      if (!isLoginWithMayaCC) {
        showSuccess(res.message, 'signup-success');
      }
      setAccessToken(res.access_token);
      yield put(actions.signUpSuccessAction(res));
      if (action.params.addMembership) {
        trackEvent(trackingEvent.signupCompleted, {
          signup_with_membership: 'yes',
        });

        yield put(
          globalActions.getUserInfo({
            needValidateMembership: false,
            needUpdateID: true,
            method: 'email/password',
            isFromSignUp: true,
            isSubscribed: signUpRequest.isSubscribe,
          }),
        );
        yield take(GET_USER_INFO_SUCCESS);
        const { membershipRequest } = action.params;
        yield put(
          checkoutActions.getCartInfo({
            needReloadTotals: false,
          }),
        );

        yield take(GET_CART_INFO_SUCCESS);
        yield put(membershipActions.applyMembership(membershipRequest));
      } else {
        trackEvent(trackingEvent.signupCompleted, {
          signup_with_membership: 'no',
        });

        yield put(
          globalActions.getUserInfo({
            needValidateMembership: true,
            needUpdateID: true,
            method: 'email/password',
            isFromSignUp: true,
            isSubscribed: signUpRequest.isSubscribe,
          }),
        );
        yield take(GET_USER_INFO_SUCCESS);
        yield put(
          checkoutActions.getCartInfo({
            needReloadTotals: false,
            redirectTo: SCREEN.CHECKOUT_CART,
          }),
        );
      }
      if (isGrantedPermission()) {
        updateFCMTokenFunc();
      }

      // yield put(actions.login(signUpRequest.email, signUpRequest.password, action.params));
      yield put(globalActions.hideLoader());
      yield put(modalActions.hideLoginModal());
      yield put(globalActions.showSideBar(false));
      yield put(modalActions.showAddMoreItemModal(false));
      yield put(globalActions.reloadApiForPage());
    } else {
      yield put(globalActions.hideLoader());
      yield put(actions.signUpFailedAction(res));
      const errorMessage = get(res, 'message', '');
      if (
        errorMessage !== 'There is already an account with this email address.'
      ) {
        showError(res.message, 'signup-failed');
      }
    }
  } catch (err) {
    console.log('err:', err);
    yield put(globalActions.hideLoader());
    yield put(actions.signUpFailedAction(err));
  }
}

// call request login social
function* loginSocial(action) {
  const requestURL = `${config.apiUrl}/ld-social-token-login`;
  const loginSocialRequest = action.loginSocialRequest;
  const loginRedirect = localStorage.getItem('loginRedirect');
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        type: loginSocialRequest.type,
        socialToken: loginSocialRequest.socialToken,
      }),
    });
    setDeviceId();
    setAccessToken(res);
    yield put(modalActions.hideLoginModal());
    yield put(actions.loginSocialActionSuccess(res));
    yield put(
      globalActions.getUserInfo({
        needUpdateID: true,
        method: loginSocialRequest.type,
        isFromSignUp: loginSocialRequest?.isFromSignUp ? true : false,
        isSubscribed: loginSocialRequest?.isSubscribed,
      }),
    );
    yield take(GET_USER_INFO_SUCCESS);
    yield put(globalActions.updateShippingAddress());
    yield put(globalActions.reloadApiForPage());

    if (isGrantedPermission()) {
      updateFCMTokenFunc();
    }
    if (loginRedirect) {
      localStorage.removeItem('loginRedirect');
      window.location.pathname = loginRedirect;
    } else {
      yield put(
        checkoutActions.getCartInfo({
          needReloadTotals: false,
          redirectTo: SCREEN.CHECKOUT_CART,
        }),
      );
    }
    if (loginSocialRequest?.isFromSignUp) {
      trackEvent(trackingEvent.signupCompleted);
    }
  } catch (err) {
    yield put(actions.loginSocialActionFailed(err));
  }
}

function* resetPassword(action) {
  const requestURL = `${config.apiUrl}/customers/password`;
  const resetPasswordRequest = action.resetPasswordRequest;
  yield put(globalActions.showLoader());
  try {
    yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(resetPasswordRequest),
    });
    yield put(
      actions.resetPasswordActionSuccess(
        `If there is an account associated with ${resetPasswordRequest.email} you will receive an email with a link (which expires in 15 minutes) to reset your password.`,
      ),
    );
    yield delay(3000);
    yield put(modalActions.showForgotPasswordModal(false));
    yield put(globalActions.hideLoader());
  } catch (err) {
    yield put(globalActions.hideLoader());
    const errorJson = yield err.json();
    yield put(actions.resetPasswordActionFailed(errorJson));
  }
}

function* createPassword(action) {
  const requestURL = `${config.apiUrl}/customers/resetPassword`;
  yield put(globalActions.showLoader());
  const newPasswordRequest = {
    email: '',
    resetToken: action.payload.token,
    newPassword: action.payload.password,
  };
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(newPasswordRequest),
    });

    if (res) {
      showSuccess('New password has been set.');
    }
    yield delay(2000);

    // Go back to homepage
    yield navigate(SCREEN.HOME, {
      showLoginModal: true,
    });
    yield put(actions.createPasswordSuccess(res));
    yield put(globalActions.hideLoader());
  } catch (err) {
    yield put(globalActions.hideLoader());
    showError('The password token is expired. Reset and try again.');
    yield put(actions.createPasswordFailed(err));
  }
}

export default function* authenSaga() {
  yield takeLatest(constants.LOGIN, onLogin);
  yield takeLatest(constants.SIGN_UP, onSignUp);
  yield takeLatest(constants.CHECK_MEMBERSHIP, onCheckMembership);
  yield takeLatest(constants.LOGIN_SOCIAL, loginSocial);
  yield takeLatest(constants.RESET_PASSWORD, resetPassword);
  yield takeLatest(constants.CREATE_PASSWORD, createPassword);
}

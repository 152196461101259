import * as constant from './constants'

export const initialState = {
  productDetail: {},
  relatedProduct: [],
  reviewStatus: {
    isLoading: false,
    message: '',
  },
  shoppingAdded: [],
  errors: null,
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.LOAD_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: action.payload,
      }
    case constant.LOAD_RELATED_PRODUCT_SUCCESS:
      return {
        ...state,
        relatedProduct: action.payload,
      }
    case constant.ADD_PRODUCT_REVIEW:
      return {
        ...state,
        reviewStatus: {
          ...state.reviewStatus,
          isLoading: true,
          message: '',
        },
      }
    case constant.ADD_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        reviewStatus: {
          ...state.reviewStatus,
          isLoading: false,
          message: action.payload,
        },
      }
    case constant.ADD_PRODUCT_REVIEW_FAILED:
      return {
        ...state,
        reviewStatus: {
          ...state.reviewStatus,
          isLoading: false,
          message: action.payload,
        },
      }
    case constant.LOAD_SHOPPING_LIST_ADDED_SUCCESS:
      return {
        ...state,
        shoppingAdded: action.payload,
      }
    case constant.LOAD_PRODUCT_DETAIL_FAILED:
      return {
        ...state,
        productDetail: {},
        errors: action.payload,
      }
    case constant.LOAD_RELATED_PRODUCT_FAILED:
      return {
        ...state,
        relatedProduct: [],
        errors: action.payload,
      }
    case constant.LOAD_SHOPPING_LIST_ADDED_FAILED:
      return {
        ...state,
        shoppingAdded: [],
        errors: action.payload,
      }
    default:
      return state
  }
}

export default reducer

import { trackEvent, trackingEvent } from 'utils/firebase'

export function ga4TrackAddToCart(productPayload, sourceTracking) {
  const [product] = productPayload.items

  trackEvent(trackingEvent.addToCart, {
    currency: 'PHP',
    value: product?.extension_attributes?.final_price,
    items: [
      {
        item_id: product.sku,
        item_name: product.name,
        quantity: product.qty,
        price: product.price,
      },
    ],
    a2c_type: sourceTracking?.type,
    a2c_source: sourceTracking?.source || '',
    a2c_element: sourceTracking?.element || '',
    a2c_name: product?.name || '',
  })
}

export function ga4TrackAddToCartMC(productPayload, sourceTracking) {
  const product = productPayload?.data || productPayload
  trackEvent(trackingEvent.addToCart, {
    currency: 'PHP',
    value: product?.extension_attributes?.final_price,
    items: [
      {
        item_id: product.sku,
        item_name: product.name,
        quantity: product.qty,
        price: product.price,
      },
    ],
    a2c_type: sourceTracking?.a2c_type,
    a2c_source: sourceTracking?.a2c_source || '',
    a2c_element: sourceTracking?.a2c_element || '',
    a2c_name: product?.name || '',
  })
}

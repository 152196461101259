import { produce } from 'immer'
import con from '../../../constant/actionConstant'

const initialState = {
  success: false,
  loading: false,
}
const reducer = (state = initialState, action) => {
  return produce(state, (newstate) => {
    switch (action.type) {
      case con.triggerSignupSuccess:
        newstate.success = true
        break
      case con.signupStart:
        newstate.loading = true
        break
      case con.signupFinish:
        newstate.loading = false
        break
      default:
        break
    }
  })
}
export default reducer

import { LANDERS_VENDOR } from 'global-constant'
import { REBATE_PERCENTAGE } from 'config/b2b'
import { PROMOTION_TYPE } from 'constant/promotionType'

const getBizRebateAmount = (cartItems: any = []) => {
  if (cartItems.length === 0) {
    return 0
  }

  const totalAmount = cartItems.reduce((acc: number, currentItem: any) => {
    if (
      currentItem?.extension_attributes?.product_data?.extension_attributes
        ?.vendor === LANDERS_VENDOR ||
      currentItem?.extension_attributes?.product_data?.extension_attributes
        ?.vendor === undefined
    ) {
      if (currentItem.promotion.promotion_type === PROMOTION_TYPE.BUY_X_GET_Y) {
        return (
          acc +
          (currentItem.promotion.promotion_original_price -
            currentItem.promotion.promotion_row_saved)
        )
      }

      if (currentItem?.product_type === 'customer_membership') {
        return acc
      }

      if (currentItem.promotion.promotion_type === PROMOTION_TYPE.NORMAL) {
        return acc + currentItem.price * currentItem.qty
      }
    }

    return acc
  }, 0)

  return totalAmount * REBATE_PERCENTAGE
}

export default getBizRebateAmount

import { mgtApi as api } from '../mgtApi'
import { isEnableBusiness } from 'config/feature'
import { MEMBERSHIP_CARD_PACKAGE } from 'global-constant'

const addTagTypes = ['MCStickyNudge', 'MembershipProducts']

const membershipApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMCStickyNudgeConfig: build.query({
        query: () => ({
          url: `/membership-page-sticky-nudge`,
        }),
        providesTags: ['MCStickyNudge'],
      }),
      getMembershipProducts: build.query({
        query: () => ({
          url: `/membership-products`,
        }),
        transformResponse: (response) => {
          let membershipProducts = response

          if (!isEnableBusiness) {
            membershipProducts = response.filter(
              (product) =>
                product.type !== MEMBERSHIP_CARD_PACKAGE.BUSINESS_PACKAGE,
            )
          }

          return membershipProducts
        },
        providesTags: ['MembershipProducts'],
      }),
      getMembershipInfoSF: build.mutation({
        query: (queryArg) => ({
          url: '/membership-information-sf',
          method: 'POST',
          body: queryArg,
        }),
        transformResponse: (response) => {
          return response[0]
        },
      }),
      submitMembershipInfoSF: build.mutation({
        query: (queryArg) => ({
          url: '/landers-cc-signup',
          method: 'POST',
          body: queryArg,
        }),
      }),
      getMayaCCPromoVerbiage: build.query({
        query: (queryArg) => ({
          url: '/landers-cc-signup/promo-verbiage',
        }),
      }),
    }),
    overrideExisting: false,
  })

export const {
  useGetMCStickyNudgeConfigQuery,
  useGetMembershipProductsQuery,
  useLazyGetMembershipProductsQuery,
  useLazyGetMayaCCPromoVerbiageQuery,
} = membershipApi

export default membershipApi

import * as constant from './constants';

export function getAllHelp() {
  return {
    type: constant.GET_ALL_HELP,
  };
}

export function loadHelpSuccess(payload) {
  return {
    type: constant.HELP_SUCCESS,
    payload,
  };
}

export function loadHelpFailed(payload) {
  return {
    type: constant.HELP_FAILED,
    payload,
  };
}

export function postFaq(payload) {
  return {
    type: constant.POST_FAQ,
    payload,
  };
}

export function postFaqSuccess(payload) {
  return {
    type: constant.POST_FAQ_SUCCESS,
    payload,
  };
}

export function postFaqFailed(payload) {
  return {
    type: constant.POST_FAQ_FAILED,
    payload,
  };
}
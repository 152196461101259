import { initialState } from './reducer';
import { createSelector } from 'reselect';

const selectGlobalDomain = (state) => state.rnglobal || initialState;

export const makeSelectHasLoader = () =>
  createSelector(selectGlobalDomain, (subState) => subState.hasLoader);

export const makeSelectIsLoadingDeliveryLocation = () =>
  createSelector(
    selectGlobalDomain,
    (subState) => subState.isLoadingDeliveryLocation,
  );

export const makeSelectHasPartialLoader = () =>
  createSelector(selectGlobalDomain, (subState) => subState.hasPartialLoader);

export const makeSelectCurrentLocation = () =>
  createSelector(selectGlobalDomain, (substate) => substate.currentLocation);

export const makeSelectAccessToken = () =>
  createSelector(selectGlobalDomain, (subState) => subState.accessToken);

export const makeSelectSideBar = () =>
  createSelector(selectGlobalDomain, (subState) => subState.sideBar);

export const makeSelectSubMenuTop = () =>
  createSelector(selectGlobalDomain, (subState) => subState.subMenuTop);

export const makeSelectCurrentUser = () =>
  createSelector(selectGlobalDomain, (subState) => subState.currentUser);

export const makeSelectListProvince = () =>
  createSelector(selectGlobalDomain, (subState) => subState.listProvince);
export const makeSelectListCity = () =>
  createSelector(selectGlobalDomain, (subState) => subState.listCity);
export const makeSelectListBarangay = () =>
  createSelector(selectGlobalDomain, (subState) => subState.listBarangay);
export const makeSelectFooter = () =>
  createSelector(selectGlobalDomain, (subState) => subState.footer);
export const makeSelectIsShowSideBar = () =>
  createSelector(selectGlobalDomain, (substate) => substate.isShowSideBar);

export const makeSelectSearchMode = () =>
  createSelector(selectGlobalDomain, (substate) => substate.isSearchMode);

export const makeSelectPersistorLoaded = () =>
  createSelector(selectGlobalDomain, (substate) => substate.persistorLoaded);

export const makeSelectCountryName = () =>
  createSelector(selectGlobalDomain, (subState) => subState.countryName);

export const makeSelectRouter = () =>
  createSelector(
    (state) => state.router,
    (subState) => subState,
  );

export const makeSelectCmsPage = () =>
  createSelector(selectGlobalDomain, (subState) => subState.cms);

export const maleSelectIsCmsLoaded = () =>
  createSelector(selectGlobalDomain, (subState) => subState.isCmsLoaded);

export const maleSelectIsSideBarLoaded = () =>
  createSelector(selectGlobalDomain, (subState) => subState.isSideBarLoaded);

export const makeSelectIsLoadingFooter = () =>
  createSelector(selectGlobalDomain, (subState) => subState.isLoadingFooter);

export const makeSelectGlobalConfig = () =>
  createSelector(selectGlobalDomain, (subState) => subState.globalConfig);

export const makeSelectStoreAddresses = () =>
  createSelector(selectGlobalDomain, (subState) => subState.storeAddresses);

export const makeSelectSpecialCategoryID = () =>
  createSelector(selectGlobalDomain, (subState) => subState.specialCategoryID);

export const makeSelectIsLoadingUserInfo = () =>
  createSelector(selectGlobalDomain, (subState) => subState.isLoadingUserInfo);

export const makeSelectOutOfStockConfig = () =>
  createSelector(selectGlobalDomain, (subState) => subState.outOfStockConfig);

export const makeSelectFooterConfig = () =>
  createSelector(selectGlobalDomain, (subState) => subState.footerConfig);

export const makeSelectPreferredBranches = () =>
  createSelector(selectGlobalDomain, (subState) => subState.preferredBranches);

export const makeSelectDeliverySlotVerbiage = () =>
  createSelector(
    selectGlobalDomain,
    (subState) => subState.deliverySlotVerbiage,
  );

export const makeSelectSearchAndAutoSuggest = () =>
  createSelector(
    selectGlobalDomain,
    (substate) => substate.searchAndAutoSuggest,
  );

export const makeSelectStoreViewSources = () =>
  createSelector(selectGlobalDomain, (substate) => substate.storeViewSources);

export const makeSelectIsShowChatNudge = () =>
  createSelector(selectGlobalDomain, (substate) => substate.isShowChatNudge);

export const makeSelectYellowAiPayload = () =>
  createSelector(selectGlobalDomain, (substate) => substate.yellowAiPayload);

export const makeSelectStoreCodeView = () =>
  createSelector(selectGlobalDomain, (substate) => substate.storeCodeView);

import { call, put, takeLatest } from 'redux-saga/effects'
import * as globalActions from 'containers/Landers/actions'
import * as actions from './actions'
import * as constant from './constants'

function* onLoadDeliveryAreas(action) {
  const requestURL = 'https://static.snapmart.ph/areas.json'
  try {
    yield put(globalActions.showLoader())
    const res = yield call(fetch, requestURL, {
      method: 'GET',
    })
    const data = yield res.json()
    yield put(actions.loadDeliveryAreasSuccess(data))
  } catch (err) {
    yield put(actions.loadDeliveryAreasFailed(err))
  } finally {
    yield put(globalActions.hideLoader())
  }
}

export default function* expSaga() {
  yield takeLatest(constant.GET_ALL_DELIVERY_AREAS, onLoadDeliveryAreas)
}

import get from 'lodash/get'

export const calculateTotalSavingByItems = (cartItems) => {
  if (!Array.isArray(cartItems)) {
    return 0
  }
  return cartItems.reduce((prev, cur) => {
    return (
      prev +
      (get(cur, 'extension_attributes.product_data.price') - cur.price) *
        cur.qty
    )
  }, 0)
}

export const getValueByCode = (segments, code) => {
  if (!Array.isArray(segments)) {
    return 0
  }
  const segment = segments.find((item) => item.code === code)
  return segment ? segment.value : 0
}

export const getDefaultShippingFeeLimit = (shippingFees) => {
  if (!Array.isArray(shippingFees)) {
    return 0
  }
  const fee = shippingFees.find((item) => item.total === 0)
  return fee ? fee.shipping_fee : 0
}

export const checkCartHasOnlyMC = (items) =>
  items.every(({ product_type }) => product_type === 'customer_membership')

// @TODO delete this function
export const calculateTotalSavings = (
  cartData,
  cartTotals,
  progressBarData,
) => {
  //Discount from Items
  const cartItems = cartData?.items || []
  const totalSavedByItems = calculateTotalSavingByItems(cartItems)

  //Shopper Fee
  const baseShopperFee = cartTotals?.base_fee || 0
  const shopperFee = cartTotals?.fee || 0
  const shopperFeeDiscount =
    baseShopperFee > 0 ? baseShopperFee - shopperFee : 0

  //Landers Delivery Fee
  const baseShippingFee = getDefaultDeliveryDiscount(
    get(progressBarData, 'shipping_fee_limit', []),
  )
  const shippingFee = cartTotals?.shipping_amount || 0
  const shippingDiscount =
    baseShippingFee > 0 ? baseShippingFee - shippingFee : 0

  //Solane and GTC Aldis Delivery Fee
  const baseOtherDeliveryFee = cartTotals?.base_other_delivery_fee || 0
  const otherDeliveryFee = cartTotals?.other_delivery_fee || 0
  const otherDeliveryDiscount =
    baseOtherDeliveryFee > 0 ? baseOtherDeliveryFee - otherDeliveryFee : 0

  //Auto apply discount from sales rule
  const orderDiscountAmount = cartTotals?.discount_amount || 0

  return (
    totalSavedByItems +
    orderDiscountAmount +
    shopperFeeDiscount +
    shippingDiscount +
    otherDeliveryDiscount
  )
}

export const getDefaultDeliveryDiscount = (shippingFees = []) => {
  if (!Array.isArray(shippingFees) || shippingFees.length === 0) {
    return null
  }
  const item = shippingFees.find((item) => item.total === 0)
  return item.shipping_fee
}

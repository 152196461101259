
export const GET_ALL_EXP_EVENT = 'landers/the-landers-exp/GET_ALL_EXP_EVENT'
export const EXP_LOAD_EVENTS_SUCCESS = 'landers/the-landers-exp/EXP_LOAD_EVENTS_SUCCESS'
export const EXP_LOAD_EVENTS_FAILED = 'landers/the-landers-exp/EXP_LOAD_EVENTS_FAILED'
export const EXP_LOADING_START = 'landers/the-landers-exp/EXP_LOADING_START'
export const EXP_LOADING_FINISH = 'landers/the-landers-exp/EXP_LOADING_FINISH'
export const EXP_LOAD_MORE = 'landers/the-landers-exp/EXP_LOAD_MORE'

export const GET_BANNER_EXP = 'landers/the-landers-exp/GET_BANNER_EXP'
export const EXP_LOAD_BANNER_SUCCESS = 'landers/the-landers-exp/EXP_LOAD_BANNER_SUCCESS'
export const EXP_LOAD_BANNER_FAILED = 'landers/the-landers-exp/EXP_LOAD_BANNER_FAILED'
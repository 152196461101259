import { get, capitalize, isEmpty, sortBy } from 'lodash'
import { getStoreCode } from 'utils/request'
import config from 'global-config'
import dayjs from 'dayjs'

import {
  isDuringCampaign,
  sortSchedules,
  formatMoneyEscapeOnlyNull,
  formatDateToTimeZone,
} from 'utils/helper'

const unbxdOptions = {
  fields: [
    'isBulky',
    'sku',
    'status',
    'dimension',
    'brand',
    'newsToDate',
    'newsFromDate',
    'color',
    'visibility',
    'title',
    'entityId',
    'typeId',
    'metaTitle',
    'smallImage',
    'imageUrl',
    'productUrl',
    'specialPrice',
    'specialToDate',
    'specialFromDate',
    'reviewCount',
    'finalRating',
    'ratingSum',
    'packageText',
    'promoTag',
    'websiteId',
    '*sourceQty',
    'skuframe*',
    'originalPrice',
    'sneakpeek*All*',
    'b2p1',
    'b2p1Endtime',
    'b2p1Starttime',
  ],
  sort: {
    entity_id: 'entityId',
    lander_store_quantity: 'score',
    price: 'originalPrice',
    price_desc: 'originalPrice',
    name: 'title',
    name_desc: 'title',
    newness: 'newsFromDate',
    'newness+': 'newsFromDate',
    'newness-': 'newsFromDate',
    'name+': 'title',
    'name-': 'title',
  },
  pageSize: 24,
}

export const isUnbxdSearchEnabled = () => {
  return config.unbxd.searchEnabled
}

export const isUnbxdBrowseEnabled = () => {
  return config.unbxd.browseEnabled
}

export const isUnbxdAutoSuggestEnabled = () => {
  return config.unbxd.autosuggestEnabled
}

const getHostNameAndPath = (module) => {
  return `${config.unbxd.searchHost}${config.unbxd.apiKey}/${config.unbxd.siteKey}/${module}`
}

const getSortParam = (searchCriteria) => {
  if (searchCriteria.productListOrder) {
    const selectedSortOption = get(
      unbxdOptions,
      ['sort', searchCriteria.productListOrder],
      searchCriteria.productListOrder,
    )
    const selectedSortDir = searchCriteria.productListDir || 'DESC'
    return `&sort=${selectedSortOption} ${selectedSortDir}`
  }
  return ''
}

const getVersionParams = () => {
  return '&version=V2&viewType=GRID&facet.version=V2&facet.multiselect=true&variants=true&variants.count=1'
}

const getPageSize = () => {
  return `&rows=${unbxdOptions.pageSize}`
}

const getPageStart = (searchCriteria) => {
  if (searchCriteria.currentPage > 1) {
    return `&start=${(searchCriteria.currentPage - 1) * unbxdOptions.pageSize}`
  }
  return ''
}

const getFilterByStore = () => {
  if (getStoreCode() != null) {
    return `&filter=${getStoreCode()}-sourceQty:[1 TO 999999]`
  }
  return ''
}

const getSalePriceFilter = () => {
  return `&filter=specialPrice:*&filter=(-specialToDate:*) OR specialToDate:[NOW TO *]`
}

const getNewPriceFilter = () => {
  return `&filter=newsToDate:[NOW TO *]`
}

const getLevel1FilterCriteriaParams = (searchCriteria) => {
  const { ldDisplay } = searchCriteria
  if (!ldDisplay) {
    return ''
  }
  switch (ldDisplay) {
    case 'instock':
      return getFilterByStore()
    case 'onsale':
      return getSalePriceFilter()
    case 'new':
      return getNewPriceFilter()
    default:
      return ''
  }
}

const getFieldsParam = () => {
  return `&fields=${getFieldsInternalParam()}`
}

const getFieldsInternalParam = () => {
  let fields = `${unbxdOptions.fields.join(',')}`
  if (getStoreCode() != null) {
    fields =
      fields +
      ',saleprice*' +
      capitalize(getStoreCode()) +
      '*,sneakpeek*' +
      capitalize(getStoreCode()) +
      '*'
  }
  return fields
}

const transformProduct = (product) => {
  return {
    id: product.entityId,
    sku: product.sku,
    name: product.title,
    price: product.originalPrice ? product.originalPrice : 0,
    type_id: product.typeId,
    extension_attributes: {
      website_ids: [product.websiteId],
      is_new: isNewProduct(product),
      product_url: product.productUrl,
      price: product.originalPrice ? product.originalPrice : 0,
      final_price: getFinalPrice(
        product,
        product.originalPrice ? product.originalPrice : 0,
      ),
      rating_count: product.reviewCount,
      rating_summary: product.finalRating ? product.finalRating * 20 : 0,
      brand: product.brand,
      campaign_data: getCampaigns(product),
      sneak_peek_data: getSneakPeek(product),
      promo_tag: getPromoTag(product),
      stock_source_data: getStockData(product),
      frames: getSkuFrames(product),
      is_bulky: get(product, 'isBulky', 0) === 1,
    },
    options: [],
    media_gallery_entries: [],
    custom_attributes: getCustomAttributesProduct(product),
  }
}

const isUnbxdValidTime = (item) => {
  if (!item) {
    return false
  }
  const now = dayjs.tz()
  const format = 'YYYY-MM-DD HH:mm:ss'
  const startTimePH = formatDateToTimeZone(item.start_time, format)
  const endTimePH = formatDateToTimeZone(item.end_time, format)
  return (
    item.start_time &&
    item.end_time &&
    startTimePH.isValid() &&
    endTimePH.isValid() &&
    startTimePH.isBefore(endTimePH) &&
    now.isSameOrBefore(endTimePH) &&
    now.isSameOrAfter(startTimePH)
  )
}

const getPromoTag = (product) => {
  if (
    product.b2p1 &&
    product.b2p1 === 'true' &&
    product.b2p1Endtime &&
    isUnbxdValidTime({
      start_time: product.b2p1Starttime
        ? product.b2p1Starttime
        : dayjs().format(),
      end_time: product.b2p1Endtime, //'2023-03-03T00:00:00+08:00'
    })
  ) {
    return ['Buy 2 for the price of 1']
  }
  return []
}

const getStockData = (product) => {
  let sku = product.sku
  let stocks = []
  Object.keys(product)
    .filter((key) => key.endsWith('sourceQty'))
    .forEach((key) => {
      let value = product[key]
      let storeCode = key.split('-')
      if (value) {
        stocks.push({
          sku: sku,
          source_code: storeCode[0],
          quantity: value,
          status: value && value > 0,
        })
      }
    })
  return stocks
}

const isNewProduct = (product) => {
  if (product.newsToDate && product.newsFromDate) {
    return isUnbxdValidTime({
      start_time: product.newsFromDate,
      end_time: product.newsToDate,
    })
  }
  return false
}

const findSkuFrameObject = (skuFrames, key, attributeName) => {
  let skuFrameId = key.split(attributeName)[1]
  let skuFrameObject
  if (skuFrameId in skuFrames) {
    skuFrameObject = skuFrames[skuFrameId]
  } else {
    skuFrameObject = {}
    skuFrameObject.id = skuFrameId
  }
  return skuFrameObject
}

const getSneakPeek = (product) => {
  let skuFrames = {}
  try {
    Object.keys(product)
      .filter((key) => key.startsWith('sneakpeek'))
      .forEach((key) => {
        let value = product[key]
        if (value) {
          let skuFrameObject
          if (key.indexOf('Price') > -1) {
            skuFrameObject = findSkuFrameObject(skuFrames, key, 'Price')
            skuFrameObject.price = value
          } else if (key.indexOf('StartDate') > -1) {
            skuFrameObject = findSkuFrameObject(skuFrames, key, 'StartDate')
            skuFrameObject.start_date = value
          } else if (key.indexOf('EndDate') > -1) {
            skuFrameObject = findSkuFrameObject(skuFrames, key, 'EndDate')
            skuFrameObject.end_date = value
          } else if (key.indexOf('Status') > -1) {
            skuFrameObject = findSkuFrameObject(skuFrames, key, 'Status')
            skuFrameObject.status = value
          }
          if (skuFrameObject) {
            if (key.indexOf('All') > -1) {
              skuFrameObject['store'] = 'all'
            } else if (key.indexOf('Default') > -1) {
              skuFrameObject['store'] = 'default'
            } else {
              skuFrameObject['store'] = getStoreCode()
            }
            skuFrames[skuFrameObject.id] = skuFrameObject
          }
        }
      })
    if (Object.values(skuFrames).length > 0) {
      return sortSchedules(Object.values(skuFrames), 'start_date')
    }
  } catch (e) {
    console.error(
      'Error while computing sneakpeak for product - ' + product.entityId,
      e,
    )
  }
  return []
}

const getSkuFrames = (product) => {
  let skuFrames = {}
  try {
    Object.keys(product)
      .filter((key) => key.startsWith('skuframe'))
      .forEach((key) => {
        let value = product[key]
        if (value) {
          let skuFrameObject
          if (key.indexOf('Image') > -1) {
            skuFrameObject = findSkuFrameObject(skuFrames, key, 'Image')
            skuFrameObject.image = value
          } else if (key.indexOf('Starttime') > -1) {
            skuFrameObject = findSkuFrameObject(skuFrames, key, 'Starttime')
            skuFrameObject.start_time = value
          } else if (key.indexOf('Endtime') > -1) {
            skuFrameObject = findSkuFrameObject(skuFrames, key, 'Endtime')
            skuFrameObject.end_time = value
          }
          if (skuFrameObject) {
            skuFrames[skuFrameObject.id] = skuFrameObject
          }
        }
      })
    if (Object.values(skuFrames).length > 0) {
      return Object.values(skuFrames)
    }
  } catch (e) {
    console.error(
      'Error while computing skuframes for product - ' + product.entityId,
      e,
    )
  }
  return []
}

const getFinalPrice = (product, price) => {
  let selectedCampgain = selectCampaigns(product)
  if (selectedCampgain && selectedCampgain.price < price) {
    return selectedCampgain.price
  }
  if (
    product.specialPrice &&
    product.specialPrice < price &&
    (!product.specialToDate ||
      isUnbxdValidTime({
        start_time: product.specialFromDate
          ? product.specialFromDate
          : dayjs().format(),
        end_time: product.specialToDate,
      }))
  ) {
    return product.specialPrice
  }
  return price
}

const selectCampaigns = (product) => {
  let sortedSchedules = getCampaigns(product)
  if (sortedSchedules && sortedSchedules.length > 0) {
    return sortedSchedules[0]
  }
  return null
}

const getCampaigns = (product) => {
  let campagins = {}
  if (getStoreCode() != null) {
    try {
      let storeCode = capitalize(getStoreCode())
      Object.keys(product)
        .filter((key) => key.startsWith('saleprice'))
        .forEach((key) => {
          let value = product[key]
          // eslint-disable-next-line
          if (typeof value !== undefined) {
            let flatProductId = key.split(storeCode)[1]
            let flatProductObject
            if (flatProductId in campagins) {
              flatProductObject = campagins[flatProductId]
            } else {
              flatProductObject = {}
              flatProductObject.id = flatProductId
            }
            if (key.indexOf('salepricePrice') > -1) {
              flatProductObject.price = value
              flatProductObject.source_code = getStoreCode()
            } else if (key.indexOf('Starttime') > -1) {
              flatProductObject.start_time = value
            } else if (key.indexOf('Endtime') > -1) {
              flatProductObject.end_time = value
            } else if (key.indexOf('Stock') > -1) {
              flatProductObject.stock = value
            }
            campagins[flatProductId] = flatProductObject
          }
        })

      return sortSchedules(
        Object.values(campagins).filter((campagin) => {
          return isDuringCampaign(campagin)
        }),
        'start_time',
      ).map((campagin) => {
        campagin.campaign_time = [{ ...campagin }]
        return campagin
      })
    } catch (e) {
      console.error(
        'Error while computing schedules for product - ' + product.entityId,
        e,
      )
    }
  }
  return null
}

const modifyValues = function (values = []) {
  let valuesList = []
  values.forEach((value, index) => {
    const data = value
    if (value.name) {
      valuesList.push({
        ...data,
      })
    } else {
      let prevData = ''
      if (index % 2 === 1) {
        prevData = values[index - 1]
        valuesList.push({
          name: prevData,
          display: prevData,
          count: data,
        })
      }
    }
  })
  return valuesList
}

const isLastLevel = function (breadcrumb, values) {
  let lastLevelCategory = getLastCategoryName(breadcrumb)
  return (
    values.filter((value) => {
      return value.name === lastLevelCategory
    }).length > 0
  )
}

const convertMultiValue = function (values = [], breadcrumb = null) {
  let valuesList = []
  let isLastLevelReached = isLastLevel(breadcrumb, values)
  values.forEach((value, index) => {
    const data = value

    if (value.name) {
      valuesList.push({
        display: value.name,
        isLastLevelReached: isLastLevelReached,
        breadcrumb: breadcrumb,
        ...data,
      })
    }
  })
  return valuesList
}

const getLastCategoryName = (categoryBreadCrumb) => {
  if (categoryBreadCrumb.child) {
    return getLastCategoryName(categoryBreadCrumb.child)
  } else {
    return categoryBreadCrumb.values
      ? categoryBreadCrumb.values[0].name
      : 'not available'
  }
}
const convertTextFacet = function (facets) {
  let facetList = []
  let modifiedFacets = facets || []
  modifiedFacets.forEach((facet, index) => {
    const { values, displayName, filterField, position } = facet

    facet.name = filterField
    facet.display = displayName
    facet.type = 'text'
    facet.position = position || -1
    facet.value = modifyValues(values)
    facetList.push(facet)
  })
  return facetList
}

const convertMultiLevelFacet = function (facets) {
  let facetList = []
  let modifiedFacets = facets || []
  modifiedFacets.forEach((facet, index) => {
    const { values, displayName, filterField, position, breadcrumb } = facet

    facet.name = filterField
    facet.display = displayName
    facet.position = position || -1
    facet.type = 'multilevel'
    facet.value = convertMultiValue(values, breadcrumb)
    facetList.push(facet)
  })
  return facetList
}
const convertRangeFacet = (facets) => {
  const facetList = []
  const modifiedFacets = facets || []
  modifiedFacets.forEach((facet) => {
    const {
      values: { counts, gap },
    } = facet
    const values = []
    let valuesHolder = []
    for (let i = 0; i < counts.length; i = i + 2) {
      const valLabel = Number(counts[i])
      // facet value object
      const valObject = {
        name: valLabel,
        count: counts[i + 1],
        dataId: valLabel,
      }

      // push facet object into a temporary holder
      valuesHolder.push(valObject)
      if (valuesHolder.length >= 2) {
        // get from and end values from the holder
        const [from, end] = valuesHolder
        values.push({
          from,
          end,
          display: `${formatMoneyEscapeOnlyNull(
            from.name,
          )} - ${formatMoneyEscapeOnlyNull(end.name)}`,
          count: from.count,
        })
        // remove the first value from the holder
        valuesHolder.shift()
        // use the first value as from value in the next iteration
      }
    }

    if (valuesHolder.length === 1) {
      const [from] = valuesHolder
      // formulate an end value using gap
      const valLabel = from.dataId + gap
      const end = {
        name: valLabel,
        count: 0,
        dataId: valLabel,
      }
      values.push({
        from,
        end,
        display: `${formatMoneyEscapeOnlyNull(
          from.name,
        )} - ${formatMoneyEscapeOnlyNull(end.name)}`,
        count: from.count,
      })
    }

    // pick up start and end values from the parsed array.
    const startVal = values[0]['from']['dataId']
    const endVal = values[values.length - 1]['end']['dataId']
    facet.start = startVal
    facet.end = endVal
    facet.max = endVal
    facet.gap = gap || 1
    facet.min = startVal
    facet.value = values
    facet.name = facet.facetName
    facet.display = facet.displayName
    facet.type = 'range'
    facetList.push({ ...facet, values })
  })
  return facetList
}

const getCustomAttributesProduct = (product) => {
  let customAttributes = []
  if (product.smallImage || product.imageUrl) {
    customAttributes.push({
      attribute_code: 'small_image',
      value:
        product.smallImage || (product.imageUrl ? product.imageUrl[0] : null),
    })
  }

  if (product.dimension) {
    customAttributes.push({
      attribute_code: 'dimension',
      value: product.dimension,
    })
  }

  return customAttributes
}

const trackUnbxdEvent = (name, payload) => {
  if ('Unbxd' in window && 'track' in window.Unbxd) {
    window.Unbxd.track(name, payload)
  } else {
    console.log(
      'Missing Unbxd.track function hence event ' + name + ' not fired',
    )
  }
}
function hex(c) {
  const v = '0' + c.charCodeAt(0).toString(16)
  return '\\x' + v.substr(v.length - 2)
}
const encodeFacetValue = function (value) {
  value = value
    .replace(/\\/g, '\\\\')
    .replace(/\n/g, '\\n')
    .replace(/\t/g, '\\t')
    .replace(/\v/g, '\\v')
    .replace(/'/g, "\\'")
    .replace(/"/g, '\\"')
    // eslint-disable-next-line
    .replace(/[\x00-\x1F\x80-\x9F]/g, hex)
  return encodeURIComponent(value)
}

const getCategoryPathFromCrumb = (categoryBreadCrumb, isLastLevelReached) => {
  if (categoryBreadCrumb.values) {
    if (categoryBreadCrumb.child) {
      return (
        `${encodeFacetValue(categoryBreadCrumb.values[0].name)}>` +
        getCategoryPathFromCrumb(categoryBreadCrumb.child, isLastLevelReached)
      )
    } else {
      return isLastLevelReached
        ? ''
        : encodeFacetValue(categoryBreadCrumb.values[0].name)
    }
  }
  return ''
}

const getAppliedFilterParams = (filter, appliedBrand) => {
  let facetUrl = ''
  if (!filter) return
  // eslint-disable-next-line
  if (appliedBrand && (isEmpty(filter) || (!'brand_uFilter') in filter)) {
    filter.brand_uFilter = [
      { name: appliedBrand, display: appliedBrand, facetName: 'Brands' },
    ]
  }
  if (!isEmpty(filter)) {
    const keys = Object.keys(filter)
    const doubleStr = '%22'
    keys.forEach((key, indexKey) => {
      const facet = filter[key]
      if (key.startsWith('categoryPath')) {
        const facetValue = facet[facet.length - 1]
        let categoryPath = getCategoryPathFromCrumb(
          facetValue.breadcrumb,
          facetValue.isLastLevelReached,
        )
        // multi level facets
        facetUrl += `&category-filter=`

        if (categoryPath) {
          facetUrl += categoryPath
          if (!categoryPath.endsWith('>')) {
            facetUrl += '>'
          }
        }
        facetUrl += `${encodeFacetValue(facetValue.display)}`
      } else {
        /** Remove unnecessary &filter from url */
        if (facet.length) {
          facetUrl += `&filter=`
        }
        // facetUrl += indexKey < (keys.length - 1) ? `&filter=` : ""
        facet.forEach((item, index) => {
          let { display } = item
          let value
          if (item.from && item.end) {
            value = `[${item.from.dataId} TO ${item.end.dataId}]`
          } else {
            value = `${doubleStr}${encodeFacetValue(display)}${doubleStr}`
          }
          if (index === 0) {
            facetUrl += `${key}:${value}`
          } else {
            facetUrl += ` OR ${key}:${value}`
          }
        })
      }
    })
  }
  return facetUrl
}

export const recordImpressionEvent = async (url, result) => {
  try {
    let response = result.data.response
    if (response.products.length > 0) {
      let pids = response.products.map((product) => {
        return product.entityId
      })
      let impressionObj = {
        query: result.data.searchMetaData.queryParams.q,
        pids_list: pids,
        requestId: window.unbxd_request_id,
      }
      trackUnbxdEvent('search_impression', impressionObj)
    }
  } catch (e) {
    console.log('Error firing impression event :: ' + url, e)
  }
}

export const trackAddCart = (products) => {
  if (products.items && products.items.length > 0) {
    trackUnbxdEvent('addToCart', {
      pid: String(products.items[0].id),
      qty: String(1),
      requestId: window.unbxd_request_id,
    })
  }
}

export const trackRemoveCart = (products) => {
  if (products.items && products.items.length > 0) {
    trackUnbxdEvent('cartRemoval', {
      pid: String(products.items[0].id),
      qty: String(1),
      requestId: window.unbxd_request_id,
    })
  }
}

export const trackSearchEvent = (query) => {
  if (query) {
    trackUnbxdEvent('search', { query: query })
  }
}

export const trackBrowseEvent = (state, action) => {
  try {
    let categoryPath = getCategoryPath(state, action)
    if (categoryPath) {
      trackUnbxdEvent('categoryPage', {
        page: categoryPath,
        page_type: 'BOOLEAN',
      })
    }
  } catch (e) {
    console.log('Error firing browse event :: ', e)
  }
}

export const getSearchCategoryFilter = (searchCriteria) => {
  if (searchCriteria.categoryId && searchCriteria.categoryId > 0) {
    return `&filter=u_categoryPathId:${searchCriteria.categoryId}`
  }
  return ``
}

export const getSearchUrl = (searchCriteria, filter, appliedBrand) => {
  return `${getHostNameAndPath('search')}?q=${
    searchCriteria.query
  }${getSortParam(
    searchCriteria,
  )}${getFieldsParam()}${getPageSize()}${getPageStart(
    searchCriteria,
  )}${getLevel1FilterCriteriaParams(
    searchCriteria,
  )}${getVersionParams()}${getAppliedFilterParams(
    filter,
    appliedBrand,
  )}${getSearchCategoryFilter(searchCriteria)}`
}

export const getAutosuggestCategoryFilter = (categoryId) => {
  if (categoryId && categoryId !== '0') {
    return `&popularProducts.filter=u_categoryPathId:${categoryId}`
  }
  return ``
}

const getAutosuggestFilterByStore = () => {
  if (getStoreCode() != null) {
    return `&popularProducts.filter=${getStoreCode()}-sourceQty:[1 TO 999999]`
  }
  return ''
}

export const getAutosuggestUrl = (query, categoryId) => {
  return `${getHostNameAndPath(
    'autosuggest',
  )}?q=${query}&inFields.count=3&topQueries.count=2&keywordSuggestions.count=6&popularProducts.count=6&promotedSuggestion.count=2&indent=off&popularProducts.fields=${getFieldsInternalParam()},docType,autosuggest${getAutosuggestCategoryFilter(
    categoryId,
  )}${getAutosuggestFilterByStore()}`
}

const getCategoryPath = (state, action) => {
  if (state && state.slugData && state.slugData.breadcrumb) {
    return (
      `p-id=categoryPathId:"` +
      state.slugData.breadcrumb
        .filter((value, index) => {
          return index > 0 && value.id
        })
        .map((value) => {
          return value.id
        })
        .join('>') +
      '"&pagetype=boolean&'
    )
  } else {
    return `p-id=categoryPathId:"` + action.payload + '"&pagetype=boolean&'
  }
}

const extractSortOption = (state) => {
  if (state.secondaryFilter && state.secondaryFilter.product_list_order) {
    return {
      productListDir: state.secondaryFilter.product_list_dir,
      productListOrder: state.secondaryFilter.product_list_order,
    }
  } else {
    return {}
  }
}

const extractLevel1Filter = (state) => {
  if (state.secondaryFilter && state.secondaryFilter.ld_display) {
    return { ldDisplay: state.secondaryFilter.ld_display }
  } else {
    return {}
  }
}

export const getBrowseUrl = (state, action, currentPage) => {
  return `${getHostNameAndPath('category')}?${getCategoryPath(
    state,
    action,
  )}&${getSortParam(
    extractSortOption(state),
  )}${getFieldsParam()}${getPageSize()}${getPageStart({
    currentPage: currentPage,
  })}${getLevel1FilterCriteriaParams(
    extractLevel1Filter(state),
  )}${getVersionParams()}${getAppliedFilterParams(state.filter)}`
}

export const getBrowseUrlForSubCategories = (action) => {
  return `${getHostNameAndPath('category')}?${getCategoryPath(
    null,
    action,
  )}&${getFieldsParam()}${getVersionParams()}${getLevel1FilterCriteriaParams({
    ldDisplay: 'instock',
  })}&rows=6&facet=false`
}

export const convertResponseObject = (result) => {
  try {
    let unbxdRequestId = get(result, 'headers.unbxd-request-id')
    if (unbxdRequestId) {
      window.unbxd_request_id = unbxdRequestId
    }
  } catch (e) {
    console.error('Error while setting unbxd request id', e)
  }
  if (
    result.data.response &&
    result.data.response.products &&
    result.data.response.products.length > 0
  ) {
    return {
      data: result.data.response.products.map((product) => {
        return transformProduct(product)
      }),
    }
  }
  return { data: [] }
}

export const highlight2 = (term, query) => {
  if (term && term.length > 0 && query && query.length > 3) {
    return term.replace(
      query,
      `<span class="unbxd-highlighted">${query}</span>`,
    )
  }
  return term
}

const escapeStr = (str) => {
  // eslint-disable-next-line
  return str.replace(/([\\{}()|.?*+\-\^$\[\]])/g, '\\$1')
}

export const highlight = (str, q) => {
  var strArr = str.toLowerCase().split(' ')
  for (var i = 0; i < strArr.length; i++)
    strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substring(1)
  var output = strArr.join(' ')

  if (q.indexOf(' ')) {
    var arr = q.split(' ')
    for (var k in arr) {
      if (!arr.hasOwnProperty(k)) continue

      var l = output.toLowerCase().lastIndexOf('</strong>')
      if (l !== -1) l += 9
      output =
        output.substring(0, l) +
        output
          .substring(l)
          .replace(new RegExp(escapeStr(arr[k]), 'gi'), function ($1) {
            return '<strong>' + $1 + '</strong>'
          })
    }
  } else {
    var st = output.toLowerCase().indexOf(q)
    output =
      st >= 0
        ? output.substring(0, st) +
          '<strong>' +
          output.substring(st, st + q.length) +
          '</strong>' +
          output.substring(st + q.length)
        : output
  }

  return output
}

export const convertAutoSuggestResponseObject = (result) => {
  let response = {
    items: [],
    terms: [],
    brands: [],
    category: [],
  }

  result.response.products.forEach((doc) => {
    if ('doctype' in doc) {
      if (doc.doctype !== 'IN_FIELD') {
        response.terms.push({
          keyword: doc.autosuggest,
          highlighter: highlight(
            doc.autosuggest,
            result.searchMetaData.queryParams.q,
          ),
        })
      } else {
        if (doc.brand_in) {
          let count = 0
          doc.brand_in.forEach((infield) => {
            count++
            if (count > 3) {
              return false
            }
            response.brands.push({
              keyword: doc.autosuggest,
              highlighter: highlight(
                doc.autosuggest,
                result.searchMetaData.queryParams.q,
              ),
              brand: infield,
            })
          })
        }
      }
    } else {
      response.items.push(transformProduct(doc))
    }
  })
  console.log(response)
  return response
}

export const convertFacetObject = (result) => {
  let facetList = []
  let facets = result.data.facets
  if (facets && !isEmpty(facets.text)) {
    facetList.push(...convertTextFacet(facets.text.list))
  }
  if (facets && !isEmpty(facets.multilevel)) {
    facetList.push(...convertMultiLevelFacet(facets.multilevel.list))
  }
  if (facets && !isEmpty(facets.range)) {
    facetList.push(...convertRangeFacet(facets.range.list))
  }
  return {
    unbxd_filter: sortBy(facetList, 'position'),
  }
}

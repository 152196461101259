import { createSelector } from 'reselect'
import { initialState } from './reducer'

/**
 * Direct selector to the authorize state domain
 */

const selectAuthenDomain = (state) => state.rnauthen || initialState

export const makeSelectInValidSignUp = () =>
  createSelector(selectAuthenDomain, (substate) => substate.inValidSignUp)

export const makeSelectInValidLogin = () =>
  createSelector(selectAuthenDomain, (substate) => substate.inValidLogin)

export const makeSelectResetPasswordData = () =>
  createSelector(selectAuthenDomain, (substate) => substate.resetPasswordData)

export const makeSelectInValidReset = () =>
  createSelector(selectAuthenDomain, (substate) => substate.inValidReset)

export const makeSelectCheckMembershipObj = () =>
  createSelector(selectAuthenDomain, (substate) => substate.checkMembership)

export const makeSelectLambdaToken = () =>
  createSelector(selectAuthenDomain, (substate) => substate.lambdaAccessToken)

export const makeSelectLoginMethod = () =>
  createSelector(selectAuthenDomain, (substate) => substate.loginMethod)

export const isSpecialInstruction = (product) => {
  if (!product) return false

  const isSpecialInstruction = product?.options?.findIndex(
    (option) =>
      option && option.special_instructions && option.special_instructions === 1
  )

  if (isSpecialInstruction === undefined) {
    return false
  }

  return isSpecialInstruction !== -1
}

import { find, size } from 'lodash'
import { DEFAULT_STORE_VIEW } from 'containers/Landers/constants'

/**
 *
 * @param {string} storeCode
 * @param {Array<{store_source_code: String, magento_store_code: String}>} storeSouceMapping
 * @returns {string}
 */
export function getStoreViewCode(storeCode = '', storeSouceMapping) {
  if (size(storeSouceMapping) === 0 || storeCode === '') {
    return DEFAULT_STORE_VIEW
  }

  return (
    find(storeSouceMapping, { store_source_code: storeCode })
      ?.magento_store_code || DEFAULT_STORE_VIEW
  )
}

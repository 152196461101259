import { store } from 'configureStore';

let nodeToken = '';
let expiresIn = '';
let userEmail = '';

// @deprecated found a way to sync node token
export function setNodeTokenApi(token) {
  nodeToken = token;
}

// @deprecated found a way to sync node token
export function getNodeTokenApi() {
  return nodeToken;
}

// @deprecated found a way to sync node token
export function setExpiryApi(expires_in) {
  expiresIn = expires_in;
}

// @deprecated found a way to sync node token
export function getExpiryApi() {
  return expiresIn;
}

// @deprecated found a way to sync node token
export function setUserEmailApi(email) {
  userEmail = email;
}

// @deprecated found a way to sync node token
export function getUserEmailApi() {
  return userEmail;
}

export const isTokenExpired = () => {
  // Check if the expiration date is less than 1 hour from the current date
  let isLessThanOneHour = true;

  const _expiresIn = store.getState()?.rnauthen.lambdaAccessToken.expires_in;

  if (_expiresIn !== '') {
    const oneHourInMilliseconds = 60 * 60 * 1000;
    const currentDate = new Date();
    let expirationDate = new Date(_expiresIn * 1000);
    isLessThanOneHour =
      expirationDate - currentDate < oneHourInMilliseconds * 14; // check if expiration of token is less than 14 hours
  }

  return isLessThanOneHour;
};

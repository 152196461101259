import { createSelector } from 'reselect'
import { initialState } from './reducer'

const selectHomeDomain = (state) => state.home || initialState

export const makeSelectHomeCards = () =>
  createSelector(selectHomeDomain, (subState) => subState.homeCards)

export const makeSelectEvents = () =>
  createSelector(selectHomeDomain, (subState) => subState.events)

export const makeSelectCategoriesCards = () =>
  createSelector(selectHomeDomain, (subState) => subState.categoriesCards)

export const makeSelectBannerSlider = () =>
  createSelector(selectHomeDomain, (subState) => subState.bannerSlider)

export const makeSelectHomeStoreCode = () =>
  createSelector(selectHomeDomain, (subState) => subState.homeStoreCode)

export const makeSelectPastPurchases = () =>
  createSelector(selectHomeDomain, (subState) => subState.pastPurchases)

export const makeSelectWidgets = () =>
  createSelector(selectHomeDomain, (subState) => subState.widgets)

export const makeSelectWidgetLoading = () =>
  createSelector(selectHomeDomain, (subState) => subState.widgetLoading)

export const makeSelectLoadingHomeCards = () =>
  createSelector(selectHomeDomain, (subState) => subState.loadingHomeCards)

export const makeSelectSplashAd = () =>
  createSelector(selectHomeDomain, (subState) => subState.splashAd)

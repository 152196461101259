import clevertap from '.';
import dayjs from 'dayjs';
import { get, some } from 'lodash';
import formatMobileNumber from 'utils/helpers/formatMobileNumber.ts';
import { cleanEmpty } from 'utils/helpers/removeEmptyValues';
const getDefaultBillingAddress = (addresses = []) => {
  const defaultBillingAddress = addresses.find(
    (item) => item?.default_billing === true,
  );
  return {
    Country: defaultBillingAddress
      ? get(defaultBillingAddress, 'country_id', '')
      : '',
    'Zip/Postal Code': defaultBillingAddress
      ? get(defaultBillingAddress, 'postcode', '')
      : '',
    'State/Province': defaultBillingAddress
      ? get(defaultBillingAddress, 'region.region', '')
      : '',
    'City/District': defaultBillingAddress
      ? get(defaultBillingAddress, 'city', '')
      : '',
    Barangay: defaultBillingAddress
      ? get(defaultBillingAddress, 'extension_attributes.township', '')
      : '',
    Street: defaultBillingAddress
      ? get(defaultBillingAddress, 'street[0]', '')
      : '',
  };
};
export const ctSetUserProfile = (profile = {}) => {
  const isProfileNotEmpty = some(profile);
  const membershipInfo =
    get(profile, 'extension_attributes.membership_info', {}) || {};
  const { type = '', code = '', expired_date = '' } = membershipInfo;
  const userProfile = {
    Site: {
      Identity: isProfileNotEmpty ? profile?.id : '',
      Name:
        isProfileNotEmpty && profile?.firstname && profile?.lastname
          ? `${profile?.firstname} ${profile?.lastname}`
          : '',
      Email: isProfileNotEmpty ? profile?.email : '',
      'First Name': isProfileNotEmpty ? profile?.firstname : '',
      'Middle Name': '',
      'Last Name': isProfileNotEmpty ? profile?.lastname : '',
      'Customer Group': isProfileNotEmpty ? '' : '',
      'Membership Type': isProfileNotEmpty ? type : '',
      'Expiration Date':
        isProfileNotEmpty && code
          ? dayjs(expired_date).format('MMM DD, YYYY')
          : '',
      'Membership Code': isProfileNotEmpty ? code : '',
      'Mobile Phone': isProfileNotEmpty
        ? profile?.extension_attributes?.phone
        : '',
      Phone: isProfileNotEmpty
        ? formatMobileNumber(profile?.extension_attributes?.phone).replace(
            /\s/g,
            '',
          )
        : '',
      'Email Address': isProfileNotEmpty ? profile?.email : '',
      'Date of Birth': isProfileNotEmpty
        ? dayjs(profile?.extension_attributes?.birthday).format('MMMM DD, YYYY')
        : '',

      'Auto Created':
        isProfileNotEmpty &&
        profile?.extension_attributes?.auto_created === true
          ? 'Yes'
          : 'No',
      ...getDefaultBillingAddress(isProfileNotEmpty ? profile?.addresses : []),
    },
  };
  clevertap.onUserLogin.push(cleanEmpty(userProfile));
};
export const ctUpdateUserProfile = (profile, id) => {
  const {
    customer_group,
    account_created,
    gender,
    marital_status,
    scpwd_tagged,
    occupation,
    preferred_branch,
    receive_promotions,
    lifetime_order_count,
    lifetime_gmv,
    first_order_date,
    recent_order_date,
    firstname,
    lastname,
    phone,
  } = profile;
  const SCPWD =
    scpwd_tagged !== null && scpwd_tagged !== false && scpwd_tagged !== 0;
  const membershipInfo =
    get(profile, 'extension_attributes.membership_info', {}) || {};
  const { type = '', code = '', expired_date = '' } = membershipInfo;
  const updateProfile = {
    Site: {
      Email: profile?.email,
      'First Name': profile?.firstname,
      'Last Name': profile?.lastname,
      'Membership Type': type,
      'Expiration Date': code ? dayjs(expired_date).format('MMM DD, YYYY') : '',
      'Auto Created':
        profile?.extension_attributes?.auto_created === true ? 'Yes' : 'No',
      'Membership Code': code,
      'Mobile Phone': profile?.extension_attributes?.phone,
      'Email Address': profile?.email,
      'Date of Birth': dayjs(profile?.extension_attributes?.birthday).format(
        'MMMM DD, YYYY',
      ),
      Identity: id,
      Name: firstname || lastname ? `${firstname} ${lastname}` : '',
      'Customer Group': customer_group,
      'Account Created': account_created
        ? dayjs(account_created).format('MMM DD, YYYY')
        : '',
      Phone: phone ? formatMobileNumber(phone).replace(/\s/g, '') : '',
      Gender: gender ? gender : '',
      'Marital Status': marital_status,
      'SCPWD Tagged': !!SCPWD ? 'Yes' : 'No',
      Occupation: occupation ? occupation : '',
      Occupation: occupation ? occupation?.toString() : '',
      'Preferred Branch': preferred_branch,
      'Receive Promotions': receive_promotions ? 'Yes' : 'No',
      'Lifetime order count': lifetime_order_count,
      'Lifetime GMV': lifetime_gmv,
      'First order date':
        first_order_date !== ''
          ? dayjs(first_order_date).format('MMMM DD, YYYY')
          : '',
      'Recent order date':
        recent_order_date !== ''
          ? dayjs(recent_order_date).format('MMMM DD, YYYY')
          : '',
      ...getDefaultBillingAddress(profile?.addresses),
    },
  };
  clevertap.profile.push(cleanEmpty(updateProfile));
};

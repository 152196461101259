import * as constant from './constants';

export function loadTrackAndTraceTemplate(payload) {
  return {
    type: constant.LOAD_TRACK_AND_TRACE,
    payload,
  };
}
export function saveTrackAndTraceTemplate(payload) {
  return {
    type: constant.SAVE_TRACK_AND_TRACE,
    payload,
  };
}

export function loadOrderTracker(payload) {
  return {
    type: constant.LOAD_ORDER_TRACER,
    payload,
  };
}
export function saveOrderTracker(payload) {
  return {
    type: constant.SAVE_ORDER_TRACER,
    payload,
  };
}

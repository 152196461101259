/*
 *
 * Shopping list constants
 *
 */

export const PAST_PURCHASE_ID = 'ID_PAST_PURCHASES_LIST';
export const allProductListId = 'ID_ALL_PRODUCTS_IN_MY_LISTS';
export const ESSENTIALS_LIST_ID = 'essentials';

export const CREATE_UPDATE_LIST_LOADING_START = 'landers/shoppinglist/CREATE_UPDATE_LIST_LOADING_START'
export const CREATE_UPDATE_LIST_LOADING_FINISH = 'landers/shoppinglist/CREATE_UPDATE_LIST_LOADING_FINISH'

export const GET_SHOPPING_LIST_START = 'landers/shoppinglist/GET_SHOPPING_LIST_START'
export const GET_SHOPPING_LIST_FINISH = 'landers/shoppinglist/GET_SHOPPING_LIST_FINISH'

export const OPEN_SHOPPING_LIST_MODAL = 'landers/shoppinglist/OPEN_SHOPPING_LIST_MODAL'
export const CLOSE_SHOPPING_LIST_MODAL = 'landers/shoppinglist/CLOSE_SHOPPING_LIST_MODAL'

export const SAVE_ALL_PRODUCT_IN_MY_LIST = 'landers/shoppinglist/SAVE_ALL_PRODUCT_IN_MY_LIST'

export const SAVE_PAST_PURCHASE = 'landers/shoppinglist/SAVE_PAST_PURCHASE'

export const GET_LIST_DETAIL_START = 'landers/shoppinglist/GET_LIST_DETAIL_START'
export const GET_LIST_DETAIL_FINISH = 'landers/shoppinglist/GET_LIST_DETAIL_FINISH'

export const SAVE_LIST_DETAIL = 'landers/shoppinglist/SAVE_LIST_DETAIL'
export const SAVE_SHOPPING_LIST_FILTER = 'landers/shoppinglist/SAVE_SHOPPING_LIST_FILTER'
export const CLEAR_SHOPPING_LIST_FILTER = 'landers/shoppinglist/CLEAR_SHOPPING_LIST_FILTER'

export const SAVE_SHOPPING_ARRANGE = 'landers/shoppinglist/SAVE_SHOPPING_ARRANGE'
export const SAVE_SHOPPING_LIST = 'landers/shoppinglist/SAVE_SHOPPING_LIST'

export const MODIFY_LIST = 'landers/shoppinglist/MODIFY_LIST'
// TODO: RENAME LATER
export const MODIFY_SHOPPING_LIST = 'landers/shoppinglist/MODIFY_SHOPPING_LIST'

export const SAVE_LIST_ID_TO_REMOVE = 'landers/shoppinglist/SAVE_LIST_ID_TO_REMOVE'

// type for funcion saga
export const REQUEST_CREATE_UPDATE_LIST = 'landers/shoppinglist/REQUEST_CREATE_UPDATE_LIST'
export const REQUEST_CREATE_UPDATE_LIST_ERROR = 'landers/shoppinglist/REQUEST_CREATE_UPDATE_LIST_ERROR'
export const LOAD_SHOPPING_LIST = 'landers/shoppinglist/LOAD_SHOPPING_LIST'
export const LOAD_ALL_PRODUCT_IN_MY_LIST = 'landers/shoppinglist/LOAD_ALL_PRODUCT_IN_MY_LIST'
export const LOAD_PAST_PURCHASE = 'landers/shoppinglist/LOAD_PAST_PURCHASE'
export const LOAD_LIST_DETAIL = 'landers/shoppinglist/LOAD_LIST_DETAIL'
export const LOAD_DELETE_LIST = 'landers/shoppinglist/LOAD_DELETE_LIST'
export const SHARE_SHOPPING__LIST = 'landers/shoppinglist/SHARE_SHOPPING__LIST'

export const LOAD_DELETE_ITEM_FROM_LIST = 'landers/shoppinglist/LOAD_DELETE_ITEM_FROM_LIST'
export const LOAD_SHARE_SHOPPING__LIST = 'landers/shoppinglist/LOAD_SHARE_SHOPPING__LIST'
export const SAVE_SHARE_SHOPPING__LIST = 'landers/shoppinglist/SAVE_SHARE_SHOPPING__LIST'
export const LOAD_SHARE_LIST_DETAIL = 'landers/shoppinglist/LOAD_SHARE_LIST_DETAIL'
export const ADD_SUBSCRIBE_SALE = 'landers/shoppinglist/ADD_SUBSCRIBE_SALE'
export const DELETE_SUBSCRIBE_SALE = 'landers/shoppinglist/DELETE_SUBSCRIBE_SALE'

export const ADD_PRODUCT_TO_SHOPPING_LIST = 'landers/shoppinglist/ADD_PRODUCT_TO_SHOPPING_LIST'
export const ADD_PRODUCT_TO_SHOPPING_LIST_SUCCESS = 'landers/shoppinglist/ADD_PRODUCT_TO_SHOPPING_LIST_SUCCESS'
export const ADD_PRODUCT_TO_SHOPPING_LIST_FAILED = 'landers/shoppinglist/ADD_PRODUCT_TO_SHOPPING_LIST_FAILED'
export const ADD_TO_MY_LIST = 'landers/shoppinglist/ADD_TO_MY_LIST'

export const frequencyArr = [
  {
    id: 'other',
    label: 'Other'
  },
  {
    id: 'weekly',
    label: 'Weekly'
  },
  {
    id: 'monthly',
    label: 'Monthly'
  },
  {
    id: 'every2weeks',
    label: 'Every 2 Weeks'
  },
]

export const dropdownFilter = {
  options: [
    {
      value: '1',
      label: 'Quantity - Low to High',
    },
    {
      value: '2',
      label: 'Quantity - High to Low',
    },
    {
      value: '3',
      label: 'Categories',
    },
    {
      value: '4',
      label: 'Recently Purchased',
    },
  ],
}
import request from './request'

export default {
  getCountryDetail(id) {
    return request.get({
      url: `directory/countries/${id}`,
    })
  },

  getRegionDetail(id) {
    return request.get({
      url: `directory/region/${id}`,
    })
  },

  getCityDetail(id) {
    return request.get({
      url: `directory/city/${id}`,
    })
  },

  notifyAddress(payload) {
    return request.post({
      url: `ldmultistores/location/notifyaddress`,
      data: payload,
    })
  },

  createCustomerAddress(payload) {
    return request.post({
      url: `ld-customer-address`,
      data: payload,
    })
  },

  updateCustomerAddress(payload) {
    return request.post({
      url: `ld-customer-address`,
      data: payload,
    })
  },

  deleteCustomerAddress(payload) {
    return request.post({
      url: `ld-customer-delete-address`,
      data: payload,
    })
  },
}

import { createSlice } from '@reduxjs/toolkit'

import { InitialState } from './type'
import INITIAL_STATE from './constants'

const slice = createSlice({
  name: 'modalSlice',
  initialState: INITIAL_STATE,
  reducers: {
    onShowBizMcModal: (state: InitialState) => {
      state.bizMcModal = true
    },
    onHideBizMcModal: (state: InitialState) => {
      state.bizMcModal = false
    },
    onShowBizRebateModal: (state: InitialState) => {
      state.bizRebateModal = true
    },
    onHideBizRebateModal: (state: InitialState) => {
      state.bizRebateModal = false
    },
  },
})

export const {
  onShowBizMcModal,
  onHideBizMcModal,
  onShowBizRebateModal,
  onHideBizRebateModal,
} = slice.actions

export default slice.reducer

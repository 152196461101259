import styled from 'styled-components';

const FadeInContainer = styled.div`
  -webkit-animation: ldFadeIn 1s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: ldFadeIn 1s; /* Firefox < 16 */
      -ms-animation: ldFadeIn 1s; /* Internet Explorer */
        -o-animation: ldFadeIn 1s; /* Opera < 12.1 */
          animation: ldFadeIn 1s;
`;

export { FadeInContainer };


import { delay, call, put, takeLatest, select, take } from 'redux-saga/effects';
import * as actions from './actions';
import * as constants from './constants';
import { isEmpty } from 'lodash';
import qs from 'qs';

import {
  saveProductDetail,
  loadRelatedProduct,
} from 'containers/ProductDetail/actions';
import * as AuthenticationActions from 'containers/Authentication/actions';
import { showLoader, hideLoader } from 'containers/Landers/actions';
import productDetailServices from 'services/productDetail';
import { getAccessToken } from 'utils/request';
import { checkLocationSuccess } from 'containers/Landers/actions';
import { mapDatastoreToAddress } from 'utils/helper';
import { makeSelectCurrentUser } from 'containers/Landers/selectors';
import { createAddress, updateAddress } from 'containers/Account/actions';
import {
  setShippingAddress,
  updateShippingAddress,
  selectDeliveryTimeslot,
  syncCartClient,
  syncProductThenGoCheckout,
} from 'containers/Checkout/actions';
import { makeSelectAddedProduct } from 'containers/Modals/selectors';
import { getInStockValue } from 'utils/product';
import { showError } from 'utils/notification';
import { loadProducts } from 'containers/Home/actions';
import urls from 'constant/urls';
import { syncCartAddressChange } from 'containers/Landers/actions';
import { getProgressBar } from 'containers/Checkout/screens/CheckoutCart/actions';
import { UPDATE_SHIPPING_ADDRESS_SUCCESS } from 'containers/Checkout/constants';
import { getStoreViewUrl } from 'utils/request';
import request from 'utils/request';
import { isCustomerMembership } from 'utils/validate';
import {
  getItemCartQty,
  getCartItems,
} from 'containers/Modals/screens/ReorderModal/functions';
import { makeSelectCartData } from 'containers/Checkout/selectors';
import { isSpecialInstruction } from 'utils/products';
import { getSpecialInstructionValue } from 'utils/cart';
import { ctViewItem } from 'utils/clevertap/ctEvent';

function* onShowCartNotificationModal(action) {
  yield delay(3000);
  yield put(actions.hideCartNotificationModal());
}

function* onLoadProductDetail(action) {
  const { isWithSpecialInstructions } = action?.tracking || {
    isWithSpecialInstructions: false,
  };

  try {
    yield put(showLoader());
    const data = yield call(
      [productDetailServices, 'getProductDetail'],
      action.payload,
    );

    const productIsSpecialInstruction = isSpecialInstruction(data);

    const cartData = yield select((state) => makeSelectCartData()(state));

    const cartItem = cartData.items.find((item) => {
      return item.sku === data.sku;
    });
    let specialInstructionValue = '';

    if (cartItem) {
      specialInstructionValue = productIsSpecialInstruction
        ? getSpecialInstructionValue(
            cartItem?.extension_attributes?.product_data?.options,
          )
        : '';
    }

    const productDetail = {
      ...data,
      isWithSpecialInstructions,
      specialInstructionValue,
    };
    ctViewItem(productDetail);
    yield put(loadRelatedProduct(data.sku));
    yield put(saveProductDetail(productDetail));
    yield put(actions.showProductDetailModalDone());
    yield put(hideLoader());
  } catch (err) {
    yield put(actions.hideProductDetailModal());
    yield put(hideLoader());
  }
}

function* onHideForgotPassword(action) {
  yield put(AuthenticationActions.clearDataResetPassword());
}

function* onConfirmPickupModal(action) {
  const { selectedStore, dateSlot, timeSlot, isMobile } = action.payload;

  const addedProduct = yield select((state) => makeSelectAddedProduct()(state));
  const currentUser = yield select((state) => makeSelectCurrentUser()(state));
  const cartData = yield select((state) => makeSelectCartData()(state));

  const pickupAddress = currentUser?.addresses?.find((address) => {
    return address.extension_attributes?.store_pickup;
  });

  if (!getAccessToken()) {
    yield put(checkLocationSuccess(selectedStore));
  } else {
    let address = mapDatastoreToAddress(selectedStore, currentUser);
    const isPickup = true;
    if (!isEmpty(pickupAddress)) {
      yield put(
        updateAddress(
          { address: { ...address, id: pickupAddress.id } },
          isPickup,
        ),
      );
    } else {
      yield put(createAddress({ address }, isPickup));
    }

    yield put(setShippingAddress(address));
    yield put(updateShippingAddress(false, { isUpdateAddress: true }));
    if (!isEmpty(cartData?.items)) {
      yield take(UPDATE_SHIPPING_ADDRESS_SUCCESS);
    }
  }

  if (!!dateSlot && !!timeSlot) {
    const selectTimeslot = {
      capacity: timeSlot.capacity,
      currentCapacity: timeSlot.currentCapacity,
      isDisabled: timeSlot.isDisabled,
      isStorePickup: timeSlot.isStorePickup,
      period: timeSlot.period,
      value: timeSlot.value,
      date: dateSlot.date,
      date_campaign: dateSlot.date_campaign,
      storeAddress: selectedStore,
    };

    yield put(selectDeliveryTimeslot(selectTimeslot));
  }

  if (isEmpty(addedProduct)) {
    yield put(actions.hideDeliveryAddressModal());

    yield put(actions.afterPickupModalClosed({ isMobile }));
  } else {
    yield put(actions.hideDeliveryAddressModal());

    const hasStock = getInStockValue(addedProduct);

    if (hasStock) {
      yield put(
        syncCartClient(
          [
            {
              data: addedProduct,
              qty: 1,
            },
          ],
          {
            delay: 500,
            needReloadTotals: true,
          },
        ),
      );
    }

    if (!hasStock) {
      showError(
        'Sorry, the product is no longer available in your selected store.',
      );
    }
    yield put(actions.afterPickupModalClosed({ isMobile }));
  }
}

function* onAfterPickupModalClosed(action) {
  const { isMobile } = action.payload;

  if (!isMobile) {
    if (window.location.pathname.includes(urls.checkoutCart)) {
      yield put(getProgressBar());
      yield put(syncProductThenGoCheckout());
      yield put(syncCartAddressChange(window.location.pathname, true));
    } else {
      // check whether to reload products when store code changed
      if (window.location.pathname === '/') {
        yield put(loadProducts());
        yield put(syncCartAddressChange(window.location.pathname, true));
      } else {
        yield put(syncCartAddressChange(window.location.pathname, true));
      }
    }
  }
}

function* onShowReorderModal(action) {
  const { order } = action.payload;
  const skus = order.items.map((item) => item.sku);

  yield put(showLoader());

  const params = {
    searchCriteria: {
      filterGroups: [
        {
          filters: [
            {
              field: 'sku',
              value: skus.join(','),
              condition_type: 'in',
            },
          ],
        },
      ],
    },
  };

  const requestURL = `${getStoreViewUrl()}/products?${qs.stringify(params, {
    encode: false,
  })}`;

  const products = yield call(request, requestURL, {
    method: 'GET',
  });

  const cartData = yield select((state) => makeSelectCartData()(state));
  const cartItems = getCartItems(cartData, false);
  const details = [];

  for (let product of products.items) {
    let detail = {
      data: product,
      qty: 1,
    };

    if (isCustomerMembership(product)) {
      continue;
    }

    const item = order.items.find((item) => product.sku === item.sku);

    detail.qty = item.qty_ordered;
    let storeStock = getInStockValue(detail.data);

    detail.originalStock = storeStock;
    detail.stocks = storeStock - getItemCartQty(cartItems, detail.data.sku);
    detail.isSelected = false;
    detail.isInvalid = storeStock <= 0;

    if (detail.stocks > 0 && detail.qty > detail.stocks) {
      detail.qty = detail.stocks;
    }

    details.push(detail);
  }

  yield put(hideLoader());
  yield put(actions.showReorderModalSuccess(details));
}

export default function* modalsSaga() {
  yield takeLatest(
    constants.SHOW_CART_NOTIFICATION_MODAL,
    onShowCartNotificationModal,
  );
  yield takeLatest(constants.SHOW_PRODUCT_DETAIL, onLoadProductDetail);
  yield takeLatest(constants.HIDE_FORGOT_PASSWORD, onHideForgotPassword);
  yield takeLatest(constants.CONFIRM_PICKUP_MODAL, onConfirmPickupModal);
  yield takeLatest(
    constants.AFTER_PICKUP_MODAL_CLOSED,
    onAfterPickupModalClosed,
  );
  yield takeLatest(constants.SHOW_REORDER_MODAL, onShowReorderModal);
}

export const INITIAL_STATE = {
  isShowMayaRenew: false,
  isShowMayaXLanders: false,
  isShowMayaApply: false,
  membershipSfData: {},
  isShowCompleteMaya: false,
  isShowMayaCompleteOrder: false,
  isMayaCCOnly: false,
  selectedMembershipProducts: {},
  verbiage: [],
  isShowOnlineAccountRequired: false,
  isLoginWithMayaCC: false,
  isShowSnackbar: false,
  snackbarDetails: {
    title: '',
    body: [],
  },
  isShowLinkMembershipCard: false,
  isShowMembershipLinkFormModal: false,
  mayaCCButtonOption: '',
  isShowExpireMC: false,
  expireMCAlert: {
    type: '',
    expiryDate: '',
  },
  membershipExpiryData: {},
  isShowMembershipEmailExistNotif: false,
}

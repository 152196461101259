import { get, isEmpty, clone } from 'lodash'

export const removeProductFromAList = (listId, productId, list) => {
  if (isEmpty(list)) return list
  if (!listId) return list
  const newObj = clone(list, true)
  newObj.products = newObj.products.filter((item, index) => {
    return item.id !== productId
  })
  return newObj
}

export const removeListFromShoppingList = (listId, shoppingList) => {
  if (shoppingList.length === 0) return shoppingList
  if (!listId) return shoppingList
  const newobj = [...shoppingList]
  return newobj.filter((item, index) => {
    return item.list_id !== listId
  })
}

export const makeThumbArray = (arr, MAX) => {
  const newArr = [...arr]
  const base = []
  newArr.forEach((item, index) => {
    if (index === MAX) return
    base.push(item)
  })
  while (base.length < MAX) {
    base.push('')
  }
  return base
}

// TODO: remove this one, when we have api
const filterSale = (arr) => {
  let newArr = [...arr]
  return newArr.filter((item, index) => {
    let price = parseFloat(item.extension_attributes.price)
    let finalPrice = parseFloat(item.extension_attributes.final_price)
    let dis = price - finalPrice
    return dis > 0
  })
}
const filterPriceWatch = (arr) => {
  let newArr = [...arr]
  return newArr.filter((item, index) => {
    return item.extension_attributes.is_subscribe_sale === true
  })
}

const arrangeLowToHigh = (arr) => {
  let newArr = [...arr]
  return newArr.sort((a, b) => {
    return (
      parseFloat(get(a, 'extension_attributes.total_purchase_qty', 0)) -
      parseFloat(get(b, 'extension_attributes.total_purchase_qty', 0))
    )
  })
}
const arrangeHighTolow = (arr) => {
  let newArr = [...arr]
  return newArr.sort((a, b) => {
    return (
      parseFloat(get(b, 'extension_attributes.total_purchase_qty', 0)) -
      parseFloat(get(a, 'extension_attributes.total_purchase_qty', 0))
    )
  })
}
const arrangeCategory = (arr) => {
  let newArr = [...arr]
  return newArr.sort((a, b) => {
    let x = get(a, 'extension_attributes.root_category_name', '').toLowerCase()
    let y = get(b, 'extension_attributes.root_category_name', '').toLowerCase()
    if (x < y) return -1
    if (x > y) return 1
    return 0
  })
}
const arrangeRecentPurchase = (arr) => {
  let newArr = [...arr]
  return newArr.sort((a, b) => {
    return (
      parseFloat(b.extension_attributes.last_purchase_qty) -
      parseFloat(a.extension_attributes.last_purchase_qty)
    )
  })
}

export const calulateTotalPrice = (products, filter) => {
  let filteredProducts = [...products]
  if (filter.ld_display === 'onsale') {
    //  1 is sale
    filteredProducts = filterSale(products)
  }
  if (filter.ld_display === 'watch') {
    // 2 si price watch
    filteredProducts = filterPriceWatch(products)
  }
  let total = 0
  filteredProducts.forEach((item, index) => {
    const finalPrice = get(item, 'extension_attributes.final_price', 0)
    total = total + parseFloat(finalPrice)
  })
  total = Number(total).toFixed(2)
  return total
}
export const filterProductShoppinglistDetail = (products, filter) => {
  let filteredProducts = [...products]
  if (filter.ld_display === 'onsale') {
    //  1 is sale
    filteredProducts = filterSale(products)
  }
  if (filter.ld_display === 'watch') {
    // 2 si price watch
    filteredProducts = filterPriceWatch(products)
  }
  switch (filter.product_list_order) {
    case '1':
      // 1 is quantity low to high
      filteredProducts = arrangeLowToHigh(filteredProducts)
      break
    case '2':
      // 2 is quantity high to low
      filteredProducts = arrangeHighTolow(filteredProducts)
      break
    case '3':
      // 3 arrange category
      filteredProducts = arrangeCategory(filteredProducts)
      break
    case '4':
      // 4 arrange recenly purchase
      filteredProducts = arrangeRecentPurchase(filteredProducts)
      break
    default:
      break
  }
  return filteredProducts
}

import React from 'react'
import './textComponent.scss'
const NotifySuccessNotification = ({ email }) => {
	return (
		<div className="textComponent">
			<div className="vty">
				<div className="whitec">We will send you an email</div>
				<div className="cinec">
					As soon as the product is available again, you'll receive an email at <span className="email">{email}</span>
				</div>
			</div>
		</div>
	)
}
export default NotifySuccessNotification

import * as constant from 'containers/ShoppingList/constants'
import { removeListFromShoppingList } from './helpers'

export function requestCreateUpdateList(payload) {
  return {
    type: constant.REQUEST_CREATE_UPDATE_LIST,
    payload,
  };
}

export function requestCreateUpdateListError(payload) {
  return {
    type: constant.REQUEST_CREATE_UPDATE_LIST_ERROR,
    payload,
  };
}

export function setCreateUpdateListLoading(error) {
  return {
    type: constant.CREATE_UPDATE_LIST_LOADING_START,
    error,
  };
}

export function closeCreateUpdateListLoading(error) {
    return {
      type: constant.CREATE_UPDATE_LIST_LOADING_FINISH,
      error,
    };
}

export function loadShoppingList() {
	return {
		type: constant.LOAD_SHOPPING_LIST,
	};
}

export function setShoppingListLoading(error) {
  return {
    type: constant.GET_SHOPPING_LIST_START,
    error,
  };
}

export function closeShoppingListLoading(error) {
	return {
		type: constant.GET_SHOPPING_LIST_FINISH,
		error,
	};
}

export const loadAllProductInMyList = (payload) => {
	return {
		type: constant.LOAD_ALL_PRODUCT_IN_MY_LIST,
		payload,
	};
}

export const saveAllProductInMyList = (payload) => {
	return {
		type: constant.SAVE_ALL_PRODUCT_IN_MY_LIST,
		payload,
	};
}

export const loadPastPurchase = (payload) => {
	return {
		type: constant.LOAD_PAST_PURCHASE,
		payload,
	};
}
export const savePastPurchase = (payload) => {
	return {
		type: constant.SAVE_PAST_PURCHASE,
		payload,
	};
}

export const loadListDetail = (payload) => {
	return {
    type: constant.LOAD_LIST_DETAIL,
    payload,
  };
}

export const addSubscribeSale = (payload) => {
	return {
		type: constant.ADD_SUBSCRIBE_SALE,
		payload,
	};
}

export const deleteSubscribeSale = (payload) => {
	return {
		type: constant.DELETE_SUBSCRIBE_SALE,
		payload,
	};
}

export function setListDetailLoading(error) {
  return {
    type: constant.GET_LIST_DETAIL_START,
    error,
  };
}

export function closeListDetailLoading(error) {
	return {
		type: constant.GET_LIST_DETAIL_FINISH,
		error,
	};
}

export function saveListDetail(payload) {
  return {
    type: constant.SAVE_LIST_DETAIL,
		payload
  };
}

export const saveShoppingDetailFilter = (payload) => {
	return {
		type: constant.SAVE_SHOPPING_LIST_FILTER,
		payload: payload,
	}
}

export const clearShoppingDetailFilter = () => {
	return {
		type: constant.CLEAR_SHOPPING_LIST_FILTER,
	}
}

export const saveShoppingList = (payload) => {
	return {
		type: constant.SAVE_SHOPPING_LIST,
		payload: payload,
	}
}


export const deleteShoppingList = (payload) => {
	return {
		type: constant.LOAD_DELETE_LIST,
		payload: payload,
	}
}

export const deleteProductFromShoppingList = (payload) => {
	return {
		type: constant.LOAD_DELETE_ITEM_FROM_LIST,
		payload: payload,
	}
}

export const modifyShoppingList = (payload) => {
	return {
		type: constant.MODIFY_SHOPPING_LIST,
		payload: removeListFromShoppingList(payload.listId, payload.shoppingList),
	}
}

export const saveListIdToRemove = (payload) => {
	return {
		type: constant.SAVE_LIST_ID_TO_REMOVE,
		payload: payload,
	}
}

export const shareShoppingList = (payload) => {
	return {
		type: constant.SHARE_SHOPPING__LIST,
		payload: payload,
	}
}

export const loadShareShoppingList = (payload) => {
	return {
		type: constant.LOAD_SHARE_SHOPPING__LIST,
		payload: payload,
	}
}

export const saveShareShoppingList = (payload) => {
	return {
		type: constant.SAVE_SHARE_SHOPPING__LIST,
		payload: payload,
	}
}

// ADD_PRODUCT_TO_SHOPPING_LIST
export const addProductToShoppingList = (payload) => {
	return {
		type: constant.ADD_PRODUCT_TO_SHOPPING_LIST,
		payload: payload,
	}
}

export const addProductToShoppingListSuccess = (payload) => {
	return {
		type: constant.ADD_PRODUCT_TO_SHOPPING_LIST_SUCCESS,
		payload: payload,
	}
}

export const addProductToShoppingListFailed = (payload) => {
	return {
		type: constant.ADD_PRODUCT_TO_SHOPPING_LIST_FAILED,
		payload: payload,
	}
}

export const addToMyList = (payload, data) => {
	return {
		type: constant.ADD_TO_MY_LIST,
		payload,
		data
	}
}
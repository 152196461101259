import { coreApi } from 'api/coreApi';
import { snapmartApi } from 'api/snapmartApi';
import { mgtApi } from 'api/mgtApi';

import listener from './listener';
import 'containers/Modals/slices/renewMaya';
import 'containers/Home/slices/nps/npsListener';

import 'containers/Account/listener';

export const middlewares = [
  coreApi.middleware,
  snapmartApi.middleware,
  mgtApi.middleware,
  listener.middleware,
];

import React from 'react'
import Loader from 'asset/image/loader.gif'

const sizes = {
  xsmall: {
    width: 20,
    height: 20,
  },
  small: {
    width: 50,
    height: 50,
  },
}

const LoaderImage = ({ size = 'small' }) => {
  return <img style={sizes[size]} src={Loader} />
}

export default LoaderImage

import * as constants from './constants';

// SHOW_DELIVERY_LOCATION
export function showDeliveryLocationModal(addedProduct = {}) {
  return {
    type: constants.SHOW_DELIVERY_LOCATION,
    addedProduct,
  };
}

export function hideDeliveryLocationModal() {
  return {
    type: constants.HIDE_DELIVERY_LOCATION,
  };
}

// SHOW_PRODUCT_DETAIL
export function showProductDetailModal(payload, tracking) {
  return {
    type: constants.SHOW_PRODUCT_DETAIL,
    payload,
    tracking,
  };
}

export function showProductDetailModalDone(payload) {
  return {
    type: constants.SHOW_PRODUCT_DETAIL_DONE,
    payload,
  };
}

export function hideProductDetailModal() {
  return {
    type: constants.HIDE_PRODUCT_DETAIL,
  };
}

// SHOW_LOGIN
export function showLoginModal(params = {}) {
  return {
    type: constants.SHOW_LOGIN,
    params,
  };
}

export function hideLoginModal() {
  return {
    type: constants.HIDE_LOGIN,
  };
}

// SHOW_FORGOT_PASSWORD
export function showForgotPasswordModal(params = {}) {
  return {
    type: constants.SHOW_FORGOT_PASSWORD,
    params,
  };
}

export function hideForgotPasswordModal() {
  return {
    type: constants.HIDE_FORGOT_PASSWORD,
  };
}

// SHOW_ADDUPDATE_ADDRESS
export function showAddUpdateAddressModal(initAddress) {
  return {
    type: constants.SHOW_ADDUPDATE_ADDRESS,
    initAddress,
  };
}

export function hideAddUpdateAddressModal() {
  return {
    type: constants.HIDE_ADDUPDATE_ADDRESS,
  };
}

// SHOW_INVALID_CARTITEMS
export function showInvalidCartItemsModal(invalidSkus = null) {
  return {
    type: constants.SHOW_INVALID_CARTITEMS,
    invalidSkus,
  };
}
export function hideInvalidCartItemsModal() {
  return {
    type: constants.HIDE_INVALID_CARTITEMS,
  };
}
//SHOW MAX CART ITEM QTY
export function showMaxCartItemsQtyModal(invalidSkus = null) {
  return {
    type: constants.SHOW_MAX_CARTITEMS_QTY,
    invalidSkus,
  };
}
export function hideMaxCartItemsQtyModal() {
  return {
    type: constants.HIDE_MAX_CARTITEMS_QTY,
  };
}

// REQUEST ADD MEMBERSHIP MODAL
export function showRequestAddMembershipModal() {
  return {
    type: constants.SHOW_REQUEST_ADD_MEMBERSHIP,
  };
}

export function hideRequestAddMembershipModal() {
  return {
    type: constants.HIDE_REQUEST_ADD_MEMBERSHIP,
  };
}

// REQUEST ADD MEMBERSHIP MODAL
export function showCartNotificationModal(info = {}) {
  return {
    type: constants.SHOW_CART_NOTIFICATION_MODAL,
    info,
  };
}

export function hideCartNotificationModal() {
  return {
    type: constants.HIDE_CART_NOTIFICATION_MODAL,
  };
}

// SHOW_REMOVE_ALL_CART_ITEMS
export function showRemoveAllCartItemsModal() {
  return {
    type: constants.SHOW_REMOVE_ALL_CART_ITEMS,
  };
}

export function hideRemoveAllCartItemsModal() {
  return {
    type: constants.HIDE_REMOVE_ALL_CART_ITEMS,
  };
}

// SHOW_REMOVE_CART_ITEMS
export function showRemoveCartItemsModal(cartItems) {
  return {
    type: constants.SHOW_REMOVE_CART_ITEMS,
    cartItems,
  };
}

export function hideRemoveCartItemsModal() {
  return {
    type: constants.HIDE_REMOVE_CART_ITEMS,
  };
}

// SHOW_ALERT
export function showAlertModal(alertOptions) {
  return {
    type: constants.SHOW_ALERT,
    alertOptions,
  };
}

export function hideAlertModal() {
  return {
    type: constants.HIDE_ALERT,
  };
}

export function changeTabSignUp(payload) {
  return {
    type: constants.CHANGE_TAB_SIGN_UP,
    payload,
  };
}

// SHOW_MEMBERSHIP_MODAL
export function showMembershipModal(params = {}) {
  return {
    type: constants.SHOW_MEMBERSHIP_MODAL,
    params,
  };
}

export function hideMembershipModal() {
  return {
    type: constants.HIDE_MEMBERSHIP_MODAL,
  };
}

export function showModalInformMembershipIsExpired() {
  return {
    type: constants.SHOW_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED,
  };
}

export function hideModalInformMembershipIsExpired() {
  return {
    type: constants.HIDE_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED,
  };
}

// SHOPPING_LIST
export function showShareShoppingListModal(payload) {
  return {
    type: constants.SHOW_SHARE_SHOPPING_LIST,
    params: {
      isShare: true,
      listId: payload,
    },
  };
}

export function showModalCreateShoppingList(payload) {
  return {
    type: constants.SHOW_CREATE_SHOPPING_LIST,
    params: {
      isCreate: true,
      payload,
    },
  };
}

export function showModalEditShoppingList(payload) {
  return {
    type: constants.SHOW_EDIT_SHOPPING_LIST,
    params: {
      isEdit: true,
      shoppingListDetail: payload,
    },
  };
}

export function showModalAddProductToShoppingList(productItem) {
  return {
    type: constants.SHOW_ADD_PRODUCT_TO_SHOPPING_LIST,
    params: {
      isAddProduct: true,
      productItem: productItem,
    },
  };
}

export function hideModalShoppingList() {
  return {
    type: constants.HIDE_MODAL_SHOPPING_LIST,
  };
}

export function showModalFaqSuccess() {
  return {
    type: constants.SHOW_MODAL_FAQ_SUCCESS,
  };
}

export function showModalFaqFailed(error) {
  return {
    type: constants.SHOW_MODAL_FAQ_FAILED,
    error,
  };
}

export function hideModalFaq() {
  return {
    type: constants.HIDE_MODAL_FAQ,
  };
}

// SHOW_PICKUP_CONFIRM
export function showModalPickUpConfirmation(params) {
  return {
    type: constants.SHOW_PICKUP_CONFIRMATION,
    params,
  };
}

export function hideModalPickUpConfirmation() {
  return {
    type: constants.HIDE_PICKUP_CONFIRMATION,
  };
}

// SHOW_APP_PROMOTION_MODAL
export function showAppPromotionModal(params) {
  return {
    type: constants.SHOW_APP_PROMOTION_MODAL,
    params,
  };
}

export function hideAppPromotionModal() {
  return {
    type: constants.HIDE_APP_PROMOTION_MODAL,
  };
}

// SHOW_SPLASH_AD_MODAL
export function showSplashAdModal(payload) {
  return {
    type: constants.SHOW_SPLASH_AD_MODAL,
    payload,
  };
}

export function hideSplashAdModal() {
  return {
    type: constants.HIDE_SPLASH_AD_MODAL,
  };
}

// SHOW NEW MC EXPIRED MODAL
export function showNewMCExpiredModal(params) {
  return {
    type: constants.SHOW_NEW_MC_EXPIRED_MODAL,
    params,
  };
}

export function hideNewMCExpiredModal() {
  return {
    type: constants.HIDE_NEW_MC_EXPIRED_MODAL,
  };
}

// SHOW NET PROMOTERS SCORE / NPS MODAL
export function showNetPromotersScoreSurveyModal(payload) {
  return {
    type: constants.SHOW_NET_PROMOTERS_SCORE_SURVEY_MODAL,
    payload,
  };
}

export function hideNetPromotersScoreSurveyModal() {
  return {
    type: constants.HIDE_NET_PROMOTERS_SCORE_SURVEY_MODAL,
  };
}

// SHOW SUBSCRIBE
export function showSubscribeSuccessModal(params) {
  return {
    type: constants.SHOW_SUBSCRIBE_SUCCESS_MODAL,
    params,
  };
}

export function hideSubscribeSuccessModal() {
  return {
    type: constants.HIDE_SUBSCRIBE_SUCCESS_MODAL,
  };
}

// SHOW DELIVERY ADDRESS MODAL
export function showDeliveryAddressModal(params) {
  return {
    type: constants.SHOW_DELIVERY_ADDRESS_MODAL,
    params,
  };
}

export function hideDeliveryAddressModal() {
  return {
    type: constants.HIDE_DELIVERY_ADDRESS_MODAL,
  };
}

export function showPaymentCardModal(params) {
  return {
    type: constants.SHOW_PAYMENT_CARD_MODAL,
    params,
  };
}

export function hidePaymentCardModal() {
  return {
    type: constants.HIDE_PAYMENT_CARD_MODAL,
  };
}

export function showCardSavedModal(params) {
  return {
    type: constants.SHOW_CARD_SAVED_MODAL,
    params,
  };
}

export function hideCardSavedModal() {
  return {
    type: constants.HIDE_CARD_SAVED_MODAL,
  };
}

export function showCardSavedSuccess(params) {
  return {
    type: constants.SHOW_CARD_SAVED_SUCCESS,
    params,
  };
}

export function showRemoveCardSavedModal(params) {
  return {
    type: constants.SHOW_REMOVE_CARD_SAVED_MODAL,
    params,
  };
}

export function hideRemoveCardSavedModal() {
  return {
    type: constants.HIDE_REMOVE_CARD_SAVED_MODAL,
  };
}

export function showCVCToolTipModal(params) {
  return {
    type: constants.SHOW_CVC_TOOLTIP_MODAL,
    params,
  };
}

export function hideCVCToolTipModal() {
  return {
    type: constants.HIDE_CVC_TOOLTIP_MODAL,
  };
}

export function showAddCardModal(params) {
  return {
    type: constants.SHOW_ADD_CARD_MODAL,
    params,
  };
}

export function hideAddCardModal() {
  return {
    type: constants.HIDE_ADD_CARD_MODAL,
  };
}

export function showAddMoreItemModal(payload) {
  return {
    type: constants.SHOW_ADD_MORE_ITEM_MODAL,
    payload,
  };
}

export function showSupportMessage(payload) {
  return {
    type: constants.LINK_MEMBERSHIP_SHOW_SUPPORT_MESSAGE,
    payload,
  };
}

export function showErrorMessageModal(payload) {
  return {
    type: constants.SHOW_ERROR_MESSGE_MODAL,
    payload,
  };
}

export function showDropshipWarningModal(payload) {
  return {
    type: constants.SHOW_DROPSHIP_WARNING_MODAL,
    payload,
  };
}

export function showReorderModal(payload) {
  return {
    type: constants.SHOW_REORDER_MODAL,
    payload,
  };
}

export function showReorderModalSuccess(payload) {
  return {
    type: constants.SHOW_REORDER_MODAL_SUCCESS,
    payload,
  };
}

export function setReorderProductDetails(payload) {
  return {
    type: constants.SET_REORDER_PRODUCT_DETAILS,
    payload,
  };
}

export function hideReorderModal() {
  return {
    type: constants.HIDE_REORDER_MODAL,
  };
}

export function setMCExpiredTooltip(payload) {
  return {
    type: constants.SET_EXPIRED_MC_MODAL,
    payload,
  };
}

export function confirmPickupModal(payload) {
  return {
    type: constants.CONFIRM_PICKUP_MODAL,
    payload,
  };
}

export function afterPickupModalClosed(payload) {
  return {
    type: constants.AFTER_PICKUP_MODAL_CLOSED,
    payload,
  };
}

export function setNetPromotersScoreSuccess(payload) {
  return {
    type: constants.SET_NET_PROMOTERS_SCORE_SUCCESS,
    payload,
  };
}
export function showPriceFilterModal(payload) {
  return {
    type: constants.SHOW_PRICE_FILTER_MODAL,
    payload,
  };
}
export function hidePriceFilterModal() {
  return {
    type: constants.HIDE_PRICE_FILTER_MODAL,
  };
}
export function showRaffleStepsModal(payload) {
  return {
    type: constants.SHOW_RAFFLE_STEPS_MODAL,
    payload,
  };
}
export function hideRaffleStepsModal() {
  return {
    type: constants.HIDE_RAFFLE_STEPS_MODAL,
  };
}

export function showPremiumRequiredModal() {
  return {
    type: constants.SHOW_PREMIUM_REQUIRED_MODAL,
  };
}

export function hidePremiumRequiredModal() {
  return {
    type: constants.HIDE_PREMIUM_REQUIRED_MODAL,
  };
}

export function showExecModal() {
  return {
    type: constants.SHOW_EXECUTIVE_MODAL,
  };
}

export function hideExecModal() {
  return {
    type: constants.HIDE_EXECUTIVE_MODAL,
  };
}

import { history, store } from 'configureStore';
import config from 'global-config';
import { DEFAULT_STORE_VIEW } from 'containers/Landers/constants';

const AUTH_API_PATHS = [
  '/me/password',
  '/customer/login',
  '/landersapi-customer-register',
];

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function checkStatus(response) {
  const isNotAuthAPI = AUTH_API_PATHS.every(
    (path) => !response.url.includes(path),
  );

  if (
    response.url.includes('snapmart-quote/pay-placeorder') &&
    response.status >= 300
  ) {
    // send error log to magento API
    setTimeout(async () => {
      const cartId = store.getState()?.rncheckout?.cartData?.id;
      const message = await response.text();

      fetch(`${config.apiUrl}/snapmart-quote/payment-error-handling`, {
        method: 'POST',
        body: JSON.stringify({
          cart_id: cartId,
          message,
          status: response.status,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
    }, 2000);
  }

  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401 && isNotAuthAPI) {
    accessToken = '';
    history.push('/', { isTokenExpired: true });
  }

  throw response;
}

let accessToken = '';
let storeCode = '';
let expiresIn = '';

export function setAccessToken(token) {
  accessToken = token;
}

export function getAccessToken() {
  return accessToken;
}

export function setExpiry(expires_in) {
  expiresIn = expires_in;
}

export function getExpiry() {
  return expiresIn;
}

export function setStoreCode(code) {
  storeCode = code;
}

export function getStoreCode() {
  return storeCode;
}

/**
 * @type {string}
 */
let storeView = DEFAULT_STORE_VIEW;

/**
 * @deprecated should be using redux store storeCodeView instead
 * @param {string} storeViewCode
 */
export function setStoreView(storeViewCode) {
  storeView = storeViewCode;
}

export function getStoreView() {
  return storeView;
}

/**
 *
 * @returns {string}
 */
export function getStoreViewUrl() {
  return `${config.baseUrl}/rest/${storeView}/V1`;
}

export default function request(url, options, token = '') {
  const parsedOptions = Object.assign(
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token ? token : accessToken}`,
        sourceCode: storeCode ? storeCode : '',
      },
    },
    options,
  );

  return fetch(url, parsedOptions).then(checkStatus).then(parseJSON);
}

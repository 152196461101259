import * as constant from './constants'

export function loadProducts() {
  return {
    type: constant.LOAD_PRODUCTS,
  }
}

export function loadProductsSuccess(payload) {
  return {
    type: constant.LOAD_PRODUCTS_SUCCESS,
    payload,
  }
}

export function loadProductsFailed(error) {
  return {
    type: constant.LOAD_PRODUCTS_FAILED,
    error,
  }
}

export function loadWidget(hash) {
  return {
    type: constant.LOAD_WIDGET,
    hash,
  }
}

export function loadWidgetSuccess(payload) {
  return {
    type: constant.LOAD_WIDGET_SUCCESS,
    payload,
  }
}

export function loadWidgetFailed(error) {
  return {
    type: constant.LOAD_WIDGET_FAILED,
    error,
  }
}

export function loadEvents() {
  return {
    type: constant.LOAD_EVENTS,
  }
}

export function loadEventsSuccess(payload) {
  return {
    type: constant.LOAD_EVENTS_SUCCESS,
    payload,
  }
}

export function loadEventsFailed(error) {
  return {
    type: constant.LOAD_EVENTS_FAILED,
    error,
  }
}

export function loadCategoriesCards() {
  return {
    type: constant.LOAD_CATEGORIES_CARDS,
  }
}
export function loadCategoriesCardsSuccess(payload) {
  return {
    type: constant.LOAD_CATEGORIES_CARDS_SUCCESS,
    payload,
  }
}
export function loadCategoriesCardsFailed(error) {
  return {
    type: constant.LOAD_CATEGORIES_CARDS_FAILED,
    error,
  }
}

export function loadBannerSlider() {
  return {
    type: constant.LOAD_BANNER_SLIDER,
  }
}
export function loadBannerSliderSuccess(payload) {
  return {
    type: constant.LOAD_BANNER_SLIDER_SUCCESS,
    payload,
  }
}
export function loadBannerSliderFailed(error) {
  return {
    type: constant.LOAD_BANNER_SLIDER_FAILED,
    error,
  }
}

export function loadPastPurchaseProducts() {
  return {
    type: constant.LOAD_PAST_PURCHASE_PRODUCTS,
  }
}
export function loadPastPurchaseProductsSuccess(payload) {
  return {
    type: constant.LOAD_PAST_PURCHASE_PRODUCTS_SUCCESS,
    payload,
  }
}

export function loadPastPurchaseProductsFailed(error) {
  return {
    type: constant.LOAD_PAST_PURCHASE_PRODUCTS_FAILED,
    error,
  }
}

export function getSplashAd(payload) {
  return {
    type: constant.GET_SPLASH_AD,
    payload,
  }
}
export function getSplashAdSuccess(payload) {
  return {
    type: constant.GET_SPLASH_AD_SUCCESS,
    payload,
  }
}

export function getSplashAdFailed(error) {
  return {
    type: constant.GET_SPLASH_AD_FAILED,
    error,
  }
}

export function loadNetPromotersScoreSurvey(payload) {
  return {
    type: constant.LOAD_NET_PROMOTERS_SCORE_SURVEY,
    payload,
  }
}

export function loadNetPromotersScoreSurveySuccess(payload) {
  return {
    type: constant.LOAD_NET_PROMOTERS_SCORE_SURVEY_SUCCESS,
    payload,
  }
}

export function loadNetPromotersScoreSurveyFailed(error) {
  return {
    type: constant.LOAD_NET_PROMOTERS_SCORE_SURVEY_FAILED,
    error,
  }
}

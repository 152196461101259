// eslint-disable-next-line
import { produce } from 'immer'
import { getStoreCode } from 'utils/request'

import * as constants from './constants'

export const initialState = {
  loadingHomeCards: false,
  bannerSlider: null,
  homeCards: null,
  categoriesCards: null,
  events: null,
  homeStoreCode: '',
  pastPurchases: null,
  widgets: [],
  splashAd: null,
  netPromotersScoreSurvey: null,
}

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      // LOAD_BANNER_SLIDER
      case constants.LOAD_BANNER_SLIDER:
        break
      case constants.LOAD_BANNER_SLIDER_SUCCESS:
        newState.bannerSlider = action.payload
        break
      case constants.LOAD_BANNER_SLIDER_FAILED:
        newState.bannerSlider = []
        break

      // LOAD_PRODUCTS
      case constants.LOAD_PRODUCTS:
        newState.loadingHomeCards = true
        newState.homeCards = null
        newState.widgets = []
        break
      case constants.LOAD_PRODUCTS_SUCCESS:
        newState.loadingHomeCards = false
        newState.homeStoreCode = getStoreCode()
        if (action.payload) {
          newState.homeCards = action.payload || []
        } else {
          newState.homeCards = []
        }

        break
      case constants.LOAD_PRODUCTS_FAILED:
        newState.loadingHomeCards = false
        newState.homeCards = []
        break

      // LOAD_WIDGET
      case constants.LOAD_WIDGET:
        newState.widgetLoading = true
        break
      case constants.LOAD_WIDGET_SUCCESS:
        if (action.payload && action.payload.data) {
          newState.widgets = [...state.widgets, action.payload]
        }
        newState.widgetLoading = false
        break
      case constants.LOAD_WIDGET_FAILED:
        newState.widgetLoading = false
        break

      // LOAD_CATEGORIES_CARDS
      case constants.LOAD_CATEGORIES_CARDS:
        break
      case constants.LOAD_CATEGORIES_CARDS_SUCCESS:
        if (action.payload) {
          newState.categoriesCards = action.payload
        } else {
          newState.categoriesCards = []
        }

        break
      case constants.LOAD_CATEGORIES_CARDS_FAILED:
        newState.categoriesCards = []
        break

      // LOAD_EVENTS
      case constants.LOAD_EVENTS:
        break
      case constants.LOAD_EVENTS_SUCCESS:
        // in saga file it is object
        newState.events = action.payload || {}
        break
      case constants.LOAD_EVENTS_FAILED:
        newState.events = {}
        break

      // LOAD_EVENTS
      case constants.LOAD_PAST_PURCHASE_PRODUCTS:
        break
      case constants.LOAD_PAST_PURCHASE_PRODUCTS_SUCCESS:
        // in saga file it is object
        newState.pastPurchases = action.payload || {}
        break
      case constants.LOAD_PAST_PURCHASE_PRODUCTS_FAILED:
        newState.pastPurchases = {}
        break

      case constants.GET_SPLASH_AD:
        break
      case constants.GET_SPLASH_AD_SUCCESS:
        newState.splashAd = action.payload
        break
      case constants.GET_SPLASH_AD_FAILED:
        newState.splashAd = null
        break

      // Load Net Promoters Score
      case constants.LOAD_NET_PROMOTERS_SCORE_SURVEY:
        break
      case constants.LOAD_NET_PROMOTERS_SCORE_SURVEY_SUCCESS:
        newState.netPromotersScoreSurvey = action.payload
        break
      case constants.LOAD_NET_PROMOTERS_SCORE_SURVEY_FAILED:
        newState.netPromotersScoreSurvey = null
        break
    }
  })

export default reducer

import { get } from 'lodash'

import { CAPITAL_CARE_VENDOR } from 'global-constant'

export const calculateCapitalCareProduct = (cartItems = []) => {
  return cartItems.reduce((acc, currentItem) => {
    if (
      get(
        currentItem,
        'extension_attributes.product_data.extension_attributes.vendor',
      ) === CAPITAL_CARE_VENDOR
    ) {
      return acc + get(currentItem, 'base_row_total', 0)
    } else {
      return acc
    }
  }, 0)
}

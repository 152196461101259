import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

export function setAuthData(token) {
  if (sessionStorage.getItem('is_customer_support')) {
    sessionStorage.setItem('Landers_userToken', token);
  } else {
    localStorage.setItem('Landers_userToken', token);
  }
}

export function getAuthData() {
  if (sessionStorage.getItem('is_customer_support')) {
    return sessionStorage.getItem('Landers_userToken');
  } else {
    return localStorage.getItem('Landers_userToken');
  }
}

export function clearAuthData() {
  if (sessionStorage.getItem('is_customer_support')) {
    sessionStorage.removeItem('Landers_userToken');
  } else {
    localStorage.removeItem('Landers_userToken');
  }
}

export function setSplashAd(value) {
  localStorage.setItem('splash_ad_banner', value);
}

export function setSplashAdSession(value) {
  sessionStorage.setItem('splash_ad_session', value);
}

export function setDeviceId() {
  const deviceId = getDeviceId();
  if (!deviceId) {
    localStorage.setItem('device_id', uuidv4());
  }
}

export function getDeviceId() {
  return localStorage.getItem('device_id');
}

export function getSplashAdSession(value) {
  return sessionStorage.getItem('splash_ad_session', value);
}

export function showSplashAdBanner() {
  const splashAdTime = localStorage.getItem('splash_ad_banner');
  const isExpired =
    dayjs(splashAdTime).isValid() && dayjs().isAfter(splashAdTime);

  if (!splashAdTime || isExpired) {
    return true;
  }

  return false;
}

export function setSelectedMenu(menu) {
  localStorage.setItem('selected_menu', menu);
}

export function getSelectedMenu() {
  return localStorage.getItem('selected_menu');
}

import { call, put, takeLatest } from 'redux-saga/effects'

import * as actions from './actions'
import * as constant from './constants'
import config from 'global-config'

const { apiUrl } = config

function* onLoadEvents(action) {
  const requestURL =
    'https://landers-static-assets.s3.ap-southeast-1.amazonaws.com/marketing/experience/new-landers-experience-list.json'
  try {
    yield put(actions.setLoading())
    const res = yield call(fetch, requestURL, {
      method: 'GET',
    })
    const data = yield res.json()
    yield put(actions.loadExpEventSuccess(data))
  } catch (err) {
    yield put(actions.loadExpEventFailed(err))
  } finally {
    yield put(actions.closeLoading())
  }
}

function* onLoadBanner(action) {
  const requestURL = `${apiUrl}/json-page/the-landers-experience`
  // const requestURL = `${apiUrl}json-page/fram`;
  try {
    const res = yield call(fetch, requestURL, {
      method: 'GET',
    })
    const data = yield res.json()
    yield put(actions.loadExpBannerSuccess(data))
  } catch (err) {
    yield put(actions.loadExpBannerFailed(err))
  } finally {
  }
}

export default function* expSaga() {
  yield takeLatest(constant.GET_ALL_EXP_EVENT, onLoadEvents)
  yield takeLatest(constant.GET_BANNER_EXP, onLoadBanner)
}

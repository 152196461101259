import * as constant from './constants'

export const initialState = {
  eventCard: [],
	isLoading: true,
	banner: [],
}
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case constant.EXP_LOAD_EVENTS_SUCCESS:
			return {
				...state,
				eventCard: action.payload,
			}
		case constant.EXP_LOAD_EVENTS_FAILED:
			return {
				...state,
				eventCard: [],
			}
		case constant.EXP_LOAD_BANNER_SUCCESS:
			return {
				...state,
				banner: action.payload,
			}
		case constant.EXP_LOAD_BANNER_FAILED:
			return {
				...state,
				banner: [],
			}
		case constant.EXP_LOADING_START:
			return {
				...state,
				isLoading: true,
			}
		case constant.EXP_LOADING_FINISH:
			return {
				...state,
				isLoading: false,
			}
		default:
			return state
	}
}
export default reducer

export const calculatepercent = (price, finalprice) => {
  const percent = (parseFloat(finalprice) * 100) / parseFloat(price)
  if (parseFloat(finalprice) < parseFloat(price)) {
    return Math.floor(percent) + '%'
  } else {
    return false
  }
}
export const calculateDiscountPercent = (originalPrice, finalPrice) => {
  if (isFinite(originalPrice) && isFinite(finalPrice) && originalPrice > 0) {
    return Math.round(((originalPrice - finalPrice) / originalPrice) * 100)
  }
  return 0
}

export const getSpecialInstructionValue = (options) => {
  if (options === undefined || options.length === 0 || !options) return ''

  const specialInstruction = options.find(
    (options) => options && options?.special_instructions
  )

  if (specialInstruction?.special_instructions === 1) {
    const value = options.find(
      (options) => options && options?.special_instructions_value
    )

    return value?.special_instructions_value || ''
  }

  return ''
}

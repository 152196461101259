import { trackEvent, trackingEvent } from 'utils/firebase'

export function ga4TrackBeginCheckout(cartData) {
  const cartItems = []

  cartData?.items.forEach((product) => {
    cartItems.push({
      item_id: product.sku,
      item_name: product.name,
      quantity: product.qty,
      price: product.price,
    })
  })

  trackEvent(trackingEvent.beginCheckout, {
    currency: 'PHP',
    value: cartData.subtotal,
    items: cartItems,
  })
}

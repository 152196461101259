import * as constant from './constants'

export const initialState = {
  templateTrackAndTrace: [],
  orderTracker: []
}
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case constant.LOAD_TRACK_AND_TRACE:
			return {
				...state,
			}
    case constant.SAVE_TRACK_AND_TRACE:
      return {
        ...state,
      templateTrackAndTrace: action.payload,
    }
    case constant.LOAD_ORDER_TRACER:
      return {
        ...state,
      }
    case constant.SAVE_ORDER_TRACER:
      return {
        ...state,
        orderTracker: action.payload,
    }
		default:
			return state
	}
}
export default reducer

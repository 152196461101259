export const getItemCartQty = (cartItems, sku) => {
  let qty = 0

  for (let item of cartItems) {
    if (item.sku === sku) {
      qty = item.qty
      break
    }
  }

  return qty
}

export const getCartItems = (cartData, isInCart) => {
  if (Array.isArray(cartData.promotion_items) && isInCart) {
    return cartData.promotion_items
  }
  if (Array.isArray(cartData.items)) {
    return cartData.items
  }
  return []
}

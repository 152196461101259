import { produce } from 'immer'
import { concat } from 'lodash'
import * as constants from './constants'

export const initialState = {
  results: [],
  allResults: [],
  isLoadingResultSearch: false,
  listCategorySearch: [],
  resultsPopup: null,
  currentPage: 1,
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.SEARCH_HEADER:
        newState.resultsPopup = null
        break
      case constants.SEARCH_HEADER_SUCCESS:
        newState.resultsPopup = action.payload
        break
      case constants.SEARCH_HEADER_FAILED:
        newState.resultsPopup = []
        break
      case constants.RESET_SEARCH:
        newState.resultsPopup = null
        break
      case constants.LOAD_SEARCH_RESULTS:
        newState.isLoadingResultSearch = true
        if (!action.payload.isLoadMore) {
          newState.allResults = []
        }
        break
      case constants.LOAD_SEARCH_RESULTS_SUCCESS: {
        const currentAllResult = state.allResults
        let newAllResult = []
        if (action.isLoadMore) {
          newAllResult = concat(currentAllResult, action.payload)
        } else {
          newAllResult = action.payload
        }
        newState.allResults = newAllResult
        newState.isLoadingResultSearch = false
        newState.currentPage = action.currentPage
        break
      }
      case constants.LOAD_SEARCH_RESULTS_FAILED:
        newState.isLoadingResultSearch = false
        break
      case constants.SAVE_LIST_CATEGORY_SEARCH:
        newState.listCategorySearch = action.payload
        break
      default:
        break
    }
  })

export default reducer

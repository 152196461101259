import { initialState } from './reducer'
import { createSelector } from 'reselect'

/**
 * Direct selector to the authorize state domain
 */

const selectMembershipDomain = (state) => state.rnmembership || initialState

/**
 * Other specific selectors
 */

export const makeSelectPrimaryMembershipInfo = () =>
  createSelector(
    selectMembershipDomain,
    (substate) => substate.primaryMembershipInfo,
  )

export const makeSelectMembershipProducts = () =>
  createSelector(
    selectMembershipDomain,
    (substate) => substate.membershipProducts,
  )

export const makeSelectMembershipBanners = () =>
  createSelector(
    selectMembershipDomain,
    (substate) => substate.membershipBanners,
  )

export const makeSelectMembershipLink = () =>
  createSelector(selectMembershipDomain, (substate) => substate.membershipLink)

export const makeSelectMembershipError = () =>
  createSelector(selectMembershipDomain, (substate) => substate.membershipError)

export const makeSelectMembershipInfo = () =>
  createSelector(selectMembershipDomain, (substate) => substate.membershipInfo)

export const makeSelectRaffleConfigs = () =>
  createSelector(selectMembershipDomain, (substate) => substate.raffleConfigs)

export const makeSelectRaffleFaqs = () =>
  createSelector(selectMembershipDomain, (substate) => substate.raffleFaqs)

export const makeSelectBusinessMembership = () =>
  createSelector(
    selectMembershipDomain,
    (substate) => substate.membershipBusiness,
  )

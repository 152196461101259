import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectCartData,
  makeSelectOrderId,
} from 'containers/Checkout/selectors'
import {
  makeSelectSideBar,
  makeSelectHasLoader,
  makeSelectIsShowSideBar,
  makeSelectCurrentUser,
  makeSelectPersistorLoaded,
  makeSelectSubMenuTop,
  makeSelectIsLoadingFooter,
  makeSelectGlobalConfig,
  makeSelectSearchAndAutoSuggest,
} from 'containers/Landers/selectors'

import loadable from 'utils/loadable'
const LandersApp = loadable(() => import('.'))

const mapStateToProps = createStructuredSelector({
  cartData: makeSelectCartData(),
  sideBar: makeSelectSideBar(),
  subMenuTop: makeSelectSubMenuTop(),
  hasLoader: makeSelectHasLoader(),
  isLoadingFooter: makeSelectIsLoadingFooter(),
  isShowSideBar: makeSelectIsShowSideBar(),
  currentUser: makeSelectCurrentUser(),
  persistorLoaded: makeSelectPersistorLoaded(),
  globalConfig: makeSelectGlobalConfig(),
  orderId: makeSelectOrderId(),
  searchAndAutoSuggest: makeSelectSearchAndAutoSuggest(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRouter, withConnect)(LandersApp)

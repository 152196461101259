export const getPercent = (
  price: number | string,
  finalPrice: number | string,
) => {
  if (typeof price === 'string' && typeof finalPrice === 'string') {
    finalPrice = parseFloat(finalPrice);
    price = parseFloat(price);
    if (finalPrice < price) {
      return Math.round(((price - finalPrice) * 100) / price) + '%';
    } else {
      return '0%';
    }
  } else {
    if (finalPrice < price) {
      return Math.round(((+price - +finalPrice) * 100) / +price) + '%';
    } else {
      return '0%';
    }
  }
};

/*
 *
 * Membership constants
 *
 */

// LOAD MEMBERSHIP INFO
export const VERIFY_EXTENSION_MEMBERSHIP =
  'Landers/Membership/VERIFY_EXTENSION_MEMBERSHIP'
export const VERIFY_EXTENSION_MEMBERSHIP_SUCCESS =
  'Landers/Membership/VERIFY_EXTENSION_MEMBERSHIP_SUCCESS'
export const VERIFY_EXTENSION_MEMBERSHIP_FAILED =
  'Landers/Membership/VERIFY_EXTENSION_MEMBERSHIP_FAILED'
export const MEMBERSHIP_INFO = 'Landers/Membership/MEMBERSHIP_PRODUCTS'
export const MEMBERSHIP_PRODUCTS = 'Landers/Membership/MEMBERSHIP_PRODUCTS'
export const MEMBERSHIP_PRODUCTS_SUCCESS =
  'Landers/Membership/MEMBERSHIP_PRODUCTS_SUCCESS success'
export const MEMBERSHIP_PRODUCTS_FAILED =
  'Landers/Membership/MEMBERSHIP_PRODUCTS_FAILED'

// LOAD MEMBERSHIP BANNER
export const LOAD_MEMBERSHIP_BANNER =
  'Landers/Membership/LOAD_MEMBERSHIP_BANNER'
export const LOAD_MEMBERSHIP_BANNER_SUCCESS =
  'Landers/Membership/LOAD_MEMBERSHIP_BANNER_SUCCESS success'
export const LOAD_MEMBERSHIP_BANNER_FAILED =
  'Landers/Membership/LOAD_MEMBERSHIP_BANNER_FAILED'

// ADD PREMIUM MEMBERSHIP TO CART (GUEST)
export const APPLY_MEMBERSHIP = 'Landers/Membership/APPLY_MEMBERSHIP'
export const APPLY_MEMBERSHIP_SUCCESS =
  'Landers/Membership/APPLY_MEMBERSHIP_SUCCESS'
export const APPLY_MEMBERSHIP_FAILED =
  'Landers/Membership/APPLY_MEMBERSHIP_FAILED'

// ADD PREMIUM MEMBERSHIP TO CART (GUEST)
export const VERIFY_MEMBERSHIP = 'Landers/Membership/VERIFY_MEMBERSHIP'
export const VERIFY_MEMBERSHIP_SUCCESS =
  'Landers/Membership/VERIFY_MEMBERSHIP_SUCCESS'
export const VERIFY_MEMBERSHIP_FAILED =
  'Landers/Membership/VERIFY_MEMBERSHIP_FAILED'

// RENEW MEMBERSHIP
export const RENEW_MEMBERSHIP = 'Landers/Membership/RENEW_MEMBERSHIP'
export const RENEW_MEMBERSHIP_SUCCESS =
  'Landers/Membership/RENEW_MEMBERSHIP_SUCCESS'
export const RENEW_MEMBERSHIP_FAILED =
  'Landers/Membership/RENEW_MEMBERSHIP_FAILED'

// RENEW MEMBERSHIP
export const LINK_MEMBERSHIP = 'Landers/Membership/LINK_MEMBERSHIP'
export const LINK_MEMBERSHIP_SUCCESS =
  'Landers/Membership/LINK_MEMBERSHIP_SUCCESS'
export const LINK_MEMBERSHIP_FAILED =
  'Landers/Membership/LINK_MEMBERSHIP_FAILED'
export const RESET_LINK_MEMBERSHIP_FORM =
  'Landers/Membership/RESET_LINK_MEMBERSHIP_FORM'
export const GET_MEMBERSHIP_INFO = 'Landers/Membership/GET_MEMBERSHIP_INFO'
export const SET_MEMBERSHIP_INFO = 'Landers/Membership/SET_MEMBERSHIP_INFO'
export const SET_MEMBERSHIP_SUBMITTING =
  'Landers/Membership/SET_MEMBERSHIP_SUBMITTING'

export const MEMBERSHIP_EXPIRATION = 'Landers/Membership/MEMBERSHIP_EXPIRATION'

export const EXPIRED_NUDGE = 'EXPIRED_NUDGE'
export const ABOUT_TO_EXPIRE_NUDGE = 'ABOUT_TO_EXPIRE_NUDGE'

export const SET_BUSINESS_MEMBERSHIP =
  'Landers/Membership/SET_BUSINESS_MEMBERSHIP'

// Reset password
export let VERIFY_PRIMARY_MEMBERSHIP =
  'Landers/Authentication/VerifyPrimaryMembership'
export let VERIFY_PRIMARY_MEMBERSHIP_SUCCESS =
  'Landers/Authentication/VerifyPrimaryMembershipSuccess'
export let VERIFY_PRIMARY_MEMBERSHIP_FAILED =
  'Landers/Authentication/VerifyPrimaryMembershipFailed'

export const MEMBERSHIP_ACTION = {
  APPLY: 'apply',
  RENEW: 'renew',
  NEW: 'new',
}

// Raffle
export const GET_RAFFLE_CONFIGS = 'Landers/Membership/GET_RAFFLE_CONFIGS'
export const SET_RAFFLE_CONFIGS = 'Landers/Membership/SET_RAFFLE_CONFIGS'
export const GET_RAFFLE_FAQS = 'Landers/Membership/GET_RAFFLE_FAQS'
export const SET_RAFFLE_FAQS = 'Landers/Membership/SET_RAFFLE_FAQS'

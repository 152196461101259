export default {
	login: 'login',
	signup: 'signup',
	signupStart: 'signupStart',
	signupFinish: 'signupFinish',
	loginFinish: 'loginFinish',
	loginStart: 'loginStart',
	openLoginModal: 'openLoginModal',
	openSignupModal: 'openSignupModal',
	saveSideMenu: 'saveSideMenu',
	closeAuthModal: 'closeAuthModal',
	saveHomeCard: 'saveHomeCard',
	saveEventCard: 'saveEventCard',
	saveCategoryCard: 'saveCategoryCard',
	showSideMenu: 'showSideMenu',
	hideSideMenu: 'hideSideMenu',
	openVideoModal: 'openVideoModal',
	closeVideoModal: 'closeVideoModal',
	saveHomeSlider: 'saveHomeSlider',
	saveMembershipSlider: 'saveMembershipSlider',
	showSuggestion: 'showSuggestion',
	hideSuggestion: 'hideSuggestion',
	addToCart: 'addToCart',
	removeFromCart: 'removeFromCart',
	clearCart: 'clearCart',
	decreaseFromCart: 'decreaseFromCart',
	increaseItemQuantityInCart: 'increaseItemQuantityInCart',
	decreaseItemQuantityInCart: 'decreaseItemQuantityInCart',
	removeItemFromCart: 'removeItemFromCart',
	saveUser: 'saveUser',
	openMobileMenu: 'openMobileMenu',
	saveProudctDetail: 'saveProudctDetail',
	selectedLocaionNotAllow: 'selectedLocaionNotAllow',
	closeMobileMenu: 'closeMobileMenu',
	hideBurger: 'hideBurger',
	hideMobileLogo: 'hideMobileLogo',
	showMobileLogo: 'showMobileLogo',
	showBurger: 'showBurger',
	saveCategoryView: 'saveCategoryView',
	homeCardLoadingFinish: 'homeCardLoadingFinish',
	homeCardLoadingStart: 'homeCardLoadingStart',
	homeCatLoadingStart: 'homeCatLoadingStart',
	homeCatLoadingFinish: 'homeCatLoadingFinish',
	homeExLoadingStart: 'homeExLoadingStart',
	homeExLoadingFinish: 'homeExLoadingFinish',
	openMobileProductModal: 'openMobileProductModal',
	triggerSignupSuccess: 'triggerSignupSuccess',
	saveToken: 'saveToken',
	saveSearchCatId: 'saveSearchCatId',
	searchLoadingStart: 'searchLoadingStart',
	searchLoadingFinish: 'searchLoadingFinish',
	openForgotPasswordModal: 'openForgotPasswordModal',
	closeForgotPasswordModal: 'closeForgotPasswordModal',
	saveSearchSuggestion: 'saveSearchSuggestion',
	saveSearchResult: 'saveSearchResult',
	saveSelectedText: 'saveSelectedText',
	clearSearchSuggestion: 'clearSearchSuggestion',
	logout: 'logout',
	saveUserInfo: 'saveUserInfo',
	closeSearchDropDown: 'closeSearchDropDown',
	requestCategoryNameFromSlug: 'requestCategoryNameFromSlug',
	addToShoppingList: 'addToShoppingList',
	closeRemoveConfirmModal: 'closeRemoveConfirmModal',
	loadPastPurchase: 'loadPastPurchase',
	clearOriginalLocation: 'clearOriginalLocation',
	saveOriginalLocation: 'saveOriginalLocation',
}

import * as constant from './constants'

export const initialState = {
  deliveryAreas: [],
}
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case constant.DELIVERY_AREAS_SUCCESS:
			return {
				...state,
				deliveryAreas: action.payload,
			}
		case constant.DELIVERY_AREAS_FAILED:
			return {
				...state,
				deliveryAreas: [],
			}
		case constant.DELIVERY_AREAS_START:
			return {
				...state,
			}
		case constant.DELIVERY_AREAS_FINISH:
			return {
				...state,
			}
		default:
			return state
	}
}
export default reducer

import { snapmartApi as api } from '../snapmartApi'
export const addTagTypes = ['Nps']

const npsApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      ratingOrder: build.mutation({
        query: (queryArg) => ({
          url: `/nps/ratings/check`,
          method: 'POST',
          body: { orderNumbers: queryArg.orders },
        }),
      }),
      homePageRatingOrder: build.query({
        query: (queryArg) => {
          return {
            url: `/nps/orders/not-rated?user_id=${queryArg.userId}`,
          }
        },
      }),
      skipNotNowUpdate: build.mutation({
        query: (queryArg) => {
          const { userId, orderNumber, status } = queryArg
          return {
            url: `/nps/orders/not-rated/update`,
            method: 'PATCH',
            body: {
              userId: String(userId),
              orderNumber: orderNumber,
              status: status,
            },
          }
        },
        providesTags: ['Nps'],
      }),
    }),
    overrideExisting: false,
  })
export const { useHomePageRatingOrderQuery, useSkipNotNowUpdateMutation } =
  npsApi

export default npsApi

import { call, put, takeLatest } from 'redux-saga/effects'

import * as actions from './actions'
import * as constant from './constants'
import { get } from 'lodash'
import config from 'global-config'

const { apiUrl } = config

function* onLoadTrackAndTraceTemplate(action) {
  const requestURL = `${apiUrl}/json-page/track-and-trace`
  try {
    const res = yield call(fetch, requestURL, {
      method: 'GET',
    })
    const data = yield res.json()
    yield put(actions.saveTrackAndTraceTemplate(data))
  } catch (err) {
  } finally {
  }
}

function* onLoadOrderTracker(action) {
  const requestURL = `https://tps.snapmart.ph/v1/magento/customer/order/${action.payload.email}/1/100`
  try {
    const res = yield call(fetch, requestURL, {
      method: 'GET',
    })
    const data = yield res.json()
    const dataSave = get(data, 'items', [])
    yield put(actions.saveOrderTracker(dataSave))
  } catch (err) {
  } finally {
  }
}

export default function* expSaga() {
  yield takeLatest(constant.LOAD_TRACK_AND_TRACE, onLoadTrackAndTraceTemplate)
  yield takeLatest(constant.LOAD_ORDER_TRACER, onLoadOrderTracker)
}

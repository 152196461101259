export const GET_ALL_HELP = 'landers/help/GET_ALL_HELP'
export const HELP_SUCCESS = 'landers/help/HELP_SUCCESS'
export const HELP_FAILED = 'landers/help/HELP_FAILED'

export const POST_FAQ = 'landers/help/POST_FAQ'
export const POST_FAQ_SUCCESS = 'landers/help/POST_FAQ_SUCCESS'
export const POST_FAQ_FAILED = 'landers/help/POST_FAQ_FAILED'

export const breadCrumbArr = [
  {
    label: 'Help Center',
    link: '/help-center',
  },
]

export const enquiry = [
  {
    id: 38,
    name: 'Problem with item received',
    type_order: true,
  },
  {
    id: 45,
    name: 'Branch Complaint',
    type_order: false,
  },
  {
    id: 87,
    name: 'Availability & Price',
    type_order: false,
  },
  {
    id: 88,
    name: 'Payment',
    type_order: true,
  },
  {
    id: 89,
    name: 'Cancel Item/Order',
    type_order: true,
  },
  {
    id: 90,
    name: 'Change delivery info (address/HP/email/name)',
    type_order: true,
  },
  {
    id: 91,
    name: 'Change Product (Item/Quantity)',
    type_order: true,
  },
  {
    id: 92,
    name: 'Coverage Area',
    type_order: false,
  },
  {
    id: 94,
    name: 'Content Issue/ Inquiry',
    type_order: false,
  },
  {
    id: 95,
    name: 'Delivery Inquiry',
    type_order: true,
  },
  {
    id: 96,
    name: 'Feedback on Logistic Partner',
    type_order: true,
  },
  {
    id: 97,
    name: 'How to place an order',
    type_order: false,
  },
  {
    id: 98,
    name: 'Membership Issue / Inquiries /My Account',
    type_order: false,
  },
  {
    id: 99,
    name: 'My Account',
    type_order: false,
  },
  {
    id: 100,
    name: 'Official Receipt',
    type_order: true,
  },
  {
    id: 101,
    name: 'Order Status  or Delivery Inquiry',
    type_order: true,
  },
  {
    id: 102,
    name: 'Partnerships',
    type_order: false,
  },
  {
    id: 103,
    name: 'Problem with Notification Received',
    type_order: false,
  },
  {
    id: 104,
    name: 'Problem with Tracking Tool / Online Forms',
    type_order: false,
  },
  {
    id: 105,
    name: 'Promo / Voucher /Cart Rule Discount',
    type_order: false,
  },
  {
    id: 106,
    name: 'Refund / Returns / Warranty & Policy / Service Center',
    type_order: false,
  },
  {
    id: 107,
    name: 'Website Issue',
    type_order: false,
  },
  {
    id: 108,
    name: 'Product Quality Issue',
    type_order: true,
  },
  {
    id: 109,
    name: 'DTI Complaint',
    type_order: true,
  },
  {
    id: 159,
    name: 'Shipping & delivery problem',
    type_order: false,
  },
  {
    id: 160,
    name: 'Service Reviews',
    type_order: false,
  },
  {
    id: 163,
    name: 'Paymaya Promo',
    type_order: false,
  },
]

export const BANNER_POSITIONING = {
  CATEGORY_PAGE_TOP: 'catalog_category_view.page-top',
  CATEGORY_PAGE_BOTTOM: 'catalog_category_view.footer-container',
}
